import { memo } from 'react';
import { ActionIcon, Group, TextInput } from '@mantine/core';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { ITag, ITagResponse } from '../../../redux/blog/blogTypes';

type Props = {
  tags: ITagResponse[];
  isFetching: boolean;
  handleDelete: (id: number) => void;
};

export const CreatedTags = memo(({ tags, isFetching, handleDelete }: Props) => {
  return (
    <Group mt="xl">
      {tags.map(({ value, label }: ITagResponse) => (
        <Group key={value}>
          <TextInput disabled placeholder={label.en} />
          <ActionIcon disabled={isFetching} color="red" onClick={() => handleDelete(value)}>
            <DeleteIcon />
          </ActionIcon>
        </Group>
      ))}
    </Group>
  );
});
