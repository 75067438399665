import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EditableCourse,
  EditableFeedback,
  EditableRate,
  EditableReview,
  ICourse,
  IFeedback,
  IRate,
  IReview,
  IShortCourses,
} from './coursesTypes';
import { Nullable } from '../../helpers/nullable';
import { NavigateFunction } from 'react-router-dom';

interface IState {
  isError: boolean;
  isFetching: boolean;
  isCourseFetching: boolean;
  shortCourses: Nullable<IShortCourses[]>;
  coursesList: Nullable<ICourse[]>;
  courseData: Nullable<ICourse>;
  rates: Nullable<IRate[]>;
  feedbacks: Nullable<IFeedback[]>;
  rate: Nullable<IRate>;
  feedback: Nullable<IFeedback>;
  reviews: Nullable<any>;
  review: Nullable<any>;
  reviewId: Nullable<any>;
}

const initialState: IState = {
  isError: false,
  isFetching: false,
  isCourseFetching: true,
  shortCourses: null,
  coursesList: null,
  courseData: null,
  rates: null,
  feedbacks: null,
  rate: null,
  feedback: null,
  reviews: null,
  review: null,
  reviewId: null,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setIsError(state, { payload }: PayloadAction<boolean>) {
      state.isError = payload;
    },
    setIsFetching(state, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    setCourseFetching(state, { payload }: PayloadAction<boolean>) {
      state.isCourseFetching = payload;
    },
    getCourses() {},
    getCoursesList(
      state,
      { payload }: PayloadAction<{ status: Nullable<string>; page: number }>,
    ) {},
    setShortCourses(state, { payload }: PayloadAction<IShortCourses[]>) {
      state.shortCourses = payload;
    },
    setCoursesList(state, { payload }: PayloadAction<ICourse[]>) {
      state.coursesList = payload;
    },
    createCourse(
      state,
      {
        payload,
      }: PayloadAction<{
        courseData: Omit<EditableCourse | ICourse, 'id'>;
        navigate: NavigateFunction;
      }>,
    ) {},
    duplicateCourse(state, { payload }: PayloadAction<{ id: number; status: string }>) {},
    deleteCourse(state, { payload }: PayloadAction<{ id: number; status: string }>) {},
    updateCourse(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        courseData: Omit<EditableCourse | ICourse, 'id'>;
        navigate?: NavigateFunction;
      }>,
    ) {},
    getCourseById(state, { payload }: PayloadAction<string>) {},
    setCourseData(state, { payload }: PayloadAction<ICourse>) {
      state.courseData = payload;
    },
    createRate(
      state,
      { payload }: PayloadAction<{ rate: EditableRate; navigate: NavigateFunction }>,
    ) {},
    getRates() {},
    getRate(state, { payload }: PayloadAction<string>) {},
    deleteRate(state, { payload }: PayloadAction<number>) {},
    updateRate(
      state,
      { payload }: PayloadAction<{ id: string; rate: EditableRate; navigate: NavigateFunction }>,
    ) {},
    setRates(state, { payload }: PayloadAction<IRate[]>) {
      state.rates = payload;
    },
    setRate(state, { payload }: PayloadAction<IRate>) {
      state.rate = payload;
    },
    createFeedback(
      state,
      { payload }: PayloadAction<{ feedback: any; navigate: NavigateFunction }>,
    ) {},
    getFeedbacks() {},
    getFeedback(state, { payload }: PayloadAction<string>) {},
    updateFeedback(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        feedback: Omit<EditableFeedback, 'id' | 'course'>;
        navigate: NavigateFunction;
      }>,
    ) {},
    setFeedbacks(state, { payload }: PayloadAction<any[]>) {
      state.feedbacks = payload;
    },
    setFeedback(state, { payload }: PayloadAction<IFeedback>) {
      state.feedback = payload;
    },
    deleteFeedback(state, { payload }: PayloadAction<number>) {},
    getReviews() {},
    getReview(state, { payload }: PayloadAction<number>) {},
    setReviews(state, { payload }: PayloadAction<any[]>) {
      state.reviews = payload;
    },
    setReview(state, { payload }: PayloadAction<any>) {
      state.review = payload;
    },
    setReviewId(state, { payload }: PayloadAction<{ id: number }>) {
      state.reviewId = payload.id;
    },
    createReview(
      state,
      { payload }: PayloadAction<{ review: EditableReview; navigate: NavigateFunction }>,
    ) {},
    updateReview(
      state,
      {
        payload,
      }: PayloadAction<{ id: string; review: EditableReview; navigate: NavigateFunction }>,
    ) {},
    deleteReview(state, { payload }: PayloadAction<number>) {},
  },
});

export const coursesActions = coursesSlice.actions;
export const coursesReducer = coursesSlice.reducer;
