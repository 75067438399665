import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import {
  Avatar,
  Button,
  Card,
  Container,
  FileInput,
  Group,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Paper,
  Rating,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { encodeFileInBase } from 'helpers/helpers';
import { ILang } from 'helpers/commonTypes';
import { CreateCourseReviewForm } from 'pages/CoursePageReviews/CreateCourseReview/CreateCourseReviewForm/CreateCourseReviewForm';
import { coursesActions } from 'redux/courses/coursesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { coursesSelectors } from 'redux/courses/coursesSelectors';
import { EditableReview, IReview } from 'redux/courses/coursesTypes';
import { format } from 'date-fns';

export interface IReviewForm {
  full_name: ILang;
  team_position: ILang;
  description: ILang;
  rating: number;
}

interface FormProps {
  isEditMode?: boolean;
  courseId?: number;
  handleCreated?: (id: number) => void;
  editId?: number;
}

export const CreateCourseReview = ({ isEditMode = false, courseId, handleCreated, editId }: FormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fileInBase64, setFileInBase64] = useState<any>('');
  const effectCalled = useRef(false);

  const { id } = useParams<{ id: string }>();

  const review = useSelector(coursesSelectors.selectReview);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);
  const reviewId = useSelector(coursesSelectors.selectReviewId);
  const courses = useSelector(coursesSelectors.selectShortCourses);

  const pageTitle = isEditMode ? `Edit review ${review?.full_name.en}` : `New review`;

  const form = useForm<EditableReview>({
    initialValues: {
      image: '',

      full_name: {
        en: '',
        rus: '',
        est: '',
      },
      team_position: {
        en: '',
        rus: '',
        est: '',
      },
      description: {
        en: '',
        rus: '',
        est: '',
      },
      rating: 5,
      courses: courseId ? [courseId] : [],
    },
  });

  useEffect(() => {
    dispatch(coursesActions.getCourses());
  }, []);

  useEffect(() => {
    if (reviewId && handleCreated) {
      handleCreated(reviewId);
    }
  }, [reviewId, handleCreated]);

  const onSubmit = (values: typeof form.values) => {
    const review = { ...values, image: fileInBase64 };

    if (isEditMode && id) {
      dispatch(coursesActions.updateReview({ id, review, navigate }));
    } else {
      dispatch(coursesActions.createReview({ review, navigate }));
    }
  };

  const onFileChange = async (file: File) => {
    const inBase64 = await encodeFileInBase(file);
    setFileInBase64(inBase64);
  };

  const handleCoursesInput = (values: string[]) => {
    const coursesMap = values.map(Number);

    form.setFieldValue('courses', coursesMap);
  };

  useEffect(() => {
    if ((editId || id) && isEditMode) {
      dispatch(coursesActions.getReview(Number(editId ? editId : id)));
    }
  }, [id, isEditMode, editId]);

  useEffect(() => {
    if (isEditMode && review) {
      const currentData = {
        full_name: review.full_name,
        team_position: review.team_position,
        description: review.description,
        rating: review.rating,
        courses: review.courses.map((course) => course.id),
      };
      form.setValues(currentData);
    }
  }, [isEditMode, isFetching, review]);

  return (
    <Container>
      <LoadingOverlay visible={isFetching} overlayBlur={2} />
      <Title mb="xl" order={1}>
        {pageTitle}
      </Title>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.onSubmit(onSubmit)(e);
        }}
        id="edit-course-review-form"
      >
        <Tabs defaultValue="en">
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="en">Eng</Tabs.Tab>
            <Tabs.Tab value="rus">Rus</Tabs.Tab>
            <Tabs.Tab value="est">Est</Tabs.Tab>
          </Tabs.List>
          <Paper shadow="sm" p="md" withBorder>
            <MultiSelect
              styles={{ required: { display: 'none' } }}
              searchable
              required
              mb="xl"
              label="Select courses"
              placeholder="Select course"
              value={form.values.courses?.map((course) => course.toString())}
              onChange={handleCoursesInput}
              data={
                courses?.map(({ id, name, start_at, format, status }) => ({
                  value: `${id}`,
                  label: `${name} | ${format} | ${new Date(start_at).toLocaleString('default', { month: 'long', day: '2-digit' })} | ${status}`,
                })) ?? []
              }
            />
            <Group>
              {!!fileInBase64 && <Avatar src={fileInBase64} />} <Rating mb="md" {...form.getInputProps('rating')} />
            </Group>
            <FileInput
              styles={{ required: { display: 'none' } }}
              required
              value={fileInBase64.name}
              placeholder="Pick photo"
              label="Upload reviewer photo"
              onChange={(file: File) => onFileChange(file)}
              inputWrapperOrder={['label', 'error', 'input']}
              accept="image/png,image/jpeg"
            />
            <Tabs.Panel value="en" pt="xs">
              <CreateCourseReviewForm form={form} language="en" />
            </Tabs.Panel>
            <Tabs.Panel value="rus" pt="xs">
              <CreateCourseReviewForm form={form} language="rus" />
            </Tabs.Panel>
            <Tabs.Panel value="est" pt="xs">
              <CreateCourseReviewForm form={form} language="est" />
            </Tabs.Panel>{' '}
          </Paper>
        </Tabs>

        <Group position="right">
          <Button mt="md" loading={false} disabled={false} type="submit" form="edit-course-review-form">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};
