import { all, call, put, takeLatest } from 'redux-saga/effects';
import { handleServerNetworkError } from '../../helpers/helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { showNotification } from '@mantine/notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { EditableWebinar, IWebinar } from './webinarsTypes';
import { webinarsActions } from './webinarsSlice';
import { webinarsApi } from '../../api/webinarsApi';
import { APP_ROUTES } from '../../constants';

function* createWebinar({ payload }: PayloadAction<{ webinar: EditableWebinar; navigate: NavigateFunction }>) {
  yield put(webinarsActions.setIsFetching(true));
  try {
    yield webinarsApi.createWebinar(payload.webinar);

    showNotification({
      message: `Webinar successfully created`,
      color: 'green',
      autoClose: 3000,
    });

    payload.navigate(APP_ROUTES.WEBINARS);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(webinarsActions.setIsFetching(false));
  }
}

function* getWebinars() {
  yield put(webinarsActions.setIsFetching(true));
  try {
    //TODO: fix any
    const { data }: AxiosResponse<{ data: IWebinar[]; meta: any }> = yield webinarsApi.getWebinars();

    yield put(webinarsActions.setWebinars(data.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(webinarsActions.setIsFetching(false));
  }
}

function* getWebinar({ payload }: PayloadAction<string>) {
  yield put(webinarsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IWebinar> = yield webinarsApi.getWebinar(payload);
    yield put(webinarsActions.setWebinar(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(webinarsActions.setIsFetching(false));
  }
}

function* deleteWebinar({ payload }: PayloadAction<number>) {
  yield put(webinarsActions.setIsFetching(true));
  try {
    yield webinarsApi.deleteWebinar(payload);

    yield call<any>(getWebinars);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(webinarsActions.setIsFetching(false));
  }
}

function* updateWebinar({
  payload: { id, webinar, navigate },
}: PayloadAction<{ id: string; webinar: EditableWebinar; navigate: NavigateFunction }>) {
  yield put(webinarsActions.setIsFetching(true));
  try {
    const response: AxiosResponse<IWebinar> = yield call(webinarsApi.updateWebinar, id, webinar);

    navigate(APP_ROUTES.WEBINARS);

    yield put(webinarsActions.setWebinar(response.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(webinarsActions.setIsFetching(false));
  }
}

export function* webinarsSagas() {
  yield all([
    takeLatest(webinarsActions.createWebinar.toString(), createWebinar),
    takeLatest(webinarsActions.getWebinars.toString(), getWebinars),
    takeLatest(webinarsActions.deleteWebinar.toString(), deleteWebinar),
    takeLatest(webinarsActions.getWebinar.toString(), getWebinar),
    takeLatest(webinarsActions.updateWebinar.toString(), updateWebinar),
  ]);
}
