import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Container, Group, LoadingOverlay, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { ITestValues } from '../../../../redux/tests/types';
import { testsActions } from '../../../../redux/tests/testsSlice';
import { useEffect } from 'react';
import { testsSelectors } from '../../../../redux/tests/testsSelectors';

type Props = {
  isEditMode?: boolean;
};

export const TestInfo = ({ isEditMode = false }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { testId } = useParams<{ testId: string }>();

  const test = useSelector(testsSelectors.selectTest);
  const isFetching = useSelector(testsSelectors.selectIsFetching);

  const languages = [
    { value: 'en', label: 'English' },
    { value: 'rus', label: 'Russian' },
    { value: 'est', label: 'Estonian' },
  ];

  const form = useForm<ITestValues>({
    initialValues: {
      name: '',
      description: '',
      description_visible: false,
      language: '',
    },
  });

  const onSubmit = (values: ITestValues) => {
    if (isEditMode && testId) {
      dispatch(testsActions.updateTest({ test: values, testId: +testId, navigate }));
    } else {
      dispatch(testsActions.createTest({ test: values, navigate }));
    }
  };

  useEffect(() => {
    if (testId) {
      dispatch(testsActions.getTest(+testId));
    }
  }, []);

  useEffect(() => {
    if (isEditMode && test) {
      const currentData = {
        name: test.name,
        description: test.description,
        description_visible: test.description_visible,
        language: test.language,
      };

      form.setValues(currentData);
    }
  }, [isEditMode, isFetching, test]);

  if (!test && isEditMode) {
    return null;
  }

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <Text color="#0F68FC" size="xl" mt="xl">
        General information about the test
      </Text>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          styles={{ required: { display: 'none' } }}
          required
          label="Test name"
          placeholder="Name"
          {...form.getInputProps('name')}
        />
        <TextInput
          mt="xl"
          styles={{ required: { display: 'none' } }}
          required
          label="Test description"
          placeholder="Description"
          {...form.getInputProps('description')}
        />
        <Checkbox
          mt="md"
          label="Make description visible"
          {...form.getInputProps('description_visible', { type: 'checkbox' })}
        />
        <Select
          mt="xl"
          label="Select test language"
          placeholder="Pick one"
          data={languages}
          {...form.getInputProps('language')}
        />
        <Group mt="xl" position="right">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};
