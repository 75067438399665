import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Group,
  LoadingOverlay,
  Pagination,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import dayjs from 'dayjs';

import { EmptyListContainer } from '../../components/containers';
import { APP_ROUTES } from '../../constants';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { coursesActions } from '../../redux/courses/coursesSlice';
import { useSelector } from 'react-redux';
import { coursesSelectors } from '../../redux/courses/coursesSelectors';
import { CourseCard } from '../../components';
import { EditableCourse, ICourse } from '../../redux/courses/coursesTypes';
import { Nullable } from '../../helpers/nullable';
import { paginationSelectors } from '../../redux/pagination/paginationSelectors';
import { getTranslations } from 'helpers/get-translations.helper';

export const Courses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const courses = useSelector(coursesSelectors.selectCourses);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);
  const paginationData = useSelector(paginationSelectors.selectPaginationData);

  const [listStatus, setStatus] = useState<'active' | 'archive' | 'drafts'>('active');
  const [fakeUpdate, setFakeUpdate] = useState(1);

  const pageNumbers = Math.ceil(paginationData.total / paginationData.per_page);

  const handleCreate = () => {
    navigate(APP_ROUTES.CREATE_COURSE);
  };

  const handleCreateNew = () => {
    navigate(APP_ROUTES.EDIT_COURSE_NEW);
  };

  const handleArchive = async (id: number) => {
    let course = courses?.find((course) => course.id === id);

    if (course) {
      const courseData: EditableCourse = {
        id: course.id,
        learning_format_description: getTranslations(course.learning_format_description),
        module_program: course.module_program,
        meta_title: getTranslations(course.meta_title),
        meta_description: getTranslations(course.meta_description),
        name: getTranslations(course.name),
        format: course.format, // offline/online
        status: course.status === 'active' ? 'archive' : 'active', //archive active drafts
        teachers: course.teachers?.map((teacher) => teacher.id) || [],
        feedbacks: course.feedbacks?.map((feedback) => feedback.id) || [],
        speaker_photo_or_video: getTranslations(course.speaker_photo_or_video),
        program: course.program,
        languages: course.languages,
        description: getTranslations(course.description),
        start_at: new Date(course.start_at),
        description_block: course.description_block,
        for_whom_block: getTranslations(course.for_whom_block),
        module_block: course.module_block || [],
        joining_course_block: course.joining_course_block,
        you_get_description_block: course.you_get_description_block,
        any_questions_block: course.any_questions_block,
        slug: course.slug,
        about_course_header: getTranslations(course.about_course_header),
        about_course_description: getTranslations(course.about_course_description),
        webinar: course.webinar?.id,
        reviews: course.reviews?.map((review) => review.id) || [],
        learning_formats: course.learning_formats?.map((rate) => rate.id) || [],
        test_block: course.test_block,
        workload: course.workload,
      };

      dispatch(coursesActions.updateCourse({ id: id.toString(), courseData, navigate }));

      // dirty hacks for dirty code, idk how to make this code sync, redux!!!!
      setTimeout(() => {
        dispatch(coursesActions.getCoursesList({ status: listStatus, page: 1 }));
      }, 1000);
    }
  };

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`${APP_ROUTES.CREATE_COURSE}/${id}`);
    },
    [navigate],
  );

  const handleEditNew = useCallback(
    (id: number) => {
      navigate(`${APP_ROUTES.EDIT_COURSE_NEW}/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback((id: number, status: string) => {
    openConfirmModal({
      title: 'Delete course',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete course?</Text>,
      labels: { confirm: 'Delete course', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(coursesActions.deleteCourse({ id, status })),
    });
  }, []);

  const handleDuplicate = useCallback((id: number, status: string) => {
    dispatch(coursesActions.duplicateCourse({ id, status }));
  }, []);

  const onPageChange = (page: number) => {
    dispatch(coursesActions.getCoursesList({ status: listStatus, page }));
  };

  useEffect(() => {
    if (listStatus) {
      dispatch(coursesActions.getCoursesList({ status: listStatus, page: 1 }));
    }
  }, [listStatus, fakeUpdate]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!courses) {
    return null;
  }

  const coursesContent = courses.map(({ name, start_at, id, status }: ICourse) => (
    <CourseCard
      key={id}
      status={listStatus}
      handleEdit={() => handleEdit(id)}
      handleEditNew={() => handleEditNew(id)}
      handleDelete={() => handleDelete(id, status)}
      handleDuplicate={() => handleDuplicate(id, status)}
      handleArchive={() => handleArchive(id)}
      courseName={name.en}
      startDate={dayjs(start_at).format('MMMM D, YYYY')}
    />
  ));

  return (
    <Container>
      <EmptyListContainer
        isEmpty={!courses}
        title="Your course list is empty."
        description="Create a training course and enter the details."
        buttonText="Add a course"
        onClick={handleCreate}
      >
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title order={1}>Courses</Title>
          <Button onClick={handleCreateNew}>Create course</Button>
        </Group>

        <Tabs
          onTabChange={(status: any) => setStatus(status)}
          mt="xs"
          defaultValue={listStatus}
          keepMounted={false}
        >
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="active">Active</Tabs.Tab>
            <Tabs.Tab value="drafts">Drafts</Tabs.Tab>
            <Tabs.Tab value="archive">Archive</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="active" pt="xs">
            {coursesContent}
          </Tabs.Panel>

          <Tabs.Panel value="drafts" pt="xs">
            {coursesContent}
          </Tabs.Panel>

          <Tabs.Panel value="archive" pt="xs">
            {coursesContent}
          </Tabs.Panel>
        </Tabs>

        {pageNumbers > 1 && (
          <Group position="center">
            <Pagination
              mt="xl"
              mb="md"
              total={pageNumbers}
              value={paginationData.current_page}
              onChange={onPageChange}
              size="md"
              radius="md"
              withControls={false}
            />
          </Group>
        )}
      </EmptyListContainer>
    </Container>
  );
};
