import { ActionIcon, Button, Divider, Group, Stack, Text, TextInput } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { AddIcon } from 'assets/icons/AddIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { useLanguage } from 'components/Language/LanguageContext';
import { LanguageFlags } from 'components/Language/types';
import { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EditableCourse } from 'redux/courses/coursesTypes';

export const OutcomesForm = () => {
  const { language } = useLanguage();
  const form = useFormContext<EditableCourse>();
  const fieldsArray = useFieldArray({
    control: form.control,
    name: 'joining_course_block.list',
  });


  const handleAddPoint = () => {
    fieldsArray.append({
      key: randomId(),
      title: {
        en: '',
        rus: '',
        est: '',
      },
    });
  };

  return (
    <Stack>
      <TextInput
        key={`joining_course_block.name.${language}`}
        label="Name"
        placeholder="Enter name"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        {...form.register(`joining_course_block.name.${language}`)}
      />

      <Divider my="sm" variant="dashed" />

      {fieldsArray.fields.map((item, index) => (
        <Fragment key={item.key}>
          <Group>
            <TextInput
              key={`joining_course_block.list.${item.id}.title.${language}`}
              style={{ width: '100%' }}
              withAsterisk
              label="Text"
              rightSection={<Text>{LanguageFlags[language]}</Text>}
              placeholder="Enter text"
              description="200 symbols maximum"
              inputWrapperOrder={['label', 'error', 'input', 'description']}
              {...form.register(`joining_course_block.list.${index}.title.${language}`)}
            />
            <ActionIcon color="red" onClick={() => fieldsArray.remove(index)}>
              <DeleteIcon />
            </ActionIcon>
          </Group>
          <Divider my="sm" variant="dashed" />
        </Fragment>
      ))}
      <Button mt="md" leftIcon={<AddIcon />} onClick={handleAddPoint}>
        Add point
      </Button>
    </Stack>
  );
};
