import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container, Group, LoadingOverlay, Pagination, Title, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { teachersActions } from '../../redux/teachers/teachersSlice';
import { teachersSelectors } from '../../redux/teachers/teachersSelectors';
import { EmptyListContainer } from '../../components/containers';
import { APP_ROUTES } from '../../constants';
import { TeacherCard } from '../../components';

export const Teachers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const teachers = useSelector(teachersSelectors.selectTeachers);
  const isFetching = useSelector(teachersSelectors.selectIsFetching);
  const paginationData = useSelector(teachersSelectors.selectPaginationData);

  const pageNumbers = Math.ceil(paginationData.total / paginationData.per_page);
  const isEmpty = !teachers?.length;

  const handleCreate = () => {
    navigate(`${APP_ROUTES.TEACHERS}/new`);
  };

  const handleDelete = useCallback(
    (id: number) => {
      openConfirmModal({
        title: 'Delete teacher',
        centered: true,
        children: <Text size="sm">Are you sure you want to delete teacher?</Text>,
        labels: { confirm: 'Delete teacher', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: () => dispatch(teachersActions.deleteTeacher(id)),
      });
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (id: number) => {
      navigate(`${APP_ROUTES.TEACHERS}/${id}`);
    },
    [navigate],
  );

  const onPageChange = (page: number) => {
    dispatch(teachersActions.getTeachers({ page }));
  };

  useEffect(() => {
    dispatch(teachersActions.getTeachers({ page: paginationData.current_page }));
  }, [dispatch, paginationData.current_page]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        buttonText="Add a member"
        title="Your teacher member list is empty."
        description="Add teacher and enter information about them."
        onClick={handleCreate}
      >
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title order={1}>Teachers</Title>
          <Button onClick={handleCreate}>Add new teachers</Button>
        </Group>
        {teachers &&
          teachers.map(({ main_photo, id, full_name, display_about_us, team_position }) => (
            <TeacherCard
              key={id}
              photo={main_photo}
              name={full_name.en}
              teamPosition={team_position.en}
              displayAboutUs={display_about_us}
              handleChange={() => handleChange(id)}
              handleDelete={() => handleDelete(id)}
            />
          ))}
        {pageNumbers > 1 && (
          <Group position="center">
            <Pagination
              mt="xl"
              mb="md"
              total={pageNumbers}
              value={paginationData.current_page}
              onChange={onPageChange}
              size="md"
              radius="md"
              withControls={false}
            />
          </Group>
        )}
      </EmptyListContainer>
    </Container>
  );
};
