import { RootState } from '../store';
import { ICourse, IFeedback, IRate, IReview, IShortCourses } from './coursesTypes';
import { Nullable } from '../../helpers/nullable';

const selectIsFetching = (state: RootState): boolean => state.courses.isFetching;
const selectIsCourseFetching = (state: RootState): boolean => state.courses.isCourseFetching;
const selectIsFetchingError = (state: RootState): boolean => state.courses.isError;
const selectShortCourses = (state: RootState): Nullable<IShortCourses[]> =>
  state.courses.shortCourses;
const selectCourses = (state: RootState): Nullable<ICourse[]> => state.courses.coursesList;
const selectCourseData = (state: RootState): Nullable<ICourse> => state.courses.courseData;
const selectRates = (state: RootState): Nullable<IRate[]> => state.courses.rates;
const selectRate = (state: RootState): Nullable<IRate> => state.courses.rate;
const selectFeedbacks = (state: RootState): Nullable<IFeedback[]> => state.courses.feedbacks;
const selectFeedback = (state: RootState): Nullable<IFeedback> => state.courses.feedback;
const selectReviews = (state: RootState): Nullable<IReview[]> => state.courses.reviews;
const selectReview = (state: RootState): Nullable<IReview> => state.courses.review;
const selectReviewId = (state: RootState): Nullable<number> => state.courses.reviewId;

export const coursesSelectors = {
  selectIsFetching,
  selectIsFetchingError,
  selectIsCourseFetching,
  selectShortCourses,
  selectCourses,
  selectCourseData,
  selectRates,
  selectRate,
  selectFeedbacks,
  selectFeedback,
  selectReviews,
  selectReview,
  selectReviewId,
};
