import { memo } from 'react';
import { Avatar, Button, Card, Group, Stack, Text, Title } from '@mantine/core';

type Props = {
  avatar: string;
  name: string;
  linkedCourses?: { id: number; name: string }[];
  handleChange: () => void;
  handleDelete: () => void;
};

export const FeedbackCard = memo(({ avatar, name, handleChange, handleDelete, linkedCourses }: Props) => {
  return (
    <Card radius="md" style={{ border: '1px solid #EBEFFF' }} mt="xl" shadow="sm" p="xl">
      <Group grow>
        <Group>
          <Avatar src={avatar} style={{ width: '60px', height: '60px' }} radius="xl" />
          <Text ml="md" weight={500}>
            {name}
          </Text>
        </Group>

        {linkedCourses && linkedCourses?.length > 1 && (
          <Group>
            <Text weight="bold">Tied courses:</Text>
            <Stack>
              {linkedCourses?.map(({ name, id }) => (
                <Text size="xs" key={id}>
                  {name}
                </Text>
              ))}
            </Stack>
          </Group>
        )}

        <Group position="right">
          <Stack>
            <Button onClick={handleChange} variant="subtle">
              Change details
            </Button>
            <Button color="red" onClick={handleDelete} variant="subtle">
              Delete feedback
            </Button>
          </Stack>
        </Group>
      </Group>
    </Card>
  );
});
