import { Button, Paper, Tabs, Stack, SegmentedControl, Alert } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageNames } from 'components/Language/types';
import { useFormContext } from 'react-hook-form';

function SaveAlert() {
  return (
    <Alert color="yellow" radius="md" variant="filled">
      Unsaved data
    </Alert>
  );
}

function SaveForm() {
  return (
    <Button color="green" radius="md" variant="filled" type="submit">
      Save
    </Button>
  );
}

type FormSection = {
  name: string;
  component: React.FC;
  key: string;
};

type Props = {
  sections: FormSection[];
};

export const BaseFormLayout: React.FC<Props> = ({ sections }) => {
  const form = useFormContext();
  const { language, updateLanguage } = useLanguage();

  return (
    <Tabs
      defaultValue={sections.length > 0 ? sections[0].key : ''}
      keepMounted={false}
      variant="pills"
      radius="md"
      orientation="vertical"
      mt={5}
    >
      <Tabs.List style={{ position: 'sticky', top: '80px', alignSelf: 'flex-start' }}>
        {sections.map(({ key, name }) => (
          <Tabs.Tab key={key} value={key}>{name}</Tabs.Tab>
        ))}

        <Stack mt={10}>
          <SegmentedControl
            size="sm"
            value={language}
            onChange={(value) => updateLanguage(value as Language)}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
          />
          {form.formState.isDirty && <SaveAlert />}
          {form.formState.isDirty && <SaveForm />}
        </Stack>
      </Tabs.List>

      {sections.map(({ key, component: Component }) => (
        <Tabs.Panel key={key} value={key} pl="xs">
          <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
            {<Component />}
          </Paper>
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};
