export function pick(obj: object = {}, propertiesToPick: string[] = []): object {
  if (obj == null || !propertiesToPick.length) {
    return obj;
  }

  const filteredEntries = Object.entries(obj).filter(([key, value]) =>
    propertiesToPick.includes(key),
  );

  return Object.fromEntries(filteredEntries);
}
