import { Center } from '@mantine/core';
import { LoginForm } from '../../components';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../redux/auth/authSelectors';

export const Login = () => {
  const isAuthorized = useSelector(authSelectors.selectIsAuthorized);

  if (isAuthorized) {
    return <Navigate to={APP_ROUTES.TEACHERS} />;
  }

  return (
    <Center style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 100px)' }}>
      <LoginForm />
    </Center>
  );
};
