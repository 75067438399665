import { Container, Flex, LoadingOverlay, Title } from '@mantine/core';
import { EditCourseForm } from 'components';
import { LanguageProvider } from 'components/Language/LanguageContext';
import { Test } from 'components/Pages/api';
import { getTranslations, getTranslationsWithEmptyValue } from 'helpers/get-translations.helper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useCourse } from 'pages/Courses/api';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { coursesSelectors } from 'redux/courses/coursesSelectors';
import { coursesActions } from 'redux/courses/coursesSlice';
import { EditableCourse } from 'redux/courses/coursesTypes';
import { teachersSelectors } from 'redux/teachers/teachersSelectors';
import { teachersActions } from 'redux/teachers/teachersSlice';
import { webinarsActions } from 'redux/webinars/webinarsSlice';

const getEmptyCourse = (): EditableCourse => ({
  learning_format_description: {
    en: '',
    rus: '',
    est: '',
  },
  name: {
    en: '',
    rus: '',
    est: '',
  },
  meta_title: {
    en: '',
    rus: '',
    est: '',
  },
  meta_description: {
    en: '',
    rus: '',
    est: '',
  },
  teachers: [],
  format: '', // offline/online
  status: 'drafts', //archive active drafts
  languages: [],
  speaker_photo_or_video: {
    en: '',
    rus: '',
    est: '',
  },
  program: '',
  about_course_header: {
    en: '',
    rus: '',
    est: '',
  },
  about_course_description: {
    en: '',
    rus: '',
    est: '',
  },
  description: {
    en: '',
    rus: '',
    est: '',
  },
  start_at: new Date(),
  description_block: [],
  for_whom_block: {
    en: '',
    rus: '',
    est: '',
  },
  module_block: [],
  joining_course_block: {
    name: {
      en: '',
      rus: '',
      est: '',
    },
    list: [],
  },
  you_get_description_block: {
    title: {
      en: '',
      rus: '',
      est: '',
    },
    text_block: [],
  },
  any_questions_block: [],
  curriculum_link: '',
  slug: {
    en: '',
    rus: '',
    est: '',
  },
  feedbacks: [],
  reviews: [],
  learning_formats: [],
  module_program: '',
  test_block: {
    en: {
      title: '',
      description: '',
      postButtonText: '',
      url: '',
      buttonText: '',
    },
    rus: {
      title: '',
      description: '',
      postButtonText: '',
      url: '',
      buttonText: '',
    },
    est: {
      title: '',
      description: '',
      postButtonText: '',
      url: '',
      buttonText: '',
    },
  },
  workload: '',
});

const getEmptyTest: () => Test = () => ({
  title: '',
  description: '',
  postButtonText: '',
  url: '',
  buttonText: '',
});

interface CourseHeaderProps {
  pageTitle: string;
}

export const CourseHeader = ({ pageTitle }: CourseHeaderProps) => {
  return (
    <Flex justify="space-between" align="center" direction="row" wrap="wrap">
      <Title order={3}>{pageTitle}</Title>
    </Flex>
  );
};

export const EditCourse = () => {
  const { id } = useParams<{ id: string }>();
  const isCoursesFetching = useSelector(coursesSelectors.selectIsFetching);
  const isTeachersFetching = useSelector(teachersSelectors.selectIsFetching);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { course, isLoading, isError, triggerUpdate, triggerCreate } = useCourse(id);

  const isEditMode = !!id;
  const pageTitle = isEditMode ? `Edit course ${course?.name.en}` : `New course`;
  const isFetching = isLoading || isCoursesFetching || isTeachersFetching;

  const form = useForm({
    defaultValues: getEmptyCourse(),
  });

  useEffect(() => {
    dispatch(teachersActions.getTeachers({ page: 1 }));
    dispatch(webinarsActions.getWebinars());
    dispatch(coursesActions.getReviews());
    dispatch(coursesActions.getFeedbacks());
    dispatch(coursesActions.getRates());
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode && !isFetching && course?.id && !isError) {
      if (course.id !== parseInt(id)) return;

      const formData: EditableCourse = {
        id: course.id,
        meta_title: getTranslations(course.meta_title),
        learning_format_description: getTranslations(course.learning_format_description),
        meta_description: getTranslations(course.meta_description),
        module_program: course.module_program || '',
        name: getTranslations(course.name),
        format: course.format, // offline/online
        status: course.status, //archive active drafts
        teachers: course.teachers?.map((teacher) => teacher.id) || [],
        feedbacks: course.feedbacks?.map((feedback) => feedback.id) || [],
        speaker_photo_or_video: getTranslations(course.speaker_photo_or_video),
        program: course.program,
        languages: course.languages,
        description: getTranslations(course.description),
        start_at: new Date(course.start_at),
        description_block: course.description_block,
        for_whom_block: getTranslations(course.for_whom_block),
        module_block: course.module_block || [],
        joining_course_block: course.joining_course_block,
        you_get_description_block: course.you_get_description_block,
        any_questions_block: course.any_questions_block,
        slug: getTranslations(course.slug),
        about_course_header: getTranslations(course.about_course_header),
        about_course_description: getTranslations(course.about_course_description),
        webinar: course.webinar?.id,
        reviews: course.reviews?.map((review) => review.id) || [],
        learning_formats: course.learning_formats?.map((rate) => rate.id) || [],
        test_block: getTranslationsWithEmptyValue(course.test_block, getEmptyTest),
        curriculum_link: course.curriculum_link,
        workload: course.workload,
      };

      form.reset(formData);
    }
  }, [isEditMode, isFetching, course, id]);

  const handleError = () => {};

  const onSubmit = (values: EditableCourse) => {
    const courseData = values;

    if (isEditMode && id) {
      triggerUpdate(courseData);
    } else {
      triggerCreate(courseData);
    }
  };

  return (
    <LanguageProvider>
      <Container size="lg">
        <LoadingOverlay visible={isFetching} overlayBlur={2} />
        <CourseHeader pageTitle={pageTitle} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, handleError)}>
            <EditCourseForm />
          </form>
        </FormProvider>
      </Container>
    </LanguageProvider>
  );
};
