import { useMantineTheme, Flex, Button, Modal, Group, rem, Image, Text } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconTrash } from '@tabler/icons-react';
import { useState, useRef, memo } from 'react';
import { CropperRef, Cropper } from 'react-advanced-cropper';
import styles from './ImageField.module.scss';
import { ActionIcon } from '@mantine/core';

type ImageFieldProps = {
  currentValue: string | null;
  onChange: (newValue: string) => void;
  withCrop?: boolean;
  width?: string | number;
  height?: string | number;
};

export const ImageField = memo(
  ({
    currentValue,
    onChange,
    withCrop = true,
    width = 452 / 2,
    height = 552 / 2,
  }: ImageFieldProps) => {
    const theme = useMantineTheme();
    const [imgSrc, setImgSrc] = useState('');
    const cropperRef = useRef<CropperRef>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const onDropFile = (files: File[]) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageSrc = reader.result?.toString() || '';
        setImgSrc(imageSrc);
        if (!withCrop) {
          onChange(imageSrc);
        }
      });
      reader.readAsDataURL(files[0]);
    };

    const onCrop = () => {
      const cropper = cropperRef.current;
      if (cropper) {
        const canvas = cropper.getCanvas();
        if (canvas) {
          onChange(canvas.toDataURL());
          setImgSrc('');
        }
      }
    };

    const onDelete = () => {
      onChange('');
    };

    if (currentValue) {
      return (
        <Flex justify="center">
          <Image
            fit="cover"
            width={width}
            height={height}
            mx="auto"
            radius="md"
            src={currentValue}
            alt="main_photo"
          />
          <ActionIcon color="red" size="xl" radius="md" variant="filled" onClick={onDelete}>
            <IconTrash />
          </ActionIcon>
        </Flex>
      );
    } else {
      if (imgSrc && withCrop) {
        return (
          <Modal opened={!!imgSrc} onClose={() => setImgSrc('')} title="Crop image" size="auto">
            <Cropper
              ref={cropperRef}
              src={imgSrc}
              className={styles.cropper}
              stencilProps={{ aspectRatio: 3 / 4 }}
            />
            <Button onClick={onCrop}>save</Button>
          </Modal>
        );
      } else {
        return (
          <Dropzone
            onDrop={onDropFile}
            maxSize={3 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
            multiple={false}
          >
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: rem(220), pointerEvents: 'none' }}
            >
              <Dropzone.Accept>
                <IconUpload
                  size="3.2rem"
                  stroke={1.5}
                  color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size="3.2rem"
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size="3.2rem" stroke={1.5} />
              </Dropzone.Idle>

              <div>
                <Text size="xl" inline>
                  Drag image here or click to select file
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Attach file, which should not exceed 5mb
                </Text>
              </div>
            </Group>
          </Dropzone>
        );
      }
    }
  },
);
