import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Group, LoadingOverlay, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

import { testsActions } from '../../redux/tests/testsSlice';
import { testsSelectors } from '../../redux/tests/testsSelectors';
import { EmptyListContainer } from '../../components/containers';
import { APP_ROUTES } from '../../constants';
import { TestCard } from '../../components';

export const TestsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tests = useSelector(testsSelectors.selectTests);
  const isFetching = useSelector(testsSelectors.selectIsFetching);

  const isEmpty = !tests?.length;

  const handleCreate = () => {
    navigate(APP_ROUTES.CREATE_TEST);
  };

  const handleEdit = useCallback((testId: number) => {
    navigate(`${APP_ROUTES.EDIT_TEST}/${testId}`);
  }, []);

  const handleDelete = useCallback((id: number) => {
    openConfirmModal({
      title: 'Delete test',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete test?</Text>,
      labels: { confirm: 'Delete test', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(testsActions.deleteTest(id)),
    });
  }, []);

  useEffect(() => {
    dispatch(testsActions.getTests());
  }, []);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        title="Your test list is empty."
        description="Create a test and enter the details."
        buttonText="Add a test"
        onClick={handleCreate}
      >
        <Group position="apart">
          <Title order={1}>Tests</Title>
          <Button onClick={handleCreate}>Create new test</Button>
        </Group>
        {tests?.map(({ name, description, id, questions }) => (
          <TestCard
            key={id}
            name={name}
            description={description}
            questions={questions}
            handleDelete={() => handleDelete(id)}
            handleEdit={() => handleEdit(id)}
          />
        ))}

        {/*{pageNumbers > 1 && (*/}
        {/*    <Group position="center">*/}
        {/*      <Pagination*/}
        {/*          mt="xl"*/}
        {/*          mb="md"*/}
        {/*          total={pageNumbers}*/}
        {/*          page={paginationData.current_page}*/}
        {/*          onChange={onPageChange}*/}
        {/*          size="md"*/}
        {/*          radius="md"*/}
        {/*          withControls={false}*/}
        {/*      />*/}
        {/*    </Group>*/}
        {/*)}*/}
      </EmptyListContainer>
    </Container>
  );
};
