export const DeleteIcon = () => {
  return (
    <svg width="10" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 0.25V1H12V2.5H11.25V12.25C11.25 13.075 10.575 13.75 9.75 13.75H2.25C1.425 13.75 0.75 13.075 0.75 12.25V2.5H0V1H3.75V0.25H8.25ZM2.25 12.25H9.75V2.5H2.25V12.25ZM3.75 4H5.25V10.75H3.75V4ZM8.25 4H6.75V10.75H8.25V4Z"
        fill="#fa5252"
      />
    </svg>
  );
};
