import { Stack, Text, Card, Avatar, Group, Rating, Button, Box, Select } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useLanguage } from 'components/Language/LanguageContext';
import { forwardRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { coursesSelectors } from 'redux/courses/coursesSelectors';

interface ReviewCardProps {
  reviewId: number;
  handleDelete: () => void;
}

export const ReviewCard = ({ reviewId, handleDelete }: ReviewCardProps) => {
  const { language } = useLanguage();
  const reviewsAll = useSelector(coursesSelectors.selectReviews);
  const review = reviewsAll?.find((review) => review.id === reviewId) || null;

  if (!review) {
    return (
      <Card shadow="sm" p="lg" radius="md" withBorder>
        Review not found
      </Card>
    );
  }

  return (
    <Card shadow="sm" p="md" radius="md" withBorder>
      <Group grow>
        <Stack>
          <Group>
            <Avatar src={review.image}></Avatar>
            <Text>
              {review.full_name[language]} - {review.team_position[language]}
            </Text>
            <Rating value={review.rating} fractions={2} readOnly />
          </Group>
          <Text>{review.description[language]}</Text>
        </Stack>
        <Group position="right" align="right">
          <Button
            size="xs"
            leftIcon={<IconTrash stroke={1} />}
            variant="outline"
            color="red"
            onClick={handleDelete}
          >
            Remove from course
          </Button>
        </Group>
      </Group>
    </Card>
  );
};

const Item = forwardRef<HTMLDivElement, any>(
  ({ label, value, position, review, avatar, ...others }, ref) => {
    return (
      <div ref={ref} {...others}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <Box mr={10}>
            <Avatar src={avatar} />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start', flexDirection: 'column' }}>
            <div>
              {label} - {position}
            </div>

            <div>{review}</div>
          </Box>
        </Box>
      </div>
    );
  },
);

interface FormWithReview {
  reviews: number[];
}

export const ReviewsForm = () => {
  const form = useFormContext<FormWithReview>();

  const reviewsAll = useSelector(coursesSelectors.selectReviews);
  const { language } = useLanguage();

  const watchReviews = form.watch('reviews');

  const reviews = form.getValues('reviews');

  const availableReviews = useMemo(
    () =>
      reviewsAll?.filter(
        (feedback) =>
          form
            .getValues('reviews')
            ?.findIndex((reviewInCourse) => feedback.id === reviewInCourse) === -1,
      ) ?? [],
    [reviewsAll, watchReviews],
  );

  const feedbackFormatted = useMemo(
    () =>
      availableReviews.map((review) => ({
        label: review.full_name[language],
        value: String(review.id),
        avatar: review.image,
        review: review.description[language],
        position: review.team_position[language],
      })) || [],
    [availableReviews, language],
  );

  const handleAdd = (id: string) => {
    form.setValue('reviews', [...form.getValues('reviews'), Number(id)], { shouldDirty: true });
  };

  const handleDelete = (idx: number) => {
    const reviewsCopy = form.getValues('reviews');
    reviewsCopy.splice(idx, 1);
    form.setValue('reviews', reviewsCopy, { shouldDirty: true });
  };

  return (
    <Stack>
      {availableReviews.length > 0 && (
        <Select
          clearable={false}
          label={<Text>Add review</Text>}
          data={feedbackFormatted}
          limit={20}
          value={null}
          searchable
          onChange={handleAdd}
          itemComponent={Item}
        />
      )}
      {reviews?.map((review, idx) => (
        <ReviewCard key={review} reviewId={review} handleDelete={() => handleDelete(idx)} />
      ))}
    </Stack>
  );
};
