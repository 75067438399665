import { instance } from './baseApi';
import { ICourse, IRate, IShortCourses } from '../redux/courses/coursesTypes';

export const coursesApi = {
  getCourses: () => instance.get<IShortCourses>('v1/admin/courses/short'),

  getCoursesList: (status: string, page: number) =>
    instance.get<ICourse>(`v1/admin/courses?status=${status}&page=${page}`),

  createCourse: (data: ICourse) => instance.post('v1/admin/courses/', data),

  duplicateCourse: (id: number) => instance.post(`v1/admin/courses/${id}/replicate`),

  getCourseById: (id: string) => instance.get(`v1/admin/courses/${id}`),

  deleteCourse: (id: number) => instance.delete(`v1/admin/courses/${id}`),

  updateCourse: (id: string, courseData: ICourse) => instance.put(`v1/admin/courses/${id}`, courseData),

  getRates: () => instance.get('v1/admin/courses/learning-formats'),

  getRate: (id: string) => instance.get(`v1/admin/courses/learning-formats/${id}`),

  createRate: (rate: Omit<IRate, 'id'>) => instance.post('v1/admin/courses/learning-formats', rate),

  updateRate: (id: string, rate: Omit<IRate, 'id'>) => instance.put(`v1/admin/courses/learning-formats/${id}`, rate),

  deleteRate: (id: number) => instance.delete(`v1/admin/courses/learning-formats/${id}`),

  createFeedback: (feedback: any) => instance.post('v1/admin/courses/feedbacks', feedback),

  updateFeedback: (id: string, feedback: any) => instance.put(`v1/admin/courses/feedbacks/${id}`, feedback),

  getFeedbacks: () => instance.get('v1/admin/courses/feedbacks'),

  getFeedback: (id: string) => instance.get(`v1/admin/courses/feedbacks/${id}`),

  deleteFeedback: (id: number) => instance.delete(`v1/admin/courses/feedbacks/${id}`),

  getReviews: () => instance.get(`v1/admin/course-reviews`),

  getReview: (id: string) => instance.get(`v1/admin/course-reviews/${id}`),

  createReview: (review: any) => instance.post('v1/admin/course-reviews', review),

  updateReview: (id: string, review: any) => instance.put(`v1/admin/course-reviews/${id}`, review),

  deleteReview: (id: number) => instance.delete(`v1/admin/course-reviews/${id}`),
};
