import { useLanguage } from 'components/Language/LanguageContext';
import { Stack, TextInput, Text } from '@mantine/core';
import { LanguageFlags } from 'components/Language/types';
import { Controller, useFormContext } from 'react-hook-form';
import { ILang } from 'helpers/commonTypes';

interface Meta {
  meta_title: ILang;
  meta_description: ILang;
}

export const MetaTeacherForm = () => {
  const form = useFormContext<Meta>();
  const { language } = useLanguage();

  return (
    <Stack>
      <Controller
        name={`meta_title.${language}`}
        key={`meta_title.${language}`}
        control={form.control}
        render={({ field }) => (
          <TextInput
            withAsterisk
            label="Meta title"
            placeholder="Enter title for meta"
            rightSection={<Text>{LanguageFlags[language]}</Text>}
            {...field}
          />
        )}
      />
      <Controller
        name={`meta_description.${language}`}
        key={`meta_description.${language}`}
        control={form.control}
        render={({ field }) => (
          <TextInput
            withAsterisk
            label="Meta description"
            placeholder="Enter description for meta"
            rightSection={<Text>{LanguageFlags[language]}</Text>}
            {...field}
          />
        )}
      />
    </Stack>
  );
};
