import { Group, TextInput, Text, Select, Textarea, Stack, MultiSelect } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageFlags, LanguageNames } from 'components/Language/types';
import { Controller, useFormContext } from 'react-hook-form';

export const GeneralForm = () => {
  const form = useFormContext();
  const { language } = useLanguage();

  return (
    <Stack>
      <Textarea
        key={`name-${language}`}
        withAsterisk
        label={`Course name`}
        placeholder="Enter course name"
        {...form.register(`name.${language}`)}
        rightSection={<Text>{LanguageFlags[language]}</Text>}
      />

      <Group>
        <Controller
          name="start_at"
          control={form.control}
          render={({ field }) => (
            <DatePickerInput placeholder="Pick date" label="Start date" {...field} />
          )}
        />

        <Controller
          name="format"
          control={form.control}
          render={({ field }) => (
            <Select
              label="Course format"
              placeholder="Pick format"
              data={[
                { value: 'offline', label: 'Offline' },
                { value: 'online', label: 'Online' },
              ]}
              {...field}
            />
          )}
        />

        <Controller
          name="status"
          control={form.control}
          render={({ field }) => (
            <Select
              withAsterisk
              label="Status"
              placeholder="Pick format"
              data={[
                { value: 'active', label: 'Active' },
                { value: 'archive', label: 'Archive' },
                { value: 'drafts', label: 'Drafts' },
              ]}
              {...field}
            />
          )}
        />

        <TextInput
          key={`workload`}
          label="Workload"
          placeholder="Enter workload in ISO 8601 format"
          {...form.register(`workload`)}
        />
      </Group>

      <Textarea
        key={`description-${language}`}
        withAsterisk
        autosize
        minRows={2}
        maxRows={4}
        description="255 symbols maximum"
        placeholder="Description"
        label="Description"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        {...form.register(`description.${language}`)}
      />

      <TextInput
        key={`slug-${language}`}
        withAsterisk
        label="Slug"
        placeholder="Enter slug"
        {...form.register(`slug.${language}`)}
        rightSection={<Text>{LanguageFlags[language]}</Text>}
      />

      <Controller
        control={form.control}
        name="languages"
        render={({ field }) => (
          <MultiSelect
            {...field}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
            label="Languages"
            placeholder="Pick language"
          />
        )}
      />
    </Stack>
  );
};
