import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Group, LoadingOverlay, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

import { coursesActions } from '../../redux/courses/coursesSlice';
import { coursesSelectors } from '../../redux/courses/coursesSelectors';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { APP_ROUTES } from '../../constants';
import { EmptyListContainer } from '../../components/containers';
import { FeedbackCard } from '../../components';

export const CoursePageReviews = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const reviews = useSelector(coursesSelectors.selectReviews);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);

  const isEmpty = !reviews || !reviews.length;

  const handleCreate = () => {
    navigate(APP_ROUTES.CREATE_COURSES_REVIEW);
  };

  const handleDelete = useCallback((id: number) => {
    openConfirmModal({
      title: 'Delete review',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete review?</Text>,
      labels: { confirm: 'Delete review', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(coursesActions.deleteReview(id)),
    });
  }, []);

  const handleChange = useCallback((id: number) => {
    navigate(`${APP_ROUTES.CREATE_COURSES_REVIEW}/${id}`);
  }, []);

  useEffect(() => {
    dispatch(coursesActions.getReviews());
  }, []);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }
  //
  if (!reviews) {
    return null;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        buttonText="Add review"
        title="Your reviews list is empty."
        description="Add review and enter information about them."
        onClick={handleCreate}
      >
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title order={1}>Courses page reviews</Title>
          <Button onClick={handleCreate}>Add review</Button>
        </Group>
        {reviews.map(({ full_name, image, id }: any) => (
          <FeedbackCard
            handleDelete={() => handleDelete(id)}
            handleChange={() => handleChange(id)}
            key={id}
            name={full_name.en}
            avatar={image}
          />
        ))}
      </EmptyListContainer>
    </Container>
  );
};
