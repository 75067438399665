import {
  Alert,
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  Tabs,
  Textarea,
  Title,
  MultiSelect,
  Modal,
  Checkbox
} from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import { LanguageProvider, useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageFlags, LanguageNames } from 'components/Language/types';
import { RTE } from 'components/RTE';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { blogSelectors } from 'redux/blog/blogSelectors';
import { blogActions } from 'redux/blog/blogSlice';
import { IPost } from 'redux/blog/blogTypes';
import styles from './PostPreview.module.scss';
import { IconEye } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import 'react-advanced-cropper/dist/style.css';
import { ImageField } from './ImageFiels';

const getEmptyPost = (): IPost => ({
  title: {
    en: '',
    rus: '',
    est: '',
  },
  description: {
    en: '',
    rus: '',
    est: '',
  },
  quotation: {
    en: '',
    rus: '',
    est: '',
  },
  short_description: {
    en: '',
    rus: '',
    est: '',
  },
  recommended: false,
  main_photo: '',
  tags: [],
  languages: [],
});

function SaveAlert() {
  return (
    <Alert color="yellow" radius="md" variant="filled">
      Unsaved data
    </Alert>
  );
}

function SaveForm() {
  return (
    <Button color="green" radius="md" variant="filled" type="submit">
      Save
    </Button>
  );
}

const PreviewForm = ({ form }: { form: UseFormReturnType<IPost> }) => {
  const { language } = useLanguage();
  return (
    <div className={styles.postDetailsPage}>
      <div className={styles.container}>
        <div className={styles.thinContainer}>
          <h1>{form.values.title[language]}</h1>
          <div className={styles.mainPhotoWrapper}>
            <img src={form.values.main_photo} alt="mainPhoto" className={styles.mainPhoto} />
          </div>
          <div className={styles.quotation}>
            <div className={styles.icon}>
              <i className="fi-rr-quote-right" />
            </div>

            {form.values.quotation[language]}
          </div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: form.values.description[language] }}
          ></div>
          {/* <div className={styles.bottom}>
            <div className={styles.tags}>
              {form.values.tags.map((tag) => (
                <div className={styles.tag} key={tag.value}>
                  {tag.label[language]}
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const TextForm = ({ form }: { form: UseFormReturnType<IPost> }) => {
  const { language } = useLanguage();

  return <RTE className={styles.RTE} {...form.getInputProps(`description.${language}`)} />;
};

const GeneralForm = ({ form }: { form: UseFormReturnType<IPost> }) => {
  const { language, updateLanguage } = useLanguage();
  const tags = useSelector(blogSelectors.selectTags);

  const onUpdateImage = useCallback(
    (newImage: string) => {
      form.setFieldValue('main_photo', newImage);
    },
    [form.setFieldValue],
  );

  return (
    <Stack>
      <ImageField onChange={onUpdateImage} currentValue={form.values.main_photo} />
      <Textarea
        label={`Post title`}
        placeholder="Enter post title"
        {...form.getInputProps(`title.${language}`)}
        rightSection={<Text>{LanguageFlags[language]}</Text>}
      />
      <Textarea
        label="Short description"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        placeholder="Enter short description"
        inputWrapperOrder={['label', 'error', 'input', 'description']}
        {...form.getInputProps(`short_description.${language}`)}
      />
      <Textarea
        label="Quote"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        placeholder="Enter quote"
        inputWrapperOrder={['label', 'error', 'input', 'description']}
        {...form.getInputProps(`quotation.${language}`)}
      />
      <MultiSelect
        data={tags.map((tag: any) => ({ value: `${tag.value}`, label: tag.label.en }))}
        label="Tags"
        placeholder="Select tags"
        value={form.values.tags.map((tag) => `${tag}`)}
        onChange={(values) => form.setFieldValue('tags', [...values])}
      />
      <MultiSelect
        {...form.getInputProps(`languages`)}
        onChange={(value) => form.setFieldValue('languages', value)}
        data={Object.keys(LanguageNames).map((lang) => ({
          label: LanguageNames[lang as Language],
          value: lang,
        }))}
        label="Languages"
        placeholder="Pick language"
      />
      <Checkbox  label="Should be recommended" {...form.getInputProps('recommended')} />
    </Stack>
  );
};

export const CourseHeader = ({ pageTitle }: any) => {
  return (
    <Flex justify="space-between" align="center" direction="row" wrap="wrap">
      <Title order={3}>{pageTitle}</Title>
    </Flex>
  );
};

export const Form = ({ form }: { form: UseFormReturnType<IPost> }) => {
  const { language, updateLanguage } = useLanguage();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Tabs
      defaultValue="meta"
      keepMounted={false}
      variant="pills"
      radius="md"
      orientation="vertical"
      mt={5}
    >
      <Tabs.List style={{ position: 'sticky', top: '80px', alignSelf: 'flex-start' }} mb={10}>
        <Tabs.Tab value="meta">Meta</Tabs.Tab>
        <Tabs.Tab value="text">Text</Tabs.Tab>

        <Button onClick={open} color="green" radius="md" variant="filled" rightIcon={<IconEye />}>
          Preview
        </Button>

        <Stack mt={1}>
          <SegmentedControl
            size="sm"
            value={language}
            onChange={(value) => updateLanguage(value as Language)}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
          />
          {form.isDirty() && <SaveAlert />}
          {form.isDirty() && <SaveForm />}
        </Stack>
      </Tabs.List>

      <Tabs.Panel value="meta" pl="xs">
        <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
          <GeneralForm form={form} />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="text" pl="xs">
        <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
          <TextForm form={form} />
        </Paper>
      </Tabs.Panel>

      <Modal size="calc(100vw - 3rem)" opened={opened} onClose={close} title="Post preview">
        <PreviewForm form={form} />
      </Modal>
    </Tabs>
  );
};

export const EditablePost = () => {
  const { id } = useParams<{ id: string }>();
  const post = useSelector(blogSelectors.selectPost);
  const isCoursesFetching = useSelector(blogSelectors.selectIsFetching);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isEditMode = !!id;
  const pageTitle = isEditMode ? `Edit post: ${post?.title?.en}` : `New post`;
  const isFetching = isCoursesFetching;

  const form = useForm<IPost>({ initialValues: { ...getEmptyPost() } });

  useEffect(() => {
    if (id) {
      dispatch(blogActions.getPost(+id));
    }
    dispatch(blogActions.getTags());
  }, [dispatch, id]);

  useEffect(() => {
    if (isEditMode && post) {
      const currentData = {
        title: post.title,
        description: post.description,
        quotation: post.quotation,
        short_description: post.short_description,
        recommended: post.recommended,
        main_photo: post.main_photo,
        tags: post.tags,
        languages: post.languages,
      };

      form.setValues(currentData);
      form.resetDirty(currentData);
    }
  }, [isFetching, isEditMode, post]);

  const onSubmit = (values: typeof form.values) => {
    const post = { ...values };

    if (isEditMode && id) {
      dispatch(blogActions.updatePost({ id: +id, post, navigate }));
    } else {
      dispatch(blogActions.createPost({ post, navigate }));
    }
  };

  return (
    <LanguageProvider>
      <Container size="lg">
        <CourseHeader pageTitle={pageTitle} />
        <LoadingOverlay visible={isFetching} overlayBlur={2} />
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} />
        </form>
      </Container>
    </LanguageProvider>
  );
};
