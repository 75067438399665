import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { handleServerNetworkError } from '../../helpers/helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { showNotification } from '@mantine/notifications';
import { APP_ROUTES } from '../../constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { IQuestionsResponse, IQuestionValues, ITestResponse, ITestValues, IUnitResponse } from './types';
import { testsActions } from './testsSlice';
import { testsApi } from '../../api/testsApi';
import { testsSelectors } from './testsSelectors';

function* createTest({ payload }: PayloadAction<{ test: ITestValues; navigate: NavigateFunction }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<ITestResponse> = yield testsApi.createTest(payload.test);
    showNotification({
      message: `Test successfully created`,
      color: 'green',
      autoClose: 3000,
    });

    payload.navigate(`${APP_ROUTES.CREATE_TEST}/${data.id}`);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* updateTest({ payload }: PayloadAction<{ test: ITestValues; testId: number; navigate: NavigateFunction }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data } = yield testsApi.updateTest(payload.testId, payload.test);

    showNotification({
      message: `Test name and description successfully updated`,
      color: 'green',
      autoClose: 3000,
    });

    payload.navigate(`${APP_ROUTES.EDIT_TEST_QUESTIONS}/${data.id}`);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* getTests() {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data } = yield testsApi.getTests();

    yield put(testsActions.setTests(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* getTest({ payload }: PayloadAction<number>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data } = yield testsApi.getTest(payload);

    yield put(testsActions.setTest(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* deleteTest({ payload }: PayloadAction<number>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.deleteTest(payload);

    yield call(getTests);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* createQuestions({
  payload,
}: PayloadAction<{ questions: IQuestionValues[]; testId: number; navigate: NavigateFunction }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.createQuestions(payload.questions, payload.testId);

    payload.navigate(`${APP_ROUTES.CREATE_TEST}/${payload.testId}/units`);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* updateQuestions({
  payload,
}: PayloadAction<{ questions: IQuestionValues[]; testId: number; navigate: NavigateFunction }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.updateQuestions(payload.questions, payload.testId);

    payload.navigate(`${APP_ROUTES.CREATE_TEST}/${payload.testId}/units`);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* getTestUnits({ payload }: PayloadAction<number>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IUnitResponse[]> = yield testsApi.getTestUnits(payload);

    yield put(testsActions.setTestUnits(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* getTestUnit({ payload }: PayloadAction<{ testId: number; unitId: number }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IUnitResponse> = yield testsApi.getTestUnit(payload.testId, payload.unitId);

    yield put(testsActions.setTestUnit(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* createTestUnit({ payload }: PayloadAction<{ testId: number; name: string; navigate: NavigateFunction }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data } = yield testsApi.createTestUnit(payload.testId, payload.name);

    const units: IUnitResponse[] = yield select(testsSelectors.selectUnits);

    yield put(testsActions.setTestUnits([...units, data]));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* deleteTestUnit({ payload }: PayloadAction<{ testId: number; unitId: number }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.deleteTestUnit(payload.testId, payload.unitId);

    yield call<any>(getTestUnits, { payload: payload.testId });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* updateTestUnit({
  payload: { testId, unitId, name },
}: PayloadAction<{ testId: number; unitId: number; name: string }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.updateTestUnit(testId, unitId, name);

    yield call<any>(getTestUnits, { payload: testId });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* getTestQuestions({ payload }: PayloadAction<number>) {
  yield put(testsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IQuestionsResponse[]> = yield testsApi.getTestQuestions(payload);

    yield put(testsActions.setTestQuestions(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

function* bindUnit({ payload }: PayloadAction<{ testId: number; unitId: number; questionId: number }>) {
  yield put(testsActions.setIsFetching(true));
  try {
    yield testsApi.bindUnit(payload.testId, payload.questionId, payload.unitId);

    yield call<any>(getTestQuestions, { payload: payload.testId });
    yield call<any>(getTestUnits, { payload: payload.testId });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(testsActions.setIsFetching(false));
  }
}

export function* testsSagas() {
  yield all([
    takeLatest(testsActions.createTest.toString(), createTest),
    takeLatest(testsActions.getTests.toString(), getTests),
    takeLatest(testsActions.getTest.toString(), getTest),
    takeLatest(testsActions.deleteTest.toString(), deleteTest),
    takeLatest(testsActions.updateTest.toString(), updateTest),
    takeLatest(testsActions.createQuestions.toString(), createQuestions),
    takeLatest(testsActions.updateQuestions.toString(), updateQuestions),
    takeLatest(testsActions.getTestUnits.toString(), getTestUnits),
    takeLatest(testsActions.getTestUnit.toString(), getTestUnit),
    takeLatest(testsActions.createTestUnit.toString(), createTestUnit),
    takeLatest(testsActions.deleteTestUnit.toString(), deleteTestUnit),
    takeLatest(testsActions.updateTestUnit.toString(), updateTestUnit),
    takeLatest(testsActions.getTestQuestions.toString(), getTestQuestions),
    takeLatest(testsActions.bindUnit.toString(), bindUnit),
  ]);
}
