import { Select, Stack, Text } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { WebinarCard } from 'components/WebinarCard/WebinarCard';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { webinarsSelectors } from 'redux/webinars/webinarsSelectors';
import { webinarsActions } from 'redux/webinars/webinarsSlice';
import { Alert } from '@mantine/core';
import { IconSchool } from '@tabler/icons-react';
import { Controller, useFormContext } from 'react-hook-form';
import { stripHTML } from 'helpers/strip-html';

interface FormWithWebinar {
  webinar?: number;
}

function NoWebinarAlert() {
  return (
    <Alert icon={<IconSchool size="4rem" />} color="teal">
      You can select one webinar for this course
    </Alert>
  );
}

export const WebinarForm = () => {
  const form = useFormContext<FormWithWebinar>();
  const { language } = useLanguage();

  const webinars = useSelector(webinarsSelectors.selectWebinars);
  const webinar = useSelector(webinarsSelectors.selectWebinar);
  const dispatch = useAppDispatch();

  const watchWebinar = form.watch('webinar');

  const selectedWebinar = form.getValues('webinar');

  useEffect(() => {
    if (!!selectedWebinar) {
      dispatch(webinarsActions.getWebinar(selectedWebinar.toString()));
    }
  }, [watchWebinar]);

  const webinarsFormatted = useMemo(
    () =>
      webinars?.map((webinar) => ({
        label: stripHTML(webinar.name.en),
        value: String(webinar.id),
      })) || [],
    [webinars],
  );

  const removeWebinar = useCallback(() => {
    form.setValue('webinar', undefined, { shouldDirty: true });
  }, [form]);

  return (
    <Stack>
      {!selectedWebinar && <NoWebinarAlert />}

      {webinar && selectedWebinar && (
        <WebinarCard
          withTimer={webinar.timer_enabled}
          key={webinar.id}
          handleEdit={() => {}}
          handleDelete={removeWebinar}
          name={webinar.name[language]}
          date={webinar.start_at_date as string}
          time={webinar.start_at_time as string}
          url={webinar.kajabi_url}
        />
      )}

      <Controller
        name="webinar"
        control={form.control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={<Text>Select webinar</Text>}
            data={webinarsFormatted}
            limit={20}
            searchable
            onChange={(e) => (e ? field.onChange(parseInt(e)) : null)}
          />
        )}
      />
    </Stack>
  );
};
