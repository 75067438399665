export enum Language {
  ENG = 'en',
  EST = 'est',
  RUS = 'rus',
}

export const LanguageNames = {
  [Language.ENG]: 'ENG 🇬🇧',
  [Language.EST]: 'EST 🇪🇪',
  [Language.RUS]: 'RU 🇷🇺',
};

export const LanguageFlags = {
  [Language.ENG]: '🇬🇧',
  [Language.EST]: '🇪🇪',
  [Language.RUS]: '🇷🇺',
};
