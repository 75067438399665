import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { teachersActions } from './teachersSlice';
import { teachersApi } from '../../api/teachersApi';
import { handleServerNetworkError } from '../../helpers/helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { showNotification } from '@mantine/notifications';
import { APP_ROUTES } from '../../constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { teachersSelectors } from './teachersSelectors';
import { IPagination } from '../pagination/paginationTypes';
import { EditableTeacher, ITeacher } from './teachersTypes';

function* createTeacher({ payload }: PayloadAction<{ teacher: EditableTeacher; navigate: NavigateFunction }>) {
  yield put(teachersActions.setIsFetching(true));
  try {
    yield teachersApi.createTeacher(payload.teacher);
    yield put(teachersActions.setIsFetchingError(false));

    showNotification({
      message: `Teacher successfully created`,
      color: 'green',
      autoClose: 3000,
    });

    payload.navigate(APP_ROUTES.TEACHERS);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
    yield put(teachersActions.setIsFetchingError(true));
  } finally {
    yield put(teachersActions.setIsFetching(false));
  }
}

function* getTeachers({ payload }: PayloadAction<{ page: number }>) {
  yield put(teachersActions.setIsFetching(true));
  try {
    //TODO: fix any
    const { data }: AxiosResponse<{ data: ITeacher[]; meta: any }> = yield teachersApi.getTeachers(payload.page);

    yield put(teachersActions.setTeachers(data.data));
    yield put(teachersActions.setPaginationData(data.meta));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(teachersActions.setIsFetching(false));
  }
}

function* getTeacher({ payload }: PayloadAction<string>) {
  yield put(teachersActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<ITeacher> = yield teachersApi.getTeacher(payload);
    yield put(teachersActions.setTeacher(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(teachersActions.setIsFetching(false));
  }
}

function* deleteTeacher({ payload }: PayloadAction<number>) {
  yield put(teachersActions.setIsFetching(true));
  try {
    yield teachersApi.deleteTeacher(payload);

    const paginationData: IPagination = yield select(teachersSelectors.selectPaginationData);

    const getTeacherPayload = { payload: { page: paginationData.current_page } };

    yield call<any>(getTeachers, getTeacherPayload);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(teachersActions.setIsFetching(false));
  }
}

function* updateTeacher({
  payload: { id, teacher, navigate },
}: PayloadAction<{ id: string; teacher: EditableTeacher; navigate: NavigateFunction }>) {
  yield put(teachersActions.setIsFetching(true));
  try {
    yield put(teachersActions.setIsFetchingError(false));
    const response: AxiosResponse<ITeacher> = yield call(teachersApi.updateTeacher, id, teacher);

    // navigate(APP_ROUTES.TEACHERS);

    yield put(teachersActions.setTeacher(response.data));
  } catch (error) {
    yield put(teachersActions.setIsFetchingError(true));
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(teachersActions.setIsFetching(false));
  }
}

export function* teachersSagas() {
  yield all([
    takeEvery(teachersActions.createTeacher.toString(), createTeacher),
    takeEvery(teachersActions.getTeachers.toString(), getTeachers),
    takeEvery(teachersActions.deleteTeacher.toString(), deleteTeacher),
    takeEvery(teachersActions.getTeacher.toString(), getTeacher),
    takeEvery(teachersActions.updateTeacher.toString(), updateTeacher),
  ]);
}
