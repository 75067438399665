import { useLanguage } from 'components/Language/LanguageContext';
import { useWebinarFormContext } from '../webinar-form-context';
import { Stack, TextInput, Text, Textarea, Group, Checkbox } from '@mantine/core';
import { LanguageFlags } from 'components/Language/types';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { RTE } from 'components/RTE';

export const WebinarGeneralFormSection: React.FC = () => {
  const { language } = useLanguage();
  const form = useWebinarFormContext();

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValues({ ...form.values, start_at_time: e.target.value });
  };

  return (
    <Stack>
      <RTE
        // styles={{ required: { display: 'none' } }}
        // required
        label="Name"
        // placeholder="Enter name"
        {...form.getInputProps(`name.${language}`)}
        // rightSection={<Text>{LanguageFlags[language]}</Text>}
      />
      <RTE
        label="Description"
        {...form.getInputProps(`description.${language}`)}
      />

      <TextInput
        styles={{ required: { display: 'none' } }}
        required
        label="Link"
        placeholder="Enter link"
        {...form.getInputProps('kajabi_url')}
      />

      <Checkbox
        mt="xs"
        label="With timer"
        {...form.getInputProps('timer_enabled', { type: 'checkbox' })}
      />

      {form.values.timer_enabled && (
        <Group>
          <DatePickerInput label="Select date" {...form.getInputProps('start_at_date')} />
          <TimeInput
            label="Select time"
            {...form.getInputProps('start_at_time')}
            onInput={handleChangeTime}
          />
        </Group>
      )}
    </Stack>
  );
};
