export const APP_ROUTES = {
  PAGES: '/pages',
  PAGE: '/pages/:id',
  LOGIN: '/login',
  TEACHERS: '/teachers',
  CREATE_TEACHER: '/create-teacher',
  CHANGE_TEACHER: '/change-teacher',
  COURSES: '/courses',
  CREATE_COURSE: '/create-course',
  EDIT_COURSE_NEW: '/courses-new',
  RATES: '/rates',
  CREATE_RATE: '/create-rate',
  POSTS: '/posts',
  NEW_POST: '/new-post',
  TAGS: '/tags',
  FEEDBACKS: '/feedbacks',
  CREATE_FEEDBACK: '/create-feedback',
  PRIVACY_POLICY: '/privacy-policy',
  WEBINARS: '/webinars',
  CREATE_WEBINAR: '/create-webinar',
  TERMS_CONDITIONS: '/terms-conditions',
  TRAINING_QA: '/training-qa',
  BASIC_OF_STUDY_ORGANISATION: '/basic-of-study-organisation',
  TESTS: '/tests',
  CREATE_TEST: '/create-test',
  EDIT_TEST: '/edit-test',
  EDIT_TEST_QUESTIONS: '/edit-test-questions',
  COURSES_REVIEW: '/courses-page-review',
  CREATE_COURSES_REVIEW: '/create-courses-page-review',
};

export const initialTags = ['Founder', 'Mentor', 'Engineer', 'Recruiter', 'Curator', 'Sourcer', 'Relocation'];
export const initialLanguages = ['en', 'rus', 'est'];
