import { instance } from 'api/baseApi';
import { fetcher } from 'helpers/fetcher';
import { handleServerNetworkError } from 'helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { EditableCourse, ICourse } from 'redux/courses/coursesTypes';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

const updateCourse = (url: string, { arg }: { arg: EditableCourse }) =>
  instance.put(url, arg).then((res) => res.data as ICourse);

const createCourse = (url: string, { arg }: { arg: EditableCourse }) =>
  instance.post(url, arg).then((res) => res.data as ICourse);

export const useCourse = (id?: string) => {
  const navigate = useNavigate();

  const query = useSWR<ICourse>(id ? `v1/admin/courses/${id}` : null, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const updateMutation = useSWRMutation(`v1/admin/courses/${id}`, updateCourse, {
    onError(err, key, config) {
      handleServerNetworkError(err);
    },
  });

  const createMutation = useSWRMutation(`v1/admin/courses/`, createCourse, {
    onSuccess(data, key, config) {
      navigate(`/courses-new/${data.id}`);
    },
    onError(err, key, config) {
      handleServerNetworkError(err);
    },
  });

  const triggerUpdate = updateMutation.trigger;

  const triggerCreate = createMutation.trigger;

  const isLoading = query.isLoading || updateMutation.isMutating || createMutation.isMutating;
  const isError = !!query.error || !!updateMutation.error || !!createMutation.error;

  const course = query.data;

  return {
    isLoading,
    isError,
    course,

    triggerUpdate,
    triggerCreate,
  };
};
