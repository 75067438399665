import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Stack,
  Textarea,
  TextInput,
  Text,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { AddIcon } from 'assets/icons/AddIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { useLanguage } from 'components/Language/LanguageContext';
import { LanguageFlags } from 'components/Language/types';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const FAQForm = () => {
  const { language } = useLanguage();
  const form = useFormContext();

  const fieldsArray = useFieldArray({
    control: form.control,
    name: 'any_questions_block',
  });

  const handleAddFaq = () => {
    fieldsArray.append({
      key: randomId(),
      title: {
        en: '',
        rus: '',
        est: '',
      },
      description: {
        en: '',
        rus: '',
        est: '',
      },
    });
  };

  return (
    <Stack>
      {fieldsArray.fields.map((item, index) => (
        <Stack key={item.id}>
          <TextInput
            key={`any_questions_block.${item.id}.title.${language}`}
            withAsterisk
            style={{ width: '100%' }}
            label="Question"
            placeholder="Enter question"
            rightSection={<Text>{LanguageFlags[language]}</Text>}
            {...form.register(`any_questions_block.${index}.title.${language}`)}
          />
          <Group>
            <Textarea
              key={`any_questions_block.${item.id}.description.${language}`}
              withAsterisk
              style={{ width: '100%' }}
              autosize
              label="Answer"
              placeholder="Enter answer"
              rightSection={<Text>{LanguageFlags[language]}</Text>}
              {...form.register(`any_questions_block.${index}.description.${language}`)}
            />
            <ActionIcon mt="xl" color="red" onClick={() => fieldsArray.remove(index)}>
              <DeleteIcon />
            </ActionIcon>
          </Group>
          <Divider my="sm" variant="dashed" />
        </Stack>
      ))}
      <Button mt="md" leftIcon={<AddIcon />} onClick={handleAddFaq}>
        Add question
      </Button>
    </Stack>
  );
};
