import { instance } from './baseApi';
import { ILogin } from '../redux/auth/authTypes';

export const authApi = {
  login: (login: string, password: string) => instance.post<ILogin>(`v1/admin/login`, { login, password }),
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};
