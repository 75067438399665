import { UseFormReturnType } from '@mantine/form';
import { IPost } from '../../../redux/blog/blogTypes';
import { ILang } from '../../../helpers/commonTypes';
import { Textarea, TextInput, Title } from '@mantine/core';
import { RichTextEditor } from '@mantine/rte';
import { RTE } from 'components/RTE';

type Props = {
  form: UseFormReturnType<IPost, any>;
  language: string;
};

export const PostForm = ({ form, language }: Props) => {
  return (
    <div>
      <TextInput
        styles={{ required: { display: 'none' } }}
        required
        label="Title"
        placeholder="Enter title"
        {...form.getInputProps(`title.${language}`)}
      />
      <Title mt="xl" color="blue" mb="xl" order={2}>
        Short description
      </Title>
      <Textarea
        styles={{ required: { display: 'none' } }}
        autosize
        required
        placeholder="Enter text"
        {...form.getInputProps(`short_description.${language}`)}
      />

      <Title mt="xl" color="blue" mb="xl" order={2}>
        Quotation
      </Title>
      <Textarea
        styles={{ required: { display: 'none' } }}
        autosize
        required
        placeholder="Enter text"
        {...form.getInputProps(`quotation.${language}`)}
      />

      <Title mt="xl" color="blue" mb="xl" order={2}>
        Post text
      </Title>
      <RTE {...form.getInputProps(`description.${language}`)} />
    </div>
  );
};
