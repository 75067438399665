import { Button, Card, Container, LoadingOverlay, Paper, Stack, Table, Title } from '@mantine/core';
import { EditIcon } from 'assets/icons/EditIcon';
import { Page, usePages } from 'components/Pages/api';
import { APP_ROUTES } from 'constants/index';
import { Link } from 'react-router-dom';

const PageNames = {
  main_page: 'Main',
  courses: 'Courses',
  'about-us': 'About us',
};

const PagesList = () => {
  const { data: list } = usePages();

  const rows = list?.map((element) => (
    <tr key={element.id}>
      <td>{element.type}</td>
      <td>{element.type == 'Page' ? PageNames[element.name] : element.name}</td>
      <td>
        <Button
          component={Link}
          to={`${APP_ROUTES.PAGES}/${element.id}`}
          leftIcon={<EditIcon />}
          variant="outline"
          size="sm"
          compact
        >
          Edit
        </Button>
      </td>
    </tr>
  ));

  return (
    <Paper radius="md" style={{ border: '1px solid #EBEFFF', padding: 0 }} mt="xl" shadow="sm">
      <Table striped highlightOnHover withColumnBorders>
        <colgroup>
          <col span={1} style={{ width: '10%' }} />
          <col span={1} style={{ width: '70%' }} />
          <col span={1} style={{ width: '5%' }} />
        </colgroup>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th>Actions</th>
        </tr>
        <tbody>{rows}</tbody>
      </Table>
    </Paper>
  );
};

export const Pages = () => {
  const { isLoading } = usePages();

  return (
    <Container>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Stack spacing={2}>
        <Title order={2}>Pages</Title>
        <PagesList />
      </Stack>
    </Container>
  );
};
