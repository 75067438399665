import { UseFormReturnType } from '@mantine/form';
import { TextInput } from '@mantine/core';
import { IReviewForm } from 'pages/CoursePageReviews/CreateCourseReview/CreateCourseReview';
import { EditableReview } from 'redux/courses/coursesTypes';

type Props = {
  form: UseFormReturnType<EditableReview>;
  language: string;
};

export const CreateCourseReviewForm = ({ form, language }: Props) => {
  return (
    <div>
      <TextInput
        withAsterisk
        required
        mb="md"
        label="Full name"
        placeholder="Enter full name"
        {...form.getInputProps(`full_name.${language}`)}
      />
      <TextInput
        withAsterisk
        required
        mb="md"
        label="Full description"
        placeholder="Enter description"
        {...form.getInputProps(`description.${language}`)}
      />
      <TextInput
        withAsterisk
        required
        mb="md"
        label="Role"
        placeholder="Enter role"
        {...form.getInputProps(`team_position.${language}`)}
      />
    </div>
  );
};
