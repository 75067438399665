import * as React from 'react';
import { Language } from './types';

type Dispatch = React.Dispatch<React.SetStateAction<Language>>;
type CountProviderProps = { children: React.ReactNode };

const LanguageContext = React.createContext<{ language: Language; updateLanguage: Dispatch } | undefined>(undefined);

function LanguageProvider({ children }: CountProviderProps) {
  const [language, updateLanguage] = React.useState<Language>(Language.ENG);
  const value = { language, updateLanguage };
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

function useLanguage() {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
}

export { useLanguage, LanguageContext, LanguageProvider };
