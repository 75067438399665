import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IQuestionValues } from '../../../../redux/tests/types';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { testsActions } from '../../../../redux/tests/testsSlice';
import { testsSelectors } from '../../../../redux/tests/testsSelectors';
import { randomId } from '@mantine/hooks';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import { AddIcon } from '../../../../assets/icons/AddIcon';

type Props = {
  isEditMode?: boolean;
};

export const TestQuestions = ({ isEditMode = false }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testId } = useParams<{ testId: string }>();

  const test = useSelector(testsSelectors.selectTest);
  const isFetching = useSelector(testsSelectors.selectIsFetching);
  const questions = useSelector(testsSelectors.selectQuestions);

  const form = useForm<{ questions: IQuestionValues[] }>({
    initialValues: {
      questions: [
        {
          key: randomId(),
          name: '',
          type: 1,
          variants: [
            {
              key: randomId(),
              name: '',
              is_right: false,
              is_selected: false,
            },
            {
              key: randomId(),
              name: '',
              is_right: false,
              is_selected: false,
            },
          ],
        },
      ],
    },
  });

  const questionTypes = [
    { value: '1', label: 'True/false' },
    { value: '2', label: 'Single choice' },
    { value: '3', label: 'Multiple choice' },
  ];

  const fields = form.values.questions.map((questionItem, questionIndex) => (
    <Fragment key={questionItem.key}>
      <Group mt="xs">
        <TextInput
          label="Question"
          placeholder="question"
          sx={{ flex: 1 }}
          {...form.getInputProps(`questions.${questionIndex}.name`)}
        />
        <Select
          label="Select question type"
          placeholder="Pick one"
          data={questionTypes}
          {...form.getInputProps(`questions.${questionIndex}.type`)}
        />
        <ActionIcon mt={30} color="red" onClick={() => form.removeListItem('questions', questionIndex)}>
          <DeleteIcon />
        </ActionIcon>
      </Group>

      <Stack mt="md">
        {questionItem.variants.map((variantItem, variantsIndex) => (
          <Group key={variantItem.key}>
            <Checkbox
              mt="md"
              {...form.getInputProps(`questions.${questionIndex}.variants.${variantsIndex}.is_right`, {
                type: 'checkbox',
              })}
            />

            <TextInput {...form.getInputProps(`questions.${questionIndex}.variants.${variantsIndex}.name`)} />
            <ActionIcon
              disabled={questionItem.variants.length <= 2}
              color="red"
              onClick={() => form.removeListItem(`questions.${questionIndex}.variants`, variantsIndex)}
            >
              <DeleteIcon />
            </ActionIcon>
          </Group>
        ))}
        {!isEditMode && (
          <Group
            onClick={() =>
              form.insertListItem(`questions.${questionIndex}.variants`, {
                key: randomId(),
                name: '',
                is_right: false,
                is_selected: false,
              })
            }
          >
            <ActionIcon color="red">
              <AddIcon />
            </ActionIcon>
            <Text>Add answer</Text>
          </Group>
        )}
      </Stack>
    </Fragment>
  ));

  const onSubmit = (values: any) => {
    if (isEditMode && testId) {
      dispatch(testsActions.updateQuestions({ questions: values, testId: +testId, navigate }));
    } else if (testId) {
      dispatch(testsActions.createQuestions({ questions: values, testId: +testId, navigate }));
    }
  };

  const handleAddQuestion = () => {
    form.insertListItem('questions', {
      name: '',
      type: 2,
      key: randomId(),
      variants: [
        {
          key: randomId(),
          name: '',
          is_right: false,
          is_selected: false,
        },
        {
          key: randomId(),
          name: '',
          is_right: false,
          is_selected: false,
        },
      ],
    });
  };

  useEffect(() => {
    if (testId) {
      dispatch(testsActions.getTest(+testId));
      dispatch(testsActions.getTestQuestions(+testId));
    }
  }, [testId]);

  useEffect(() => {
    if (isEditMode && questions) {
      form.setValues({ questions });
    }
  }, [isEditMode, isFetching, questions]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!test) {
    return null;
  }

  return (
    <Container>
      <Group>
        <TextInput label="Test name" disabled placeholder={test.name} />
        <TextInput label="Test description" disabled placeholder={test.description} />
      </Group>
      <Text color="#0F68FC" size="xl" mt="xl">
        Questions for the test
      </Text>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <div>{fields}</div>
        <Group position="center" mt="md">
          {!isEditMode && <Button onClick={handleAddQuestion}>Add question</Button>}
        </Group>
        <Group position="right">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};
