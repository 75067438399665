export const EditIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.295 0.692534L13.3075 1.70503C13.9 2.29003 13.9 3.24253 13.3075 3.82753L3.385 13.75H0.25V10.615L8.05 2.80753L10.1725 0.692534C10.7575 0.107534 11.71 0.107534 12.295 0.692534ZM1.75 12.25L2.8075 12.295L10.1725 4.92253L9.115 3.86503L1.75 11.23V12.25Z"
        fill="#228be6"
      />
    </svg>
  );
};
