import { instance } from './baseApi';
import { EditableTeacher, ITeacher } from '../redux/teachers/teachersTypes';

export const teachersApi = {
  getTeacher: (id: string) => instance.get<ITeacher>(`v1/admin/teachers/${id}`),

  getTeachers: (page?: number) => instance.get<ITeacher[]>(`v1/admin/teachers?page=${page}`),

  createTeacher: (data: EditableTeacher) => instance.post('v1/admin/teachers', data),

  deleteTeacher: (id: number) => instance.delete(`v1/admin/teachers/${id}`),

  updateTeacher: (id: string, data: EditableTeacher) => instance.put<any>(`v1/admin/teachers/${id}`, data),
};
