import { memo, useEffect } from 'react';
import { Button, Group, LoadingOverlay, Select, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { coursesSelectors } from '../../redux/courses/coursesSelectors';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { coursesActions } from '../../redux/courses/coursesSlice';
import { testsSelectors } from '../../redux/tests/testsSelectors';
import { testsActions } from '../../redux/tests/testsSlice';
import { IUnitResponse } from '../../redux/tests/types';

type Props = {
  onSubmit?: (unit: any) => void;
  prevValue?: string;
  isEditMode?: boolean;
  unitId?: number;
  testId?: string;
};

export const UnitModal = memo(({ onSubmit, isEditMode = false, unitId, testId }: Props) => {
  const dispatch = useAppDispatch();

  const courses = useSelector(coursesSelectors.selectShortCourses);
  const unit = useSelector(testsSelectors.selectUnit);
  const isFetching = useSelector(testsSelectors.selectIsFetching);

  const form = useForm<Omit<IUnitResponse, 'id' | 'questions'>>({
    initialValues: {
      name: '',
      test_id: '',
      first_quarter_result_course_id: '',
      second_quarter_result_course_id: '',
      third_quarter_result_course_id: '',
      fourth_quarter_result_course_id: '',
      first_quarter_result_course_text: '',
      second_quarter_result_course_text: '',
      third_quarter_result_course_text: '',
      fourth_quarter_result_course_text: '',
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    if (onSubmit) {
      onSubmit(values);
      form.reset();
    }
  };

  useEffect(() => {
    dispatch(coursesActions.getCourses());
    if (testId && isEditMode && unitId) {
      dispatch(testsActions.getTestUnit({ testId: +testId, unitId }));
    }
  }, [isEditMode, unitId, testId]);

  useEffect(() => {
    if (isEditMode && unit && !isFetching) {
      console.log(unit);
      const currentData = {
        name: unit.name,
        test_id: unit.test_id,
        first_quarter_result_course_id: String(unit.first_quarter_result_course_id),
        second_quarter_result_course_id: String(unit.second_quarter_result_course_id),
        third_quarter_result_course_id: String(unit.third_quarter_result_course_id),
        fourth_quarter_result_course_id: String(unit.fourth_quarter_result_course_id),
        first_quarter_result_course_text: unit.first_quarter_result_course_text,
        second_quarter_result_course_text: unit.second_quarter_result_course_text,
        third_quarter_result_course_text: unit.third_quarter_result_course_text,
        fourth_quarter_result_course_text: unit.fourth_quarter_result_course_text,
      };

      form.setValues(currentData);
    }

    return () => {
      dispatch(testsActions.setTestUnit(null));
    };
  }, [unit, isEditMode, isFetching, form]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!courses) {
    return null;
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          styles={{ required: { display: 'none' } }}
          required
          label="Unit name"
          placeholder="Unit name"
          data-autofocus
          {...form.getInputProps('name')}
        />

        <Title mt="md" mb="md" order={3} color="blue">
          Данные о результате теста
        </Title>

        <Group>
          <TextInput
            styles={{ required: { display: 'none' } }}
            required
            label="Результат теста при 0-25% правильных ответов"
            placeholder="Комментарий к 0-25%"
            {...form.getInputProps('first_quarter_result_course_text')}
          />
          <Select
            styles={{ required: { display: 'none' } }}
            required
            data={courses.map(({ id, name }) => ({ value: `${id}`, label: name }))}
            label="Select course"
            placeholder="Select course"
            {...form.getInputProps('first_quarter_result_course_id')}
          />
        </Group>

        <Group mt="md">
          <TextInput
            styles={{ required: { display: 'none' } }}
            required
            label="Результат теста при 26-50% правильных ответов"
            placeholder="Комментарий к 26-50%"
            {...form.getInputProps('second_quarter_result_course_text')}
          />
          <Select
            styles={{ required: { display: 'none' } }}
            required
            label="Select course"
            placeholder="Select course"
            data={courses.map(({ id, name }) => ({ value: `${id}`, label: name }))}
            {...form.getInputProps('second_quarter_result_course_id')}
          />
        </Group>

        <Group mt="md">
          <TextInput
            styles={{ required: { display: 'none' } }}
            required
            label="Результат теста при 51-75% правильных ответов"
            placeholder="Комментарий к 51-75%"
            {...form.getInputProps('third_quarter_result_course_text')}
          />
          <Select
            styles={{ required: { display: 'none' } }}
            required
            label="Select course"
            placeholder="Select course"
            data={courses.map(({ id, name }) => ({ value: `${id}`, label: name }))}
            {...form.getInputProps('third_quarter_result_course_id')}
          />
        </Group>

        <Group mt="md">
          <TextInput
            styles={{ required: { display: 'none' } }}
            required
            label="Результат теста при 76-100% правильных ответов"
            placeholder="Комментарий к 76-100%"
            {...form.getInputProps('fourth_quarter_result_course_text')}
          />
          <Select
            styles={{ required: { display: 'none' } }}
            required
            label="Select course"
            placeholder="Select course"
            data={courses.map(({ id, name }) => ({ value: `${id}`, label: name }))}
            {...form.getInputProps('fourth_quarter_result_course_id')}
          />
        </Group>
        <Group position="right">
          <Button type="submit" mt="md">
            Save
          </Button>
        </Group>
      </form>
    </>
  );
});
