import { AxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from './authSlice';
import { authApi, initAuthorization } from '../../api/authApi';
import { handleServerNetworkError } from '../../helpers/helpers';
import { ILoginPayload } from './authTypes';
import { instance } from '../../api/baseApi';

// login: admin,
// password: Wo14cNwp

function* checkAuthStatusSaga() {
  try {
    initAuthorization();
    const token = localStorage.getItem('authToken');

    if (token) {
      yield put(authActions.setToken(token));
    }
  } catch (err) {
    console.error('checkAuthStatusSaga error', err);
  }
}

function* login({ payload: { login, password } }: PayloadAction<ILoginPayload>): Generator {
  try {
    yield put(authActions.setIsFetching(true));
    const { data }: any = yield authApi.login(login, password);
    const token = data.token;

    localStorage.setItem('authToken', token);

    yield call(checkAuthStatusSaga);

    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(authActions.setIsFetching(false));
  }
}

export function* authSagas() {
  yield all([call(checkAuthStatusSaga), takeLatest(authActions.login.toString(), login)]);
}
