import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { Nullable } from '../../helpers/nullable';
import { EditableWebinar, IWebinar } from './webinarsTypes';

interface IState {
  isFetching: boolean;
  webinars: Nullable<IWebinar[]>;
  webinar: Nullable<IWebinar>;
}

const initialState: IState = {
  isFetching: false,
  webinar: null,
  webinars: null,
};

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    setIsFetching(state: IState, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    setWebinars(state, { payload }: PayloadAction<IWebinar[]>) {
      state.webinars = payload;
    },
    setWebinar(state, { payload }: PayloadAction<IWebinar>) {
      state.webinar = payload;
    },
    getWebinars(state) {},
    createWebinar(state, { payload }: PayloadAction<any>) {},
    deleteWebinar(state, { payload }: PayloadAction<number>) {},
    updateWebinar(
      state,
      {
        payload,
      }: PayloadAction<{ id: string; webinar: EditableWebinar; navigate: NavigateFunction }>,
    ) {},
    getWebinar(state, { payload }: PayloadAction<string>) {},
  },
});

export const webinarsActions = webinarsSlice.actions;
export const webinarsReducer = webinarsSlice.reducer;
