import { Stack, TextInput, Text, Textarea, ActionIcon, Group, Button } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { AddIcon } from 'assets/icons/AddIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { useLanguage } from 'components/Language/LanguageContext';
import { LanguageFlags } from 'components/Language/types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

export const AboutCourseForm = () => {
  const form = useFormContext();
  const { language } = useLanguage();
  const fieldsArray = useFieldArray({
    control: form.control,
    name: 'description_block',
  });

  const handleAddAdvantage = () => {
    fieldsArray.append({
      key: randomId(),
      icon: '',
      title: {
        en: '',
        rus: '',
        est: '',
      },
      description: {
        en: '',
        rus: '',
        est: '',
      },
    });
  };

  return (
    <Stack>
      <Controller
        key={`about_course_header.${language}`}
        name={`about_course_header.${language}`}
        control={form.control}
        render={({ field }) => (
          <TextInput
            withAsterisk
            label="About course header"
            placeholder="Enter about course header"
            rightSection={<Text>{LanguageFlags[language]}</Text>}
            {...field}
          />
        )}
      />

      <Textarea
        key={`about_course_description.${language}`}
        withAsterisk
        autosize
        minRows={4}
        maxRows={4}
        label="About course description"
        placeholder="Enter about course description"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        {...form.register(`about_course_description.${language}`)}
      />

      <Controller
        name={`curriculum_link`}
        control={form.control}
        render={({ field }) => (
          <TextInput label="Curriculum Link" placeholder="Enter Curriculum Link" {...field} />
        )}
      />

      <Text color="#0F68FC" size="xl">
        Course advantages (6 max)
      </Text>
      {fieldsArray.fields.map((item, index) => {
        return (
          <Stack key={item.id} mt="xl">
            <Group>
              <TextInput
                key={`description_block.${index}.title.${language}`}
                withAsterisk
                label="Title"
                placeholder="title"
                rightSection={<Text>{LanguageFlags[language]}</Text>}
                {...form.register(`description_block.${index}.title.${language}`)}
              />
              <TextInput
                key={`description_block.${index}.icon`}
                withAsterisk
                label="Icon code"
                placeholder="Paste code"
                {...form.register(`description_block.${index}.icon`)}
              />
              <ActionIcon mt="xl" color="red" onClick={() => fieldsArray.remove(index)}>
                <DeleteIcon />
              </ActionIcon>
            </Group>
            <Textarea
              key={`description_block.${index}.description.${language}`}
              withAsterisk
              autosize
              label="Description"
              rightSection={<Text>{LanguageFlags[language]}</Text>}
              placeholder="description"
              {...form.register(`description_block.${index}.description.${language}`)}
            />
          </Stack>
        );
      })}
      {fieldsArray.fields.length < 6 && (
        <Button mt="md" leftIcon={<AddIcon />} onClick={handleAddAdvantage}>
          Add new advantage
        </Button>
      )}
    </Stack>
  );
};
