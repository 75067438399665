import { Button, Center, Image, Text, Title } from '@mantine/core';
import EmptyContent from '../../../assets/icons/emptyContent.svg';
import { ReactNode } from 'react';

type Props = {
  isEmpty: boolean;
  title: string;
  description: string;
  buttonText: string;
  children?: ReactNode;
  onClick: () => void;
};

export const EmptyListContainer = ({
  isEmpty = true,
  title,
  description,
  buttonText,
  children,
  onClick = () => {},
}: Props) => {
  if (isEmpty) {
    return (
      <Center style={{ display: 'flex', flexDirection: 'column' }}>
        <Image mb="xl" width={600} height={465} radius="md" src={EmptyContent} alt="Empty content" />

        <Title mb="md" order={2}>
          {title}
        </Title>
        <Text mb="md" color="#757780">
          {description}
        </Text>

        <Button onClick={onClick}> {buttonText}</Button>
      </Center>
    );
  }

  return <div>{children}</div>;
};
