import { instance } from 'api/baseApi';
import { Language } from 'components/Language/types';
import { fetcher } from 'helpers/fetcher';
import { pick } from 'helpers/pick';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

export type PageType = 'Page' | 'Teacher' | 'Course';
export type PageName = 'main_page' | 'courses' | 'about-us';

export interface StaticPage {
  id: number;
  type: 'Page';
  name: PageName;
}

export type Test = {
  title: string;
  description: string;
  buttonText: string;
  postButtonText: string;
  url: string;
};

export interface DedicatedPage {
  id: number;
  type: 'Teacher' | 'Course';
  name: string;
}

export type Page = StaticPage | DedicatedPage;

export type FullPage = Page & {
  reviews: number[];
  webinar: number | null;
  test_block: Record<Language, Test> | null;
};

export const usePages = () => {
  return useSWR<Page[]>('v1/admin/pages', fetcher);
};

const updatePage = (url: string, { arg }: { arg: any }) =>
  instance.put(url, arg).then((res) => res.data);

export const usePage = (pageId: number) => {
  const query = useSWR<FullPage>(`v1/admin/pages/${pageId}`, fetcher);
  const mutation = useSWRMutation(`v1/admin/pages/${pageId}`, updatePage);

  const isLoading = query.isLoading || mutation.isMutating;
  const isError = query.error || mutation.error;
  const page = query.data;

  const update = async (values: FullPage) => {
    const formToSend = pick(values, ['reviews', 'webinar', 'test_block']);

    await mutation.trigger(formToSend);
  };

  return {
    isLoading,
    isError,
    page,
    update,
  };
};
