import { Nullable } from '../../helpers/nullable';
import { RootState } from '../store';
import { IWebinar } from './webinarsTypes';

const selectIsFetching = (state: RootState): boolean => state.webinars.isFetching;
const selectWebinars = (state: RootState): Nullable<IWebinar[]> => state.webinars.webinars;
const selectWebinar = (state: RootState): Nullable<IWebinar> => state.webinars.webinar;

export const webinarsSelectors = {
  selectIsFetching,
  selectWebinar,
  selectWebinars,
};
