import { RootState } from '../store';
import { IQuestionsResponse, ITestResponse, IUnitResponse } from './types';
import { Nullable } from '../../helpers/nullable';

const selectIsFetching = (state: RootState): boolean => state.tests.isFetching;
const selectTests = (state: RootState) => state.tests.tests;
const selectTest = (state: RootState): Nullable<ITestResponse> => state.tests.test;
const selectUnits = (state: RootState): Nullable<IUnitResponse[]> => state.tests.units;
const selectUnit = (state: RootState): Nullable<IUnitResponse> => state.tests.unit;
const selectQuestions = (state: RootState): Nullable<IQuestionsResponse[]> => state.tests.questions;

export const testsSelectors = {
  selectIsFetching,
  selectTests,
  selectTest,
  selectUnits,
  selectUnit,
  selectQuestions,
};
