import { instance } from './baseApi';
import { IQuestionValues, ITestValues, IUnitResponse } from '../redux/tests/types';

export const testsApi = {
  createTest: (test: ITestValues) => instance.post('v1/admin/tests', test),
  getTests: () => instance.get('v1/admin/tests'),
  getTest: (testId: number) => instance.get(`v1/admin/tests/${testId}`),
  updateTest: (testId: number, test: ITestValues) => instance.put(`v1/admin/tests/${testId}`, test),
  deleteTest: (testId: number) => instance.delete(`v1/admin/tests/${testId}`),
  createQuestions: (questions: IQuestionValues[], testId: number) =>
    instance.post(`v1/admin/tests/${testId}/questions/add-many`, questions),
  updateQuestions: (questions: IQuestionValues[], testId: number) =>
    instance.post(`v1/admin/tests/${testId}/questions/update-many`, questions),
  getTestUnits: (testId: number) => instance.get<IUnitResponse[]>(`v1/admin/tests/${testId}/units`),
  getTestUnit: (testId: number, unitId: number) =>
    instance.get<IUnitResponse>(`v1/admin/tests/${testId}/units/${unitId}`),
  updateTestUnit: (testId: number, unitId: number, name: string) =>
    instance.put(`v1/admin/tests/${testId}/units/${unitId}`, name),
  createTestUnit: (testId: number, unit: any) => instance.post(`v1/admin/tests/${testId}/units`, unit),
  deleteTestUnit: (testId: number, unitId: number) => instance.delete(`v1/admin/tests/${testId}/units/${unitId}`),
  getTestQuestions: (testId: number) => instance.get(`v1/admin/tests/${testId}/questions`),
  bindUnit: (testId: number, questionId: number, unitId: number) =>
    instance.put(`v1/admin/tests/${testId}/questions/${questionId}`, { test_unit_id: unitId }),
};
