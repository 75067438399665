import { Box, Button, Card, Group, Paper, Stack, Text, createStyles, rem, Badge } from '@mantine/core';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import relative from 'dayjs/plugin/relativeTime';
import { EditIcon } from 'assets/icons/EditIcon';
import { IconTrash } from '@tabler/icons-react';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { stripHTML } from 'helpers/strip-html';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(pluralGetSet);
dayjs.extend(relative);

type Props = {
  name: string;
  date: string;
  time: string;
  url: string;
  withTimer: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
};

enum WebinarTimeState {
  OK = 'ok',
  IN_DAY = 'in_day',
  EXPIRED = 'expired',
}

interface CardStyleProps {
  webinarTimeState: WebinarTimeState;
}

const useStyles = createStyles((theme, { webinarTimeState }: CardStyleProps) => ({
  card: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    // padding: theme.spacing.xl,
    // paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&:hover': {
      boxShadow: theme.shadows.md,
      // transform: 'scale(1.02)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage:
        webinarTimeState === WebinarTimeState.OK
          ? theme.fn.linearGradient(0, theme.colors.green[8], theme.colors.lime[6])
          : webinarTimeState === WebinarTimeState.EXPIRED
          ? theme.fn.linearGradient(0, theme.colors.red[8], theme.colors.red[4])
          : theme.fn.linearGradient(0, theme.colors.orange[6], theme.colors.yellow[6]),
    },
  },
}));

const getTimeLeftFormatted = (date: string, time: string) => {
  // please note, that datetime from api arrives in different keys and without timezone
  const dateFromApi = date.split(' ')[0];
  const timeFromApi = time.split(' ')[1];
  const startDatetimeString = `${dateFromApi} ${timeFromApi}`;
  const startDatetime = dayjs.tz(startDatetimeString, 'Europe/Tallinn');
  const getNow = () => dayjs();
  const diff = dayjs.duration(startDatetime.diff(getNow()));
  const diffFormatted = diff.format('D [day] H [hours] m [minutes] s [seconds]');

  return diffFormatted;
};

const TimeStatus = ({ webinarTimeState }: { webinarTimeState: WebinarTimeState }) => {
  const timeText = {
    [WebinarTimeState.OK]: 'Ok',
    [WebinarTimeState.IN_DAY]: 'In a day',
    [WebinarTimeState.EXPIRED]: 'Expired',
  };

  const timeGradient = {
    [WebinarTimeState.OK]: { from: 'green', to: 'lime' },
    [WebinarTimeState.IN_DAY]: { from: 'orange', to: 'yellow' },
    [WebinarTimeState.EXPIRED]: { from: 'red', to: 'red' },
  };

  return (
    <Badge variant="gradient" gradient={timeGradient[webinarTimeState]}>
      {timeText[webinarTimeState]}
    </Badge>
  );
};

export const WebinarCard = ({ name, date, handleEdit, withTimer, handleDelete, time, url }: Props) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [webinarTimeState, setWebinarTimeState] = useState<WebinarTimeState>(WebinarTimeState.OK);
  const { classes } = useStyles({ webinarTimeState });

  useEffect(() => {
    if (date && time && withTimer) {
      const dateFromApi = date.split(' ')[0];
      const timeFromApi = time.split(' ')[1];
      const startDatetimeString = `${dateFromApi} ${timeFromApi}`;
      const startDatetime = dayjs.tz(startDatetimeString, 'Europe/Tallinn');
      const getNow = () => dayjs();
      const getCurrentRemainingTime = () => dayjs.duration(startDatetime.diff(getNow()));
      let currentState = WebinarTimeState.OK;

      const updateTime = () => {
        const diff = getCurrentRemainingTime();

        if (currentState === WebinarTimeState.EXPIRED) {
          setTimeLeft(`Expired ${diff.humanize(true)}`);
          return;
        }

        const diffFormatted = diff.format('D [day] H [hours] m [minutes] s [seconds]');
        setTimeLeft(diffFormatted);
      };

      const updateWebinarTimeState = () => {
        const remainingTime = getCurrentRemainingTime();

        if (remainingTime.milliseconds() < 0) {
          setWebinarTimeState((currentState = WebinarTimeState.EXPIRED));
        } else if (remainingTime.days() < 1) {
          setWebinarTimeState((currentState = WebinarTimeState.IN_DAY));
        } else {
          setWebinarTimeState((currentState = WebinarTimeState.OK));
        }
      };

      updateWebinarTimeState();
      updateTime();

      const interval = setInterval(() => {
        updateWebinarTimeState();
        updateTime();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [date, time]);

  return (
    <Paper className={classes.card} shadow="sm" radius="md" withBorder>
      <Box p="lg">
        <Group position="apart">
          <Stack spacing="xs" align="flex-start">
            <TimeStatus webinarTimeState={webinarTimeState} />
            <Group align="center" spacing="sm">
              <Text size={18} weight={600}>
                {stripHTML(name)}
              </Text>
            </Group>

            <Text weight={500}>{timeLeft} </Text>
          </Stack>
          <Stack spacing="xs">
            <Button component="a" href={url} target="_blank" variant="outline" size="sm" compact>
              Open URL
            </Button>
            <Button onClick={handleEdit} leftIcon={<EditIcon />} variant="outline" size="sm" compact>
              Edit
            </Button>
            <Button onClick={handleDelete} leftIcon={<DeleteIcon />} color="red" variant="outline" size="sm" compact>
              Delete
            </Button>
          </Stack>
        </Group>
      </Box>
    </Paper>
  );
};
