import { Button, Paper, Tabs, Stack, SegmentedControl } from '@mantine/core';
import { AboutCourseForm } from './AboutCourseForm';
import { FAQForm } from './FAQForm';
import { FeedbackForm } from './FeedbackForm';
import { ForWhoForm } from './ForWhoForm';
import { GeneralForm } from './GeneralForm';
import { OutcomesForm } from './OutcomesForm';
import { Alert } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageNames } from 'components/Language/types';
import { useFormContext } from 'react-hook-form';
import { MetaCourseForm } from 'components/form/MetaCourseForm';
import { ReviewsForm } from 'components/form/ReviewsForm';
import { TeachersForm } from 'components/EditCourseForm/TeachersForm';
import { WebinarForm } from 'components/form/WebinarForm';
import { ResultPointsForm } from 'components/EditCourseForm/ResultPointsForm';
import { RatesForm } from 'components/EditCourseForm/RatesForm';
import { ModulesForm } from 'components/EditCourseForm/ModulesForm';
import { TestBlockForm } from 'components/form/TestBlockForm';

function SaveAlert() {
  return (
    <Alert color="yellow" radius="md" variant="filled">
      Unsaved data
    </Alert>
  );
}

function SaveForm() {
  return (
    <Button color="green" radius="md" variant="filled" type="submit">
      Save
    </Button>
  );
}

export const EditCourseForm = () => {
  const form = useFormContext();
  const { language, updateLanguage } = useLanguage();

  return (
    <Tabs
      defaultValue="general"
      keepMounted={false}
      variant="pills"
      radius="md"
      orientation="vertical"
      mt={5}
    >
      <Tabs.List style={{ position: 'sticky', top: '80px', alignSelf: 'flex-start' }}>
        <Tabs.Tab value="general">General</Tabs.Tab>
        <Tabs.Tab value="meta">Meta</Tabs.Tab>
        <Tabs.Tab value="about">About</Tabs.Tab>
        <Tabs.Tab value="for-who">For who</Tabs.Tab>
        <Tabs.Tab value="modules">Modules</Tabs.Tab>
        <Tabs.Tab value="outcomes">Outcomes</Tabs.Tab>
        <Tabs.Tab value="results-points">Results points</Tabs.Tab>
        <Tabs.Tab value="feedback">Cases</Tabs.Tab>
        <Tabs.Tab value="rates">Rates</Tabs.Tab>
        <Tabs.Tab value="test">Test</Tabs.Tab>
        <Tabs.Tab value="webinar">Webinar</Tabs.Tab>
        <Tabs.Tab value="teachers">Teachers</Tabs.Tab>
        <Tabs.Tab value="faq">FAQ</Tabs.Tab>

        <Stack mt={10}>
          <SegmentedControl
            size="sm"
            value={language}
            onChange={(value) => updateLanguage(value as Language)}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
          />
          {form.formState.isDirty && <SaveAlert />}
          {form.formState.isDirty && <SaveForm />}
        </Stack>
      </Tabs.List>

      <Tabs.Panel value="general" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <GeneralForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="meta" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <MetaCourseForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="rates" pl="xs">
        <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
          <RatesForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="about" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <AboutCourseForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="for-who" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <ForWhoForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="modules" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <ModulesForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="feedback" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <FeedbackForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="test" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <TestBlockForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="outcomes" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <OutcomesForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="results-points" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <ResultPointsForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="webinar" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <WebinarForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="faq" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <FAQForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="teachers" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <TeachersForm />
        </Paper>
      </Tabs.Panel>
    </Tabs>
  );
};
