import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../helpers/nullable';
import { IPost, IPostResponse, ITag } from './blogTypes';
import { NavigateFunction } from 'react-router-dom';
import { SelectItem } from '@mantine/core';

interface IState {
  isFetching: boolean;
  posts: Nullable<IPostResponse[]>;
  post: Nullable<IPostResponse>;
  tags: (string | SelectItem)[];
}

const initialState: IState = {
  isFetching: false,
  posts: null,
  post: null,
  tags: [],
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState: initialState,
  reducers: {
    setIsFetching(state, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    createPost(state, { payload }: PayloadAction<{ post: IPost; navigate: NavigateFunction }>) {},
    deletePost(state, { payload }: PayloadAction<number>) {},
    getPost(state, { payload }: PayloadAction<number>) {},
    getPosts(
      state,
      { payload }: PayloadAction<{ page?: number; title?: string; date_from?: string; date_to?: string }>,
    ) {},
    setPost(state, { payload }: PayloadAction<IPostResponse>) {
      state.post = payload;
    },
    setPosts(state, { payload }: PayloadAction<IPostResponse[]>) {
      state.posts = payload;
    },
    updatePost(state, { payload }: PayloadAction<{ id: number; post: IPost; navigate: NavigateFunction }>) {},
    getTags() {},
    createTag(state, { payload }: PayloadAction<ITag>) {},
    deleteTag(state, { payload }: PayloadAction<number>) {},
    setTags(state, { payload }: PayloadAction<any>) {
      state.tags = payload;
    },
  },
});

export const blogReducer = blogSlice.reducer;
export const blogActions = blogSlice.actions;
