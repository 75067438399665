import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';
import { authReducer } from './auth/authSlice';
import { stepperReducer } from './stepper/stepperSlice';
import { teachersReducer } from './teachers/teachersSlice';
import { coursesReducer } from './courses/coursesSlice';
import { settingsReducer } from './settings/settingsSlice';
import { webinarsReducer } from './webinars/webinarsSlice';
import { paginationReducer } from './pagination/paginationSlice';
import { blogReducer } from './blog/blogSlice';
import { testsReducer } from './tests/testsSlice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    stepper: stepperReducer,
    auth: authReducer,
    teachers: teachersReducer,
    courses: coursesReducer,
    settings: settingsReducer,
    webinars: webinarsReducer,
    pagination: paginationReducer,
    blog: blogReducer,
    tests: testsReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
