import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <MantineProvider>
      <Provider store={store}>
        <Notifications position="top-center" zIndex={2077} />
        <App />
      </Provider>
    </MantineProvider>
  </BrowserRouter>,
);

reportWebVitals();
