import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Group, LoadingOverlay, Select, Stack, Text } from '@mantine/core';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';

import { testsActions } from '../../../../redux/tests/testsSlice';
import { testsSelectors } from '../../../../redux/tests/testsSelectors';
import { UnitModal } from '../../../../components';
import { APP_ROUTES } from '../../../../constants';

export const TestUnits = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { testId } = useParams<{ testId: string }>();

  const units = useSelector(testsSelectors.selectUnits);
  const isFetching = useSelector(testsSelectors.selectIsFetching);
  const questions = useSelector(testsSelectors.selectQuestions);

  const handleDelete = useCallback(
    (unitId: number, name: string) => {
      if (testId) {
        openConfirmModal({
          title: `Delete unit: ${name}`,
          centered: true,
          children: <Text size="sm">Are you sure you want to delete this unit?</Text>,
          labels: { confirm: 'Delete unit', cancel: "No don't delete it" },
          confirmProps: { color: 'red' },
          onConfirm: () => dispatch(testsActions.deleteTestUnit({ testId: +testId, unitId })),
        });
      }
    },
    [dispatch, testId],
  );

  const handleRename = (unitId: number) => {
    const handleSave = (name: string) => {
      if (testId) {
        dispatch(testsActions.updateTestUnit({ testId: +testId, unitId, name }));
        closeModal('');
      }
    };

    if (testId) {
      openModal({
        title: 'Edit unit',
        children: <UnitModal onSubmit={handleSave} isEditMode unitId={unitId} testId={testId} />,
        centered: true,
        size: 'auto',
      });
    }
  };

  const handleCreate = () => {
    const handleSave = (name: any) => {
      if (testId) {
        dispatch(testsActions.createTestUnit({ testId: +testId, name, navigate }));
        closeModal('');
      }
    };

    openModal({
      title: 'Create unit',
      children: <UnitModal onSubmit={handleSave} />,
      centered: true,
      size: 'auto',
    });
  };

  const onSubmit = () => {
    navigate(APP_ROUTES.TESTS);
  };

  const onSelectChange = (unitId: string | null, questionId: number) => {
    if (testId && unitId) {
      dispatch(testsActions.bindUnit({ unitId: +unitId, testId: +testId, questionId }));
    }
  };

  useEffect(() => {
    if (testId) {
      dispatch(testsActions.getTestUnits(+testId));
      dispatch(testsActions.getTestQuestions(+testId));
    }
  }, [dispatch, testId]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!units) {
    return null;
  }

  return (
    <Container>
      <Text color="#0F68FC" size="xl" mt="xl">
        Blocks with questions
      </Text>
      <Text size="xs">At this stage, you need to divide the questions into blocks.</Text>
      {units.map(({ name, id, questions }) => (
        <Container key={id} mt="md" style={{ border: '1px solid #EBEFFF', borderRadius: '8px', padding: '24px 32px' }}>
          <Group position="apart">
            <Stack>
              <Text>{name}</Text>
              <Text>{questions.length} questions</Text>
            </Stack>
            <Group>
              <Button onClick={() => handleRename(id)}>Rename</Button>
              {units.length > 1 && (
                <Button variant="outline" color="red" onClick={() => handleDelete(id, name)}>
                  Delete
                </Button>
              )}
            </Group>
          </Group>
        </Container>
      ))}
      <Button onClick={handleCreate} mt="xl" variant="outline">
        Create new unit
      </Button>

      <Text color="#0F68FC" size="xl" mt="xl">
        Adding questions to a block
      </Text>
      <Grid mt="xl">
        {questions?.map(({ name, id, test_unit_id }, index) => (
          <Grid.Col key={id} span={6}>
            <Text color="#0F68FC">{`${index + 1}. ${name}`}</Text>
            <Select
              data={units.map((unit) => ({ value: `${unit.id}`, label: unit.name }))}
              defaultValue={test_unit_id ? String(test_unit_id) : ''}
              label="Units"
              placeholder="Select unit"
              onChange={(value) => onSelectChange(value, id)}
            />
          </Grid.Col>
        ))}
      </Grid>
      <Group mt="xl" position="right">
        <Button onClick={onSubmit}>Save</Button>
      </Group>
    </Container>
  );
};
