import { ReactNode } from 'react';
import { Button, Text, Textarea, TextInput } from '@mantine/core';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { randomId } from '@mantine/hooks';

type Props = {
  form: any;
  language: string;
  points: ReactNode;
};

export const CreateFeedbackForm = ({ form, language, points }: Props) => {
  return (
    <div>
      <TextInput
        styles={{ required: { display: 'none' } }}
        required
        mb="xs"
        label="Name"
        placeholder="Enter name"
        {...form.getInputProps(`name.${language}`)}
      />

      <TextInput
        styles={{ required: { display: 'none' } }}
        required
        mb="xs"
        label="Work"
        placeholder="Enter work"
        {...form.getInputProps(`from.${language}`)}
      />
      <TextInput
        error
        styles={{ required: { display: 'none' } }}
        required
        label="LinkedIn"
        placeholder="Insert LinkedIn link"
        {...form.getInputProps('linkedin_url')}
      />

      <Text mt="md" mb="md" color="#0F68FC" size="xl">
        Points
      </Text>

      <div>{points}</div>

      <Button
        mt="md"
        leftIcon={<AddIcon />}
        onClick={() =>
          form.insertListItem('points', {
            key: randomId(),
            title: {
              en: '',
              rus: '',
              est: '',
            },
            description: {
              en: '',
              rus: '',
              est: '',
            },
          })
        }
      >
        Add point
      </Button>

      <Text mt="md" mb="md" color="#0F68FC" size="xl">
        Review
      </Text>

      <Textarea
        styles={{ required: { display: 'none' } }}
        required
        autosize
        mb="xs"
        label="Review"
        placeholder="Enter review"
        {...form.getInputProps(`review.${language}`)}
      />
    </div>
  );
};
