import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Group, Input, LoadingOverlay, Pagination, Text, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDebouncedState, useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';

import { blogActions } from '../../redux/blog/blogSlice';
import { blogSelectors } from '../../redux/blog/blogSelectors';
import { paginationSelectors } from '../../redux/pagination/paginationSelectors';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { APP_ROUTES } from '../../constants';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { EmptyListContainer } from '../../components/containers';
import { BlogCard } from '../../components';
import dayjs from 'dayjs';
import { Nullable } from '../../helpers/nullable';

export const Posts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchTextDebounced] = useDebouncedValue(searchText, 500);

  const [startDate, setStartDate] = useDebouncedState<Nullable<Date>>(null, 500);
  const [endDate, setEndDate] = useDebouncedState<Nullable<Date>>(null, 500);

  const posts = useSelector(blogSelectors.selectPosts);
  const isFetching = useSelector(blogSelectors.selectIsFetching);
  const paginationData = useSelector(paginationSelectors.selectPaginationData);

  const pageNumbers = Math.ceil(paginationData.total / paginationData.per_page);
  const isEmpty = !posts;

  const handleCreate = () => {
    navigate(`${APP_ROUTES.POSTS}/new`);
  };

  const onPageChange = (page: number) => {
    dispatch(blogActions.getPosts({ page }));
  };

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`${APP_ROUTES.POSTS}/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback((id: number) => {
    openConfirmModal({
      title: 'Delete post',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete post?</Text>,
      labels: { confirm: 'Delete post', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(blogActions.deletePost(id)),
    });
  }, []);

  useEffect(() => {
    dispatch(
      blogActions.getPosts({
        title: searchTextDebounced,
        date_from: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
        date_to: endDate ? dayjs(endDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
      }),
    );
  }, [searchTextDebounced, startDate, endDate]);

  useEffect(() => {
    dispatch(blogActions.getPosts({ page: paginationData.current_page }));
  }, []);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!posts) {
    return null;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        title="Your blog list is empty."
        description="Create a blog and enter the details."
        buttonText="Add a post"
        onClick={handleCreate}
      >
        <Group position="apart">
          <Title order={1}>Blog list</Title>
          <Button onClick={handleCreate}>New post</Button>
        </Group>
        <Group mt="md">
          <Input.Wrapper label="Search">
            <Input
              style={{ maxWidth: '300px' }}
              icon={<SearchIcon />}
              placeholder="Search"
              value={searchText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchText(event.currentTarget.value)}
            />
          </Input.Wrapper>
          <DatePickerInput
            label="Start date"
            placeholder="Pick start date"
            clearable={false}
            value={startDate}
            onChange={setStartDate}
          />
          <DatePickerInput
            label="End date"
            placeholder="Pick end date"
            clearable={false}
            value={endDate}
            onChange={setEndDate}
          />
        </Group>
        <Grid mt="xl">
          {posts.map(({ id, title, description, created_at, main_photo }) => (
            <Grid.Col key={id} span={6}>
              <BlogCard
                description={description.en}
                image={main_photo}
                title={title.en}
                createDate={created_at}
                handleEdit={() => handleEdit(id)}
                handleDelete={() => handleDelete(id)}
              />
            </Grid.Col>
          ))}
        </Grid>

        {pageNumbers > 1 && (
          <Group position="center">
            <Pagination
              mt="xl"
              mb="md"
              total={pageNumbers}
              value={paginationData.current_page}
              onChange={onPageChange}
              size="md"
              radius="md"
              withControls={false}
            />
          </Group>
        )}
      </EmptyListContainer>
    </Container>
  );
};
