import { Navbar, NavLink } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

import { APP_ROUTES } from '../../constants';

export const NavBar = () => {
  const location = useLocation();

  return (
    <Navbar width={{ base: 300 }} height={500} p="xs">
      <NavLink label="Sections" childrenOffset={28} defaultOpened>
        <NavLink
          label="Pages"
          active={location.pathname.includes(APP_ROUTES.PAGES)}
          component={Link}
          to={APP_ROUTES.PAGES}
        />
        <NavLink
          label="Webinars"
          active={location.pathname === APP_ROUTES.WEBINARS}
          component={Link}
          to={APP_ROUTES.WEBINARS}
        />
        <NavLink
          label="Tests"
          active={location.pathname === APP_ROUTES.TESTS}
          component={Link}
          to={APP_ROUTES.TESTS}
        />
        <NavLink
          active={
            location.pathname === APP_ROUTES.CREATE_RATE ||
            location.pathname === APP_ROUTES.RATES ||
            location.pathname === APP_ROUTES.COURSES ||
            location.pathname === APP_ROUTES.CREATE_COURSE ||
            location.pathname === APP_ROUTES.FEEDBACKS ||
            location.pathname === APP_ROUTES.CREATE_FEEDBACK
          }
          label="Courses"
          childrenOffset={28}
        >
          <NavLink
            component={Link}
            label="Courses list"
            to={APP_ROUTES.COURSES}
            active={
              location.pathname === APP_ROUTES.COURSES ||
              location.pathname === APP_ROUTES.CREATE_COURSE
            }
          />
          <NavLink
            component={Link}
            label="Rates"
            to={APP_ROUTES.RATES}
            active={
              location.pathname === APP_ROUTES.RATES || location.pathname === APP_ROUTES.CREATE_RATE
            }
          />
          <NavLink
            component={Link}
            label="Cases"
            to={APP_ROUTES.FEEDBACKS}
            active={
              location.pathname === APP_ROUTES.FEEDBACKS ||
              location.pathname === APP_ROUTES.CREATE_FEEDBACK
            }
          />
          <NavLink
            component={Link}
            label="Courses page reviews"
            to={APP_ROUTES.COURSES_REVIEW}
            active={
              location.pathname === APP_ROUTES.COURSES_REVIEW ||
              location.pathname === APP_ROUTES.CREATE_COURSES_REVIEW
            }
          />
        </NavLink>
        <NavLink
          label="Teachers"
          component={Link}
          to={APP_ROUTES.TEACHERS}
          active={
            location.pathname === APP_ROUTES.TEACHERS ||
            location.pathname === APP_ROUTES.CREATE_TEACHER
          }
        />
        <NavLink childrenOffset={28} label="Blog">
          <NavLink
            label="Posts"
            component={Link}
            to={APP_ROUTES.POSTS}
            active={
              location.pathname === APP_ROUTES.POSTS || location.pathname === APP_ROUTES.NEW_POST
            }
          />
          <NavLink
            label="Tags"
            component={Link}
            to={APP_ROUTES.TAGS}
            active={location.pathname === APP_ROUTES.TAGS}
          />
        </NavLink>
      </NavLink>
      <NavLink label="Settings" childrenOffset={28}>
        <NavLink
          label="Privacy policy"
          component={Link}
          to={APP_ROUTES.PRIVACY_POLICY}
          active={location.pathname === APP_ROUTES.PRIVACY_POLICY}
        />
        <NavLink
          label="Terms & conditions"
          component={Link}
          to={APP_ROUTES.TERMS_CONDITIONS}
          active={location.pathname === APP_ROUTES.TERMS_CONDITIONS}
        />
        <NavLink
          label="Training QA"
          component={Link}
          to={APP_ROUTES.TRAINING_QA}
          active={location.pathname === APP_ROUTES.TRAINING_QA}
        />
        <NavLink
          label="Basics of study organization"
          component={Link}
          to={APP_ROUTES.BASIC_OF_STUDY_ORGANISATION}
          active={location.pathname === APP_ROUTES.BASIC_OF_STUDY_ORGANISATION}
        />
      </NavLink>
    </Navbar>
  );
};
