import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { coursesActions } from '../../../redux/courses/coursesSlice';
import {
  ActionIcon,
  Button,
  Container,
  FileInput,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  Stack,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useSelector } from 'react-redux';
import { coursesSelectors } from '../../../redux/courses/coursesSelectors';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { Nullable } from '../../../helpers/nullable';
import { encodeFileInBase } from '../../../helpers/helpers';
import { CreateFeedbackForm } from './CreateFeedbackForm/CreateFeedbackForm';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { EditableFeedback, IFeedback } from '../../../redux/courses/coursesTypes';

type Props = {
  isEditMode?: boolean;
};

export const CreateFeedback = ({ isEditMode = false }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const [currentLanguageTab, setCurrentLanguageTab] = useState<Nullable<string>>('en');
  const [fileInBase64, setFileInBase64] = useState<any>('');
  const [selectedCourses, setSelectedCourses] = useState<string[]>(['']);

  const courses = useSelector(coursesSelectors.selectShortCourses);
  const feedbackData = useSelector(coursesSelectors.selectFeedback);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);


  const pageTitle = isEditMode ? `Edit case ${feedbackData?.name.en}` : `New case`;

  const form = useForm<EditableFeedback>({
    initialValues: {
      name: {
        en: '',
        rus: '',
        est: '',
      },
      linkedin_url: '',
      from: {
        en: '',
        rus: '',
        est: '',
      },
      review: {
        en: '',
        rus: '',
        est: '',
      },
      points: [
        {
          key: randomId(),
          title: {
            en: '',
            rus: '',
            est: '',
          },
          description: {
            en: '',
            rus: '',
            est: '',
          },
        },
      ],
      courses: [],
    },
  });

  const points = form.values.points.map((item, index) => (
    <Group position={'apart'} key={item.key} mt="xs">
      <Stack style={{ width: '100%' }}>
        <Group position="apart">
          <TextInput
            style={{ width: '95%' }}
            label="Point title"
            placeholder="Enter text"
            description="58 symbols maximum"
            inputWrapperOrder={['label', 'error', 'input', 'description']}
            {...form.getInputProps(`points.${index}.title.${currentLanguageTab}`)}
          />
          <ActionIcon color="red" onClick={() => form.removeListItem('points', index)}>
            <DeleteIcon />
          </ActionIcon>
        </Group>

        <Textarea
          label="Point description"
          autosize
          placeholder="Enter text"
          description="58 symbols maximum"
          inputWrapperOrder={['label', 'error', 'input', 'description']}
          {...form.getInputProps(`points.${index}.description.${currentLanguageTab}`)}
        />
      </Stack>
    </Group>
  ));

  const onSubmit = (values: typeof form.values) => {
    const feedback = { ...values, image: fileInBase64 };

    if (isEditMode && id) {
      dispatch(coursesActions.updateFeedback({ id, feedback, navigate }));
    } else {
      dispatch(coursesActions.createFeedback({ feedback, navigate }));
    }
  };

  const handleError = () => {};

  const onFileChange = async (file: File) => {
    const inBase64 = await encodeFileInBase(file);
    setFileInBase64(inBase64);
  };

  useEffect(() => {
    if (id) {
      dispatch(coursesActions.getFeedback(id));
    }
    dispatch(coursesActions.getCourses());
  }, []);

  useEffect(() => {
    if (isEditMode && feedbackData) {
      const currentData = {
        name: feedbackData.name,
        linkedin_url: feedbackData.linkedin_url,
        from: feedbackData.from,
        review: feedbackData.review,
        points: feedbackData.points,
        courses: feedbackData.courses.map((course) => course.id),
      };

      form.setValues(currentData);
    }
  }, [isEditMode, isFetching, feedbackData]);

  const handleCoursesInput = (values: string[]) => {
    const coursesMap = values.map(Number);

    form.setFieldValue('courses', coursesMap);
  };

  if (!courses) {
    return null;
  }

  return (
    <Container>
      <LoadingOverlay visible={isFetching} overlayBlur={2} />;
      <Title mb="xl" order={1}>
        { pageTitle }
      </Title>
      <form onSubmit={form.onSubmit(onSubmit, handleError)}>
        <Tabs onTabChange={(value) => setCurrentLanguageTab(value)} defaultValue="en">
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="en">Eng</Tabs.Tab>
            <Tabs.Tab value="rus">Rus</Tabs.Tab>
            <Tabs.Tab value="est">Est</Tabs.Tab>
          </Tabs.List>
          <MultiSelect
            styles={{ required: { display: 'none' } }}
            required
            mb="xl"
            label="Select course"
            placeholder="Select course"
            value={form.values.courses.map((course) => course.toString())}
            onChange={handleCoursesInput}
            data={
                courses?.map(({ id, name, start_at, format }) => ({
                  value: `${id}`,
                  label: `${name} | ${format} | ${new Date(start_at).toLocaleString('default', { month: 'long' })}`,
                })) ?? []}
          />
          <Tabs.Panel value="en" pt="xs">
            <Text mb="md" color="#0F68FC" size="xl">
              User info
            </Text>

            <FileInput
              styles={{ required: { display: 'none' } }}
              required
              value={fileInBase64.name}
              placeholder="Pick photo"
              label="Upload user photo"
              onChange={(file: File) => onFileChange(file)}
              inputWrapperOrder={['label', 'error', 'input']}
              accept="image/png,image/jpeg"
            />

            <CreateFeedbackForm form={form} points={points} language="en" />
          </Tabs.Panel>

          <Tabs.Panel value="rus" pt="xs">
            <Text mb="md" color="#0F68FC" size="xl">
              User info
            </Text>
            <CreateFeedbackForm form={form} points={points} language="rus" />
          </Tabs.Panel>

          <Tabs.Panel value="est" pt="xs">
            <Text mb="md" color="#0F68FC" size="xl">
              User info
            </Text>
            <CreateFeedbackForm form={form} points={points} language="est" />
          </Tabs.Panel>
        </Tabs>

        <Group position="right">
          <Button mt="md" loading={false} disabled={false} type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};
