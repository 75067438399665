import { useLanguage } from 'components/Language/LanguageContext';
import { RTE } from 'components/RTE';
import { Controller, useForm, useFormContext } from 'react-hook-form';

export const ForWhoForm = () => {
  const form = useFormContext();
  const { language } = useLanguage();

  return (
    <Controller
      key={`for_whom_block.${language}`}
      name={`for_whom_block.${language}`}
      control={form.control}
      render={({ field }) => <RTE {...field} />}
    />
  );
};
