import { Input, Stack } from '@mantine/core';
import { RTE } from 'components/RTE';
import { useLanguage } from 'components/Language/LanguageContext';
import { Controller, useFormContext } from 'react-hook-form';
import { EditableTeacher } from 'redux/teachers/teachersTypes';

export const DescriptionsTeachersForm = () => {
  const form = useFormContext<EditableTeacher>();
  const { language } = useLanguage();

  return (
    <Stack>
      <Stack spacing={1}>
        <Input.Label>Description</Input.Label>
        <Controller
          name={`description.${language}`}
          key={`description.${language}`}
          control={form.control}
          render={({ field }) => <RTE {...field} />}
        />
      </Stack>
      <Stack spacing={1}>
        <Input.Label>Short description</Input.Label>
        <Controller
          name={`short_description.${language}`}
          key={`short_description.${language}`}
          control={form.control}
          render={({ field }) => <RTE {...field} />}
        />
      </Stack>
    </Stack>
  );
};
