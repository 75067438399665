import { Box, Button, Group, LoadingOverlay, PasswordInput, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { authActions } from '../../redux/auth/authSlice';
import { ILoginPayload } from '../../redux/auth/authTypes';
import { authSelectors } from '../../redux/auth/authSelectors';

export const LoginForm = () => {
  const dispatch = useAppDispatch();
  const isFetching = useSelector(authSelectors.selectIsFetching);
  const form = useForm({
    initialValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = ({ login, password }: ILoginPayload) => {
    dispatch(authActions.login({ login, password }));
  };

  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      <LoadingOverlay visible={isFetching} overlayBlur={2} />
      <Title mb="md" order={1}>
        Login Account
      </Title>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TextInput
          required
          styles={{ required: { display: 'none' } }}
          mb="md"
          label="Login"
          placeholder="Login"
          {...form.getInputProps('login')}
        />
        <PasswordInput
          required
          styles={{ required: { display: 'none' } }}
          placeholder="Password"
          label="Password"
          {...form.getInputProps('password')}
        />
        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
};
