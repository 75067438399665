import { all, fork } from 'redux-saga/effects';
import { authSagas } from './auth/authSagas';
import { teachersSagas } from './teachers/teachersSagas';
import { coursesSagas } from './courses/coursesSagas';
import { settingsSagas } from './settings/settingsSagas';
import { webinarsSagas } from './webinars/webinarsSagas';
import { blogSagas } from './blog/blogSagas';
import { testsSagas } from './tests/testsSagas';

export function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(teachersSagas),
    fork(coursesSagas),
    fork(settingsSagas),
    fork(webinarsSagas),
    fork(blogSagas),
    fork(testsSagas),
  ]);
}
