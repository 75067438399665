import { RootState } from '../store';
import { ISetting } from './settingsTypes';
import { Nullable } from '../../helpers/nullable';

const selectIsFetching = (state: RootState): boolean => state.settings.isFetching;
const selectSettings = (state: RootState): Nullable<ISetting> => state.settings.settings;

export const settingsSelectors = {
  selectIsFetching,
  selectSettings,
};
