import { Button, MultiSelect, NumberInput, Text, Textarea, TextInput } from '@mantine/core';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { randomId } from '@mantine/hooks';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { initialLanguages } from '../../../../constants';

type Props = {
  form: any;
  points: ReactNode;
  languages: string[];
  changeLanguages: Dispatch<SetStateAction<string[]>>;
  language: string;
};

export const CreateRateForm = ({ form, points, language, languages, changeLanguages }: Props) => {
  return (
    <div>
      <Text mb="md" color="#0F68FC" size="xl">
        Choose a convenient learning format
      </Text>

      <TextInput
        styles={{ required: { display: 'none' } }}
        label="Name"
        placeholder="Enter name"
        error
        required
        {...form.getInputProps(`name.${language}`)}
      />
      <Textarea
        styles={{ required: { display: 'none' } }}
        label="Short description"
        placeholder="Enter short description"
        description="58 symbols maximum"
        inputWrapperOrder={['label', 'error', 'input', 'description']}
        mt="xs"
        error
        required
        {...form.getInputProps(`short_description.${language}`)}
      />

      <Text mt="md" mb="md" color="#0F68FC" size="xl">
        Point name + icon info (8 max)
      </Text>
      <div>{points}</div>
      <Button
        mt="md"
        leftIcon={<AddIcon />}
        onClick={() =>
          form.insertListItem('description', {
            key: randomId(),
            point: {
              en: '',
              rus: '',
              est: '',
            },
          })
        }
      >
        Add point
      </Button>

      <Text mt="md" mb="md" color="#0F68FC" size="xl">
        Price + Description
      </Text>

      <NumberInput styles={{ required: { display: 'none' } }} label="Old Price" {...form.getInputProps(`old_price`)} />
      <NumberInput
        styles={{ required: { display: 'none' } }}
        required
        label="New Price"
        {...form.getInputProps(`price`)}
      />
      <TextInput
        styles={{ required: { display: 'none' } }}
        label="Description"
        placeholder="Enter description"
        mt="xs"
        error
        required
        {...form.getInputProps(`description_under_price.${language}`)}
      />
      <Text mt="md" mb="md" color="#0F68FC" size="xl">
        Languages
      </Text>

      <MultiSelect
        styles={{ required: { display: 'none' } }}
        required
        value={languages}
        data={initialLanguages}
        onChange={changeLanguages}
        label="Languages"
        placeholder="Pick language"
        {...form.getInputProps(`languages`)}
      />
    </div>
  );
};
