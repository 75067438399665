import { Button, Card, Group, Stack, Text } from '@mantine/core';
import { memo } from 'react';

type Props = {
  name: string;
  price: number;
  handleChange: () => void;
  handleDelete: () => void;
  linkedCourses?: { id: number; name: string }[];
};
export const RateCard = memo(({ name, price, handleDelete, handleChange, linkedCourses }: Props) => {
  return (
    <Card radius="md" style={{ border: '1px solid #EBEFFF' }} mt="xl" shadow="sm" p="xl">
      <Group position="apart">
        <Stack>
          <Text weight={500}>{name}</Text>
          <Text>Price: {price}</Text>
        </Stack>

        {linkedCourses && linkedCourses?.length > 0 && (
          <Group>
            <Text weight="bold">Tied courses:</Text>
            <Stack>
              {linkedCourses?.map(({ name, id }) => (
                <Text size="xs" key={id}>
                  {name}
                </Text>
              ))}
            </Stack>
          </Group>
        )}

        <Stack>
          <Button onClick={handleChange} variant="subtle">
            Change details
          </Button>
          <Button color="red" onClick={handleDelete} variant="subtle">
            Delete rate
          </Button>
        </Stack>
      </Group>
    </Card>
  );
});
