import { Language } from 'components/Language/types';
import { ILang } from './commonTypes';

export function getTranslations(
  translations: ILang | [],
  defaultLanguages: Array<keyof ILang> = ['en', 'est', 'rus'],
): ILang {
  if (translations === undefined) return { en: '', est: '', rus: '' };

  if (Array.isArray(translations)) {
    return Object.fromEntries(defaultLanguages.map((langKey) => [langKey, ''])) as any as ILang;
  } else {
    return Object.fromEntries(
      defaultLanguages.map((key) => {
        if (key in translations) {
          return [key, translations[key]];
        } else {
          return [key, ''];
        }
      }),
    ) as any as ILang;
  }
}

const defaultLanguages: Array<Language> = [Language.ENG, Language.EST, Language.RUS];

export function getTranslationsWithEmptyValue<T>(
  translations: Record<Language, T> | null,
  getEmptyTranslation: () => T,
): Record<Language, T> {
  if (!translations)
    return { en: getEmptyTranslation(), est: getEmptyTranslation(), rus: getEmptyTranslation() };

  if (Array.isArray(translations)) {
    return Object.fromEntries(
      defaultLanguages.map((langKey) => [langKey, getEmptyTranslation()]),
    ) as any as Record<Language, T>;
  } else {
    return Object.fromEntries(
      defaultLanguages.map((key) => {
        if (key in translations) {
          return [key, translations[key]];
        } else {
          return [key, getEmptyTranslation()];
        }
      }),
    ) as any as Record<Language, T>;
  }
}
