import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from './paginationTypes';

interface IState {
  paginationData: IPagination;
}

const initialState: IState = {
  paginationData: {
    current_page: 1,
    per_page: 0,
    total: 0,
  },
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: initialState,
  reducers: {
    setPaginationData(state, { payload }: PayloadAction<any>) {
      state.paginationData = payload;
    },
  },
});

export const paginationReducer = paginationSlice.reducer;
export const paginationActions = paginationSlice.actions;
