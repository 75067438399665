import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginPayload } from './authTypes';

interface IAuth {
  token: null | string;
  isFetching: boolean;
}

const initialState: IAuth = {
  token: null,
  isFetching: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state: IAuth, { payload }: PayloadAction<string | null>) {
      state.token = payload;
    },
    login(state: IAuth, { payload }: PayloadAction<ILoginPayload>) {},
    setIsFetching(state: IAuth, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;