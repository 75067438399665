import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuestionsResponse, IQuestionValues, ITestResponse, ITestValues, IUnitResponse } from './types';
import { NavigateFunction } from 'react-router-dom';
import { Nullable } from '../../helpers/nullable';

interface IState {
  isFetching: boolean;
  tests: Nullable<ITestResponse[]>;
  test: Nullable<ITestResponse>;
  units: Nullable<IUnitResponse[]>;
  unit: Nullable<IUnitResponse>;
  questions: Nullable<IQuestionsResponse[]>;
}

const initialState: IState = {
  isFetching: false,
  tests: null,
  test: null,
  units: null,
  unit: null,
  questions: null,
};

export const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    setIsFetching(state: IState, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    createTest(state, { payload }: PayloadAction<{ test: ITestValues; navigate: NavigateFunction }>) {},
    getTests() {},
    setTests(state, { payload }: PayloadAction<ITestResponse[]>) {
      state.tests = payload;
    },
    getTest(state, { payload }: PayloadAction<number>) {},
    setTest(state, { payload }: PayloadAction<ITestResponse>) {
      state.test = payload;
    },
    updateTest(state, { payload }: PayloadAction<{ test: ITestValues; testId: number; navigate: NavigateFunction }>) {},
    deleteTest(state, { payload }: PayloadAction<number>) {},
    createQuestions(
      state,
      { payload }: PayloadAction<{ questions: IQuestionValues[]; testId: number; navigate: NavigateFunction }>,
    ) {},
    updateQuestions(
      state,
      { payload }: PayloadAction<{ questions: IQuestionValues[]; testId: number; navigate: NavigateFunction }>,
    ) {},
    getTestUnits(state, { payload }: PayloadAction<number>) {},
    getTestUnit(state, { payload }: PayloadAction<{ testId: number; unitId: number }>) {},
    setTestUnit(state, { payload }: PayloadAction<Nullable<IUnitResponse>>) {
      state.unit = payload;
    },
    setTestUnits(state, { payload }: PayloadAction<IUnitResponse[]>) {
      state.units = payload;
    },
    createTestUnit(state, { payload }: PayloadAction<{ testId: number; name: string; navigate: NavigateFunction }>) {},
    deleteTestUnit(state, { payload }: PayloadAction<{ testId: number; unitId: number }>) {},
    updateTestUnit(state, { payload }: PayloadAction<{ testId: number; unitId: number; name: string }>) {},
    getTestQuestions(state, { payload }: PayloadAction<number>) {},
    setTestQuestions(state, { payload }: PayloadAction<IQuestionsResponse[]>) {
      state.questions = payload;
    },
    bindUnit(state, { payload }: PayloadAction<{ testId: number; unitId: number; questionId: number }>) {},
  },
});

export const testsActions = testsSlice.actions;
export const testsReducer = testsSlice.reducer;
