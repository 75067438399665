import { Select, Stack, Text, createStyles, rem, Card, Button, Group } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { coursesSelectors } from 'redux/courses/coursesSelectors';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical, IconTrash } from '@tabler/icons-react';
import { ImageField } from 'components/common/ImageFiels';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { EditableCourse } from 'redux/courses/coursesTypes';

const useStyles = createStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
    justifyContent: 'space-between',
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: rem(30),
    fontWeight: 700,
    width: rem(60),
  },

  text: {
    marginRight: 'auto',
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },
}));

interface DndListHandleProps {
  data: number[];
}

interface DraggableProps {
  item: number;
  index: number;
  handleDelete: (idx: number) => void;
}

export function DraggableItem({ item, index, handleDelete }: DraggableProps) {
  const { classes, cx } = useStyles();
  const { language } = useLanguage();
  const ratesAll = useSelector(coursesSelectors.selectRates);
  const rate = ratesAll?.find((rate) => rate.id === item) || null;

  if (!rate) {
    return (
      <Card shadow="sm" p="lg" radius="md" withBorder>
        Review {item} not found
      </Card>
    );
  }

  return (
    <Draggable key={item} index={index} draggableId={`${item}`}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical size="1.05rem" stroke={1.5} />
          </div>
          {/* <Text className={classes.symbol}>{item}</Text> */}
          <Stack spacing={0} className={classes.text}>
            <Text>{rate.name[language]}</Text>
            <Text color="dimmed" size="sm">
              ${rate.price} • Desc: {rate.short_description[language]}
            </Text>
          </Stack>
          <Group position="right" align="right">
            <Button size="xs" variant="outline" color="red" onClick={() => handleDelete(index)}>
              <IconTrash stroke={1} />
            </Button>
          </Group>
        </div>
      )}
    </Draggable>
  );
}

export function RatesForm() {
  const form = useFormContext<EditableCourse>();

  const watchLearningFormats = form.watch('learning_formats');

  const learingFormatsInForm = useMemo(
    () => form.getValues('learning_formats'),
    [watchLearningFormats],
  );

  const ratesAll = useSelector(coursesSelectors.selectRates);
  const { language } = useLanguage();
  const [state, handlers] = useListState<number>(watchLearningFormats);

  const items = state.map((item, index) => (
    <DraggableItem
      key={item}
      handleDelete={(idx) => handlers.remove(idx)}
      item={item}
      index={index}
    />
  ));

  useEffect(() => {
    form.setValue('learning_formats', state, { shouldDirty: true });
  }, [state]);

  const availableReviews = useMemo(
    () =>
      ratesAll?.filter(
        (rate) =>
          learingFormatsInForm?.findIndex((rateInCourse) => rate.id === rateInCourse) === -1,
      ) ?? [],
    [ratesAll, learingFormatsInForm],
  );

  const onUpdateImage = useCallback(
    (newImage: string) => {
      form.setValue(`learning_format_description.${language}`, newImage, { shouldDirty: true });
    },
    [language],
  );

  const ratesFormatted = useMemo(
    () =>
      availableReviews.map((rate) => ({
        label: `${rate.name[language]} - ${rate.price}`,
        value: String(rate.id),
      })) || [],
    [availableReviews, language],
  );

  const handleAdd = (id: string) => {
    handlers.append(Number(id));
  };

  return (
    <Stack>
      {availableReviews.length > 0 && (
        <Select
          clearable={false}
          label={<Text>Add rate</Text>}
          data={ratesFormatted}
          limit={20}
          value={null}
          searchable
          onChange={handleAdd}
        />
      )}

      <DragDropContext
        onDragEnd={({ destination, source }) =>
          handlers.reorder({ from: source.index, to: destination?.index || 0 })
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Controller
        key={`learning_format_description.${language}`}
        name={`learning_format_description.${language}`}
        control={form.control}
        render={({ field }) => (
          <ImageField
            width="100%"
            height="auto"
            onChange={onUpdateImage}
            currentValue={field.value}
            withCrop={false}
          />
        )}
      />
    </Stack>
  );
}
