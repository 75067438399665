import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppShell, Button, Group, Header, Image, Switch, useMantineColorScheme } from '@mantine/core';
import { NavBar } from '../..';
import itTalentLogo from '../../../assets/icons/itTalengLogo.svg';
import { authActions } from '../../../redux/auth/authSlice';

type Props = {
  children?: ReactNode;
  isAuthorized: boolean;
};

export const MainContainer = ({ children, isAuthorized = false }: Props) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    dispatch(authActions.setToken(null));
    navigate('/');
  };

  return (
    <AppShell
      padding="md"
      navbar={isAuthorized ? <NavBar /> : <></>}
      header={
        <Header height={60} p="xs">
          <Group position="apart">
            <Image width={160} height={44} src={itTalentLogo} />
            <Group>
              <Switch
                onChange={(event) => toggleColorScheme(event.currentTarget.checked ? 'dark' : 'light')}
                size="xl"
                mr="md"
                color={dark ? 'gray' : 'dark'}
                onLabel={'dark'}
                offLabel={'light'}
              />
              {isAuthorized && (
                <Button radius="xl" onClick={handleLogout}>
                  Logout
                </Button>
              )}
            </Group>
          </Group>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
};
