import { instance } from './baseApi';
import { IPost, ITag } from '../redux/blog/blogTypes';

export const blogApi = {
  getPosts: (params: { page?: number; title?: string; date_from?: string; date_to?: string }) =>
    instance.get<IPost[]>(`v1/admin/blog-posts`, { params }),

  getPost: (id: number) => instance.get<IPost>(`v1/admin/blog-posts/${id}`),

  createPost: (post: IPost) => instance.post('v1/admin/blog-posts', post),

  deletePost: (id: number) => instance.delete(`v1/admin/blog-posts/${id}`),

  updatePost: (id: number, post: IPost) => instance.put(`v1/admin/blog-posts/${id}`, post),

  getTags: () => instance.get('v1/admin/blog-post-tags'),

  createTag: (label: ITag) => instance.post('v1/admin/blog-post-tags', label),

  deleteTag: (id: number) => instance.delete(`v1/admin/blog-post-tags/${id}`),
};
