//@ts-nocheck
import { useEffect, useState } from 'react';
import { Button, Checkbox, Container, FileInput, Group, LoadingOverlay, MultiSelect, Tabs, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IPost } from '../../redux/blog/blogTypes';
import { encodeFileInBase } from '../../helpers/helpers';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { PostForm } from './PostForm/PostForm';
import { blogActions } from '../../redux/blog/blogSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { blogSelectors } from '../../redux/blog/blogSelectors';

type Props = {
  isEditMode?: boolean;
};

export const NewPost = ({ isEditMode = false }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [fileInBase64, setFileInBase64] = useState<any>('');
  const [choosedTags, setTags] = useState<string[]>(['']);

  const post = useSelector(blogSelectors.selectPost);
  const isFetching = useSelector(blogSelectors.selectIsFetching);
  const tags = useSelector(blogSelectors.selectTags);

  const form = useForm<IPost>({
    initialValues: {
      title: {
        en: '',
        rus: '',
        est: '',
      },
      description: {
        en: '',
        rus: '',
        est: '',
      },
      quotation: {
        en: '',
        rus: '',
        est: '',
      },
      short_description: {
        en: '',
        rus: '',
        est: '',
      },
      recommended: true,
      main_photo: '',
      tags: [''],
    },
  });

  const onSubmit = (values: typeof form.values) => {
    const post = { ...values, main_photo: fileInBase64, tags: choosedTags };

    if (isEditMode && id) {
      dispatch(blogActions.updatePost({ id: +id, post, navigate }));
    } else {
      dispatch(blogActions.createPost({ post, navigate }));
    }
  };

  const onFileChange = async (file: File) => {
    const inBase64 = await encodeFileInBase(file);
    setFileInBase64(inBase64);
  };

  useEffect(() => {
    if (id) {
      dispatch(blogActions.getPost(+id));
    }
    dispatch(blogActions.getTags());
  }, [dispatch, id]);

  useEffect(() => {
    if (post) {
      setTags(post?.tags.map((tag) => tag.toString()));
    }
  }, [post]);

  console.log('choosedTags', choosedTags);
  console.log('post', post);

  useEffect(() => {
    if (isEditMode && post) {
      const currentData = {
        title: post.title,
        description: post.description,
        quotation: post.quotation,
        short_description: post.short_description,
        recommended: post.recommended,
        main_photo: post.main_photo,
        tags: post.tags,
      };

      form.setValues(currentData);
    }
  }, [isFetching, isEditMode, post]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <Title mb="xl" order={1}>
        Post
      </Title>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Tabs defaultValue="en">
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="en">Eng</Tabs.Tab>
            <Tabs.Tab value="rus">Rus</Tabs.Tab>
            <Tabs.Tab value="est">Est</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="en" pt="xs">
            <FileInput
              styles={{ required: { display: 'none' } }}
              required
              mb="md"
              value={fileInBase64.name}
              placeholder="Pick photo"
              label="Upload main photo"
              onChange={(file: File) => onFileChange(file)}
              description="The Size of the uploaded Image 645px by 1000px"
              inputWrapperOrder={['label', 'error', 'input', 'description']}
              accept="image/png,image/jpeg"
            />
            <MultiSelect
              data={tags.map((tag: any) => ({ value: `${tag.value}`, label: tag.label.en }))}
              label="Tags"
              placeholder="Select tags"
              value={choosedTags}
              onChange={setTags}
            />
            <PostForm form={form} language="en" />
            <Checkbox mt="md" label="Recommended" {...form.getInputProps('recommended', { type: 'checkbox' })} />
          </Tabs.Panel>

          <Tabs.Panel value="rus" pt="xs">
            <PostForm form={form} language="rus" />
          </Tabs.Panel>

          <Tabs.Panel value="est" pt="xs">
            <PostForm form={form} language="est" />
          </Tabs.Panel>
        </Tabs>

        <Group mt="xl" position="right">
          <Button loading={false} disabled={false} type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};
