import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IStepper {
  currentStep: number;
}

const initialState: IStepper = {
  currentStep: 0,
};

export const stepperSlice = createSlice({
  name: 'stepperSlice',
  initialState: initialState,
  reducers: {
    setCurrentStep(state: IStepper, { payload }: PayloadAction<number>) {
      state.currentStep = payload;
    },
  },
});

export const stepperReducer = stepperSlice.reducer;
export const stepperActions = stepperSlice.actions;