import {
  Button,
  Text,
  Stack,
  Group,
  MultiSelect,
  NumberInput,
  TextInput,
  createStyles,
  rem,
  Modal,
} from '@mantine/core';
import { randomId, useListState } from '@mantine/hooks';
import { AddIcon } from 'assets/icons/AddIcon';
import { useLanguage } from 'components/Language/LanguageContext';
import { RTE } from 'components/RTE';
import { initialTags } from '../../constants';
import { useEffect, useMemo, useState } from 'react';
import { EditableCourse, ModuleBlock } from 'redux/courses/coursesTypes';
import { IconGripVertical, IconTrash, IconEdit } from '@tabler/icons-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDisclosure } from '@mantine/hooks';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const useStyles = createStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
    justifyContent: 'space-between',
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: rem(30),
    fontWeight: 700,
    width: rem(60),
  },

  text: {
    marginRight: 'auto',
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },
}));

interface DraggableProps {
  item: ModuleBlock;
  index: number;
  handleDelete: (idx: number) => void;
  handleEdit: (idx: number) => void;
}

export function DraggableItem({ item, index, handleDelete, handleEdit }: DraggableProps) {
  const { classes, cx } = useStyles();
  const { language } = useLanguage();

  return (
    <Draggable key={item.key} index={index} draggableId={`${item.key}`}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical size="1.05rem" stroke={1.5} />
          </div>
          {/* <Text className={classes.symbol}>{item}</Text> */}
          <Stack spacing={0} className={classes.text}>
            <Text>{item.title[language]}</Text>
          </Stack>
          <Stack align="right">
            <Button size="xs" variant="outline" color="red" onClick={() => handleDelete(index)}>
              <IconTrash stroke={1} />
            </Button>
            <Button size="xs" variant="outline" color="blue" onClick={() => handleEdit(index)}>
              <IconEdit stroke={1} />
            </Button>
          </Stack>
        </div>
      )}
    </Draggable>
  );
}

export const ModulesForm = () => {
  const { language } = useLanguage();
  const form = useFormContext<EditableCourse>();
  const watchModuleBlock = form.watch('module_block');

  const moduleBlock = useMemo(() => form.getValues('module_block'), [watchModuleBlock]);
  

  const moduleBlocks = useFieldArray({
    control: form.control,
    name: 'module_block',
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [editIdx, setEditIdx] = useState(0);

  const [state, handlers] = useListState<ModuleBlock>(moduleBlock);

  const items = moduleBlocks.fields.map((item, index) => (
    <DraggableItem
      handleEdit={(idx) => {
        setEditIdx(idx);
        open();
      }}
      key={item.id}
      handleDelete={(idx) => handlers.remove(idx)}
      item={item}
      index={index}
    />
  ));

  useEffect(() => {
    form.setValue('module_block', state, { shouldDirty: true });
  }, [state]);

  const handleAddModule = () => {
    const currentLength = moduleBlock.length;

    moduleBlocks.append({
      key: randomId(),
      number: 1,
      title: {
        en: '',
        rus: '',
        est: '',
      },
      tags: [''],
      description: {
        en: '',
        rus: '',
        est: '',
      },
    });

    setEditIdx(currentLength);

    open();
  };

  return (
    <Stack>
      <TextInput
        withAsterisk
        label="Module program link"
        placeholder="Enter link for download program"
        {...form.register('module_program')}
      />

      <DragDropContext
        onDragEnd={({ destination, source }) =>
          moduleBlocks.move(source.index, destination?.index || 0)
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Modal opened={opened} onClose={close} size="lg">
        <Stack mt="xs">
          <Controller
            key={`${editIdx}`}
            control={form.control}
            name={`module_block.${editIdx}.number`}
            render={({ field }) => (
              <NumberInput
                withAsterisk
                label="Number"
                defaultValue={field.value}
                ref={field.ref}
                onChange={(e) => field.onChange(!Number.isNaN(e) ? e : parseInt(e as string))}
              />
            )}
          />
          <Controller
            key={`module_block.${editIdx}.title.${language}`}
            control={form.control}
            name={`module_block.${editIdx}.title.${language}`}
            render={({ field }) => (
              <TextInput withAsterisk label="Title" placeholder="Enter title" {...field} />
            )}
          />
          <Controller
            control={form.control}
            name={`module_block.${editIdx}.tags`}
            render={({ field }) => (
              <MultiSelect
                {...field}
                withAsterisk
                data={initialTags}
                label="Tags"
                placeholder="Pick tag"
              />
            )}
          />

          <Group>
            <Controller
              key={`module_block.${editIdx}.description.${language}`}
              control={form.control}
              name={`module_block.${editIdx}.description.${language}`}
              render={({ field }) => <RTE value={field.value} onChange={field.onChange} />}
            />
          </Group>
        </Stack>
      </Modal>

      {moduleBlock.length < 32 && (
        <Button leftIcon={<AddIcon />} onClick={handleAddModule}>
          Add module
        </Button>
      )}
    </Stack>
  );
};
