import { Flex, Title } from '@mantine/core';

interface PageTitleProps {
  text: string;
}

export const PageTitle = ({ text }: PageTitleProps) => {
  return (
    <Flex justify="space-between" align="center" direction="row" wrap="wrap">
      <Title order={3}>{text}</Title>
    </Flex>
  );
};
