import { Container, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { EditTeacherForm } from 'components/EditTeacherForm/EditTeacherForm';
import { TeacherFormProvider, useTeacherForm } from 'components/EditTeacherForm/form-context';
import { LanguageProvider } from 'components/Language/LanguageContext';
import { PageTitle } from 'components/common/PageTitle';
import { getTranslations } from 'helpers/get-translations.helper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { teachersSelectors } from 'redux/teachers/teachersSelectors';
import { teachersActions } from 'redux/teachers/teachersSlice';
import type { EditableTeacher } from 'redux/teachers/teachersTypes';
import { useForm, FormProvider, FieldErrors } from 'react-hook-form';
import { coursesActions } from 'redux/courses/coursesSlice';
import { webinarsActions } from 'redux/webinars/webinarsSlice';

const getEmptyTeacher = (): EditableTeacher => {
  const emptyTranslations = getTranslations([]);

  return {
    description: { ...emptyTranslations },
    short_description: { ...emptyTranslations },
    full_name: { ...emptyTranslations },
    display_about_us: false,
    team_position: { ...emptyTranslations },
    linkedin: '',
    main_photo: null,
    is_main_photo_reverse: false,
    meta_title: { ...emptyTranslations },
    meta_description: { ...emptyTranslations },
    languages: [],
    tags: [],
    reviews: [],
    webinar: undefined
  };
};

const urlValidate = /^(ftp|http|https):\/\/[^ "]+$/;

export const EditTeacher = () => {
  const { id } = useParams<{ id: string }>();

  const teacher = useSelector(teachersSelectors.selectTeacher);
  const isFetching = useSelector(teachersSelectors.selectIsFetching);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isEditMode = !!id;

  const pageTitle = isEditMode ? `Edit teacher ${teacher?.full_name.en}` : `New teacher`;

  const form = useForm({
    defaultValues: getEmptyTeacher(),
  })


  useEffect(() => {
    dispatch(webinarsActions.getWebinars());
    dispatch(coursesActions.getReviews());
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(teachersActions.getTeacher(id));
    }
  }, [id, isEditMode, dispatch]);

  useEffect(() => {
    if (teacher && !isFetching) {
      const formData: EditableTeacher = {
        ...teacher,
        meta_title: getTranslations(teacher.meta_title),
        description: getTranslations(teacher.description),
        short_description: getTranslations(teacher.short_description),
        full_name: getTranslations(teacher.full_name),
        team_position: getTranslations(teacher.team_position),
        meta_description: getTranslations(teacher.meta_description),
        webinar: teacher.webinar?.id,
        reviews: teacher.reviews?.map((review) => review.id) || []
      };

      form.reset(formData);
    }
  }, [id, isEditMode, isFetching, teacher]);

  const onSubmit = (values: EditableTeacher) => {
    const teacherData: EditableTeacher = values;

    if (isEditMode && id) {
      dispatch(teachersActions.updateTeacher({ id, teacher: teacherData, navigate }));
    } else {
      dispatch(teachersActions.createTeacher({ teacherData, navigate }));
    }
  };

  const handleError = (errors: FieldErrors) => {
    if (errors.languages) {
      showNotification({ message: 'Please choose minimum 1 language', color: 'red' });
    }
    if (errors.linkedin) {
      showNotification({ message: 'The linkedin must be a valid URL.', color: 'red' });
    }
  };

  return (
    <LanguageProvider>
      <Container size="lg" pl="xs">
        <LoadingOverlay visible={isFetching} overlayBlur={2} />
        <PageTitle text={pageTitle} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, handleError)}>
            <EditTeacherForm />
          </form>
        </FormProvider>
      </Container>
    </LanguageProvider>
  );
};
