import { EditableWebinar, IWebinar } from '../redux/webinars/webinarsTypes';
import { instance } from './baseApi';

export const webinarsApi = {
  getWebinar: (id: string) => instance.get<IWebinar>(`v1/admin/webinars/${id}`),

  getWebinars: () => instance.get<IWebinar[]>(`v1/admin/webinars`),

  createWebinar: (data: EditableWebinar) => instance.post('v1/admin/webinars', data),

  deleteWebinar: (id: number) => instance.delete(`v1/admin/webinars/${id}`),

  updateWebinar: (id: string, data: EditableWebinar) => instance.put<any>(`v1/admin/webinars/${id}`, data),
};
