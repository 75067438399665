import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { handleServerNetworkError } from '../../helpers/helpers';
import { blogActions } from './blogSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IPost, IPostResponse, ITag } from './blogTypes';
import { NavigateFunction } from 'react-router-dom';
import { blogApi } from '../../api/blogApi';
import { IMeta } from '../../helpers/commonTypes';
import { paginationActions } from '../pagination/paginationSlice';
import { showNotification } from '@mantine/notifications';
import { paginationSelectors } from '../pagination/paginationSelectors';
import { IPagination } from '../pagination/paginationTypes';
import { APP_ROUTES } from '../../constants';
import { blogSelectors } from './blogSelectors';

function* getPosts({
  payload,
}: PayloadAction<{ page?: number; title?: string; date_from?: string; date_to?: string }>) {
  yield put(blogActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<{ data: IPostResponse[]; meta: IMeta }> = yield blogApi.getPosts({
      page: payload.page,
      title: payload.title,
      date_from: payload.date_from,
      date_to: payload.date_to,
    });

    yield put(blogActions.setPosts(data.data));
    yield put(paginationActions.setPaginationData(data.meta));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* getPost({ payload }: PayloadAction<number>) {
  yield put(blogActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IPostResponse> = yield blogApi.getPost(payload);

    yield put(blogActions.setPost(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* createPost({ payload: { post, navigate } }: PayloadAction<{ post: IPost; navigate: NavigateFunction }>) {
  yield put(blogActions.setIsFetching(true));
  try {
    yield blogApi.createPost(post);

    navigate(APP_ROUTES.POSTS);

    showNotification({
      message: 'Post created',
      color: 'green',
      autoClose: 3000,
    });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* updatePost({
  payload: { id, post, navigate },
}: PayloadAction<{ id: number; post: IPost; navigate: NavigateFunction }>) {
  yield put(blogActions.setIsFetching(true));
  try {
    yield blogApi.updatePost(id, post);
    yield put(blogActions.setPost({ ...post, id, created_at: new Date(), updated_at: new Date() }));

    // navigate(APP_ROUTES.POSTS);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* deletePost({ payload }: PayloadAction<number>) {
  yield put(blogActions.setIsFetching(true));
  try {
    const paginationData: IPagination = yield select(paginationSelectors.selectPaginationData);
    yield blogApi.deletePost(payload);

    yield call<any>(getPosts, { payload: paginationData.current_page });
    showNotification({
      message: 'Post deleted',
      color: 'green',
      autoClose: 3000,
    });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* createTag({ payload }: PayloadAction<ITag>) {
  yield put(blogActions.setIsFetching(true));
  try {
    const { data } = yield blogApi.createTag(payload);

    const tags: any[] = yield select(blogSelectors.selectTags);

    yield put(blogActions.setTags([...tags, data]));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* deleteTag({ payload }: PayloadAction<number>) {
  yield put(blogActions.setIsFetching(true));
  try {
    yield blogApi.deleteTag(payload);

    yield call(getTags);
    showNotification({
      message: 'Tag deleted',
      color: 'green',
      autoClose: 3000,
    });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

function* getTags() {
  yield put(blogActions.setIsFetching(true));
  try {
    const { data } = yield blogApi.getTags();

    yield put(blogActions.setTags(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(blogActions.setIsFetching(false));
  }
}

export function* blogSagas() {
  yield all([
    takeLatest(blogActions.getPosts.toString(), getPosts),
    takeLatest(blogActions.getPost.toString(), getPost),
    takeLatest(blogActions.createPost.toString(), createPost),
    takeLatest(blogActions.deletePost.toString(), deletePost),
    takeLatest(blogActions.updatePost.toString(), updatePost),
    takeLatest(blogActions.getTags.toString(), getTags),
    takeLatest(blogActions.createTag.toString(), createTag),
    takeLatest(blogActions.deleteTag.toString(), deleteTag),
  ]);
}
