import { PayloadAction } from '@reduxjs/toolkit';
import { coursesApi } from 'api/coursesApi';
import { AxiosError, AxiosResponse } from 'axios';
import { IMeta } from 'helpers/commonTypes';
import { handleServerNetworkError } from 'helpers/helpers';
import { NavigateFunction } from 'react-router-dom';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { APP_ROUTES } from '../../constants';
import { paginationSelectors } from '../pagination/paginationSelectors';
import { paginationActions } from '../pagination/paginationSlice';
import { IPagination } from '../pagination/paginationTypes';
import { coursesSelectors } from './coursesSelectors';
import { coursesActions } from './coursesSlice';
import { ICourse, IFeedback, IRate, IShortCourses } from './coursesTypes';

function* getCourses() {
  yield put(coursesActions.setIsFetching(true));
  try {
    const response: AxiosResponse<IShortCourses[]> = yield coursesApi.getCourses();

    yield put(coursesActions.setShortCourses(response.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getCourseById({ payload }: PayloadAction<string>) {
  // yield put(coursesActions.setIsFetching(true));
  yield put(coursesActions.setCourseFetching(true));
  try {
    const { data }: AxiosResponse<ICourse> = yield coursesApi.getCourseById(payload);

    yield put(coursesActions.setCourseData(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {

    // yield put(coursesActions.setIsFetching(false));
    yield put(coursesActions.setCourseFetching(false));
  }
}

function* getCoursesList({
  payload: { status, page },
}: PayloadAction<{ status: string; page: number }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<{ data: ICourse[]; meta: IMeta }> =
      yield coursesApi.getCoursesList(status, page);

    yield put(paginationActions.setPaginationData(data.meta));

    yield put(coursesActions.setCoursesList(data.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* createCourse({
  payload,
}: PayloadAction<{ courseData: ICourse; navigate: NavigateFunction; take: unknown }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.createCourse(payload.courseData);

    payload.navigate(APP_ROUTES.COURSES);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* deleteCourse({ payload: { id, status } }: PayloadAction<{ id: number; status: string }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.deleteCourse(id);

    const paginationData: IPagination = yield select(paginationSelectors.selectPaginationData);

    const payload = { payload: { status, page: paginationData.current_page } };

    yield call<any>(getCoursesList, payload);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* duplicateCourse({
  payload: { id, status },
}: PayloadAction<{ id: number; status: string }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.duplicateCourse(id);

    const paginationData: IPagination = yield select(paginationSelectors.selectPaginationData);

    const payload = { payload: { status, page: paginationData.current_page } };

    yield call<any>(getCoursesList, payload);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* updateCourse({
  payload: { id, courseData, navigate },
}: PayloadAction<{ id: string; courseData: ICourse; navigate?: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    console.log(courseData);
    const { data } = yield coursesApi.updateCourse(id, courseData);
    yield put(coursesActions.setCourseData(data));
    yield put(coursesActions.setIsError(false));

    if (navigate) {
      navigate(APP_ROUTES.COURSES, { replace: true });
    }
    //
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
    yield put(coursesActions.setIsError(true));
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* createRate({
  payload: { rate, navigate },
}: PayloadAction<{ rate: Omit<IRate, 'id'>; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.createRate(rate);

    navigate(APP_ROUTES.RATES);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* updateRate({
  payload: { id, rate, navigate },
}: PayloadAction<{ id: string; rate: Omit<IRate, 'id'>; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.updateRate(id, rate);

    navigate(APP_ROUTES.RATES);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getRates() {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<{ data: IRate[] }> = yield coursesApi.getRates();

    yield put(coursesActions.setRates(data.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getRate({ payload }: PayloadAction<string>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IRate> = yield coursesApi.getRate(payload);

    yield put(coursesActions.setRate(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* deleteRate({ payload }: PayloadAction<number>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.deleteRate(payload);

    yield call(getRates);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* createFeedback({
  payload: { feedback, navigate },
}: PayloadAction<{ feedback: IFeedback; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.createFeedback(feedback);

    navigate(APP_ROUTES.FEEDBACKS);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* deleteFeedback({ payload }: PayloadAction<number>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.deleteFeedback(payload);

    yield call(getFeedbacks);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getFeedbacks() {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<{ data: IFeedback[] }> = yield coursesApi.getFeedbacks();

    yield put(coursesActions.setFeedbacks(data.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getFeedback({ payload }: PayloadAction<string>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IFeedback> = yield coursesApi.getFeedback(payload);

    yield put(coursesActions.setFeedback(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* updateFeedback({
  payload: { id, feedback, navigate },
}: PayloadAction<{ id: string; feedback: Omit<IFeedback, 'id'>; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.updateFeedback(id, feedback);

    navigate(APP_ROUTES.FEEDBACKS);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getReviews() {
  yield put(coursesActions.setIsFetching(true));
  try {
    const response: AxiosResponse<any> = yield coursesApi.getReviews();

    yield put(coursesActions.setReviews(response.data.data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* getReview({ payload }: PayloadAction<string>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<IFeedback> = yield coursesApi.getReview(payload);

    yield put(coursesActions.setReview(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* createReview({
  payload: { review, navigate },
}: PayloadAction<{ review: any; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    //@ts-ignore
    const res = yield coursesApi.createReview(review);
    //@ts-ignore
    const reviews = yield select(coursesSelectors.selectReviews);

    yield put(coursesActions.setReviews([...reviews, res.data]));
    const id = res.data.id;
    yield put(coursesActions.setReviewId({ id }));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* updateReview({
  payload: { id, review, navigate },
}: PayloadAction<{ id: string; review: any; navigate: NavigateFunction }>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    const { data } = yield coursesApi.updateReview(id, review);
    yield put(coursesActions.setReview(data));
    // navigate(APP_ROUTES.COURSES_REVIEW);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

function* deleteReview({ payload }: PayloadAction<number>) {
  yield put(coursesActions.setIsFetching(true));
  try {
    yield coursesApi.deleteReview(payload);

    yield call(getReviews);
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(coursesActions.setIsFetching(false));
  }
}

export function* coursesSagas() {
  yield all([
    takeEvery(coursesActions.getCourses.toString(), getCourses),
    takeEvery(coursesActions.getCoursesList.toString(), getCoursesList),
    takeEvery(coursesActions.createCourse.toString(), createCourse),
    takeEvery(coursesActions.deleteCourse.toString(), deleteCourse),
    takeEvery(coursesActions.updateCourse.toString(), updateCourse),
    takeEvery(coursesActions.getCourseById.toString(), getCourseById),
    takeEvery(coursesActions.createRate.toString(), createRate),
    takeEvery(coursesActions.getRates.toString(), getRates),
    takeEvery(coursesActions.getRate.toString(), getRate),
    takeEvery(coursesActions.deleteRate.toString(), deleteRate),
    takeEvery(coursesActions.updateRate.toString(), updateRate),
    takeEvery(coursesActions.createFeedback.toString(), createFeedback),
    takeEvery(coursesActions.getFeedbacks.toString(), getFeedbacks),
    takeEvery(coursesActions.getFeedback.toString(), getFeedback),
    takeEvery(coursesActions.deleteFeedback.toString(), deleteFeedback),
    takeEvery(coursesActions.updateFeedback.toString(), updateFeedback),
    takeEvery(coursesActions.duplicateCourse.toString(), duplicateCourse),
    takeEvery(coursesActions.getReviews.toString(), getReviews),
    takeEvery(coursesActions.getReview.toString(), getReview),
    takeEvery(coursesActions.createReview.toString(), createReview),
    takeEvery(coursesActions.updateReview.toString(), updateReview),
    takeEvery(coursesActions.deleteReview.toString(), deleteReview),
  ]);
}
