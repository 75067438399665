import { Button, Container, Group, LoadingOverlay, Tabs, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { ILang } from '../../helpers/commonTypes';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { settingsActions } from '../../redux/settings/settingsSlice';
import { useSelector } from 'react-redux';
import { settingsSelectors } from '../../redux/settings/settingsSelectors';
import { RTE } from 'components/RTE';

type Props = {
  path: string;
};

export const EditablePage = ({ path }: Props) => {
  const dispatch = useAppDispatch();

  const isFetching = useSelector(settingsSelectors.selectIsFetching);
  const settings = useSelector(settingsSelectors.selectSettings);

  const form = useForm<{ value: ILang }>({
    initialValues: {
      value: {
        en: '',
        rus: '',
        est: '',
      },
    },
  });

  const onSubmit = (values: typeof form.values) => {
    dispatch(settingsActions.updateSettings({ setting: values, key: path }));
  };

  useEffect(() => {
    dispatch(settingsActions.getSettings(path));

    return () => {
      dispatch(settingsActions.setSettings({ key: path, value: { en: '', rus: '', est: '' } }));
      form.reset();
    };
  }, [path]);

  useEffect(() => {
    if (settings && !isFetching) {
      form.setValues({ value: settings.value });
    }
  }, [isFetching, settings]);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <Title mb="xl" order={1}>
        {path}
      </Title>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Tabs defaultValue="en">
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="en">Eng</Tabs.Tab>
            <Tabs.Tab value="rus">Rus</Tabs.Tab>
            <Tabs.Tab value="est">Est</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="en" pt="xs">
            <RTE {...form.getInputProps('value.en')} />
          </Tabs.Panel>

          <Tabs.Panel value="rus" pt="xs">
            <RTE {...form.getInputProps('value.rus')} />
          </Tabs.Panel>

          <Tabs.Panel value="est" pt="xs">
            <RTE {...form.getInputProps('value.est')} />
          </Tabs.Panel>
        </Tabs>

        <Group position="right">
          <Button mt="md" loading={false} disabled={false} type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};
