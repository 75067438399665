import { Button, Container, Group, Menu, Stack, Text } from '@mantine/core';
import { memo } from 'react';
import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { DuplicateIcon } from '../../assets/icons/DuplicateIcon';
import { IconArchive, IconFoldUp } from '@tabler/icons-react';

type Props = {
  status: 'active' | 'drafts' | 'archive';
  courseName: string;
  startDate: string;
  handleDelete: () => void;
  handleEdit: () => void;
  handleDuplicate: () => void;
  handleEditNew: () => void;
  handleArchive: () => void;
};

export const CourseCard = memo(
  ({
    courseName,
    startDate,
    handleEdit,
    handleEditNew,
    handleDelete,
    handleDuplicate,
    handleArchive,
    status,
  }: Props) => {
    return (
      <Container mt="md" style={{ border: '1px solid #EBEFFF', borderRadius: '8px', padding: '24px 32px' }}>
        <Group position="apart">
          <Stack>
            <Text weight="bold" size="md">
              {courseName}
            </Text>
            <Text size="md">{startDate}</Text>
          </Stack>
          <Menu shadow="md" transitionProps={{ transition: 'rotate-right', duration: 150 }} width="auto">
            <Menu.Target>
              <Button>Settings</Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={handleArchive}
                icon={
                  status === 'active' ? (
                    <IconArchive width={12} color="#292D32" />
                  ) : (
                    <IconFoldUp width={12} color="#292D32" />
                  )
                }
              >
                {status === 'active' ? 'Archive' : 'Make active'}
              </Menu.Item>
              <Menu.Item onClick={handleDuplicate} icon={<DuplicateIcon />}>
                Duplicate
              </Menu.Item>
              <Menu.Item onClick={handleEditNew} icon={<EditIcon />}>
                Edit
              </Menu.Item>
              <Menu.Item onClick={handleDelete} icon={<DeleteIcon />}>
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Container>
    );
  },
);
