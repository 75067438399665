import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Group, LoadingOverlay, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

import { coursesActions } from '../../redux/courses/coursesSlice';
import { coursesSelectors } from '../../redux/courses/coursesSelectors';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { APP_ROUTES } from '../../constants';
import { EmptyListContainer } from '../../components/containers';
import { FeedbackCard } from '../../components';

export const Feedbacks = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const feedbacks = useSelector(coursesSelectors.selectFeedbacks);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);

  const isEmpty = !feedbacks || !feedbacks.length;

  const handleCreate = () => {
    navigate(APP_ROUTES.CREATE_FEEDBACK);
  };

  const handleDelete = useCallback((id: number) => {
    openConfirmModal({
      title: 'Delete feedback',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete feedback?</Text>,
      labels: { confirm: 'Delete feedback', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(coursesActions.deleteFeedback(id)),
    });
  }, []);

  const handleChange = useCallback((id: number) => {
    navigate(`${APP_ROUTES.CREATE_FEEDBACK}/${id}`);
  }, []);

  useEffect(() => {
    dispatch(coursesActions.getFeedbacks());
  }, []);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!feedbacks) {
    return null;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        buttonText="Add case"
        title="Your cases list is empty."
        description="Add feedback and enter information about them."
        onClick={handleCreate}
      >
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title order={1}>Cases</Title>
          <Button onClick={handleCreate}>Add case</Button>
        </Group>
        {feedbacks.map(({ name, image, id, courses }) => (
          <FeedbackCard
            handleDelete={() => handleDelete(id)}
            handleChange={() => handleChange(id)}
            key={id}
            name={name.en}
            avatar={image}
            linkedCourses={courses}
          />
        ))}
      </EmptyListContainer>
    </Container>
  );
};
