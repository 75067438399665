import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../helpers/nullable';
import { ISetting } from './settingsTypes';

interface IState {
  isFetching: boolean;
  settings: Nullable<ISetting>;
}

const initialState: IState = {
  settings: null,
  isFetching: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    getSettings(state, { payload }: PayloadAction<string>) {},
    setSettings(state, { payload }: PayloadAction<ISetting>) {
      state.settings = payload;
    },
    setIsFetching(state, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    updateSettings(state, { payload }: PayloadAction<{ setting: Omit<ISetting, 'key'>; key: string }>) {},
  },
});

export const settingsReducer = settingsSlice.reducer;
export const settingsActions = settingsSlice.actions;
