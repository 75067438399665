import { Stack, TextInput, Text, MultiSelect, Group, Checkbox } from '@mantine/core';
import { useTeacherFormContext } from './form-context';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageFlags, LanguageNames } from 'components/Language/types';
import { useCallback } from 'react';
import { ImageField } from 'components/common/ImageFiels';
import { Controller, useFormContext } from 'react-hook-form';
import { EditableTeacher } from 'redux/teachers/teachersTypes';

export const GeneralTeacherForm = () => {
  const { language } = useLanguage();
  const form = useFormContext<EditableTeacher>();

  return (
    <Stack>
      <Group grow>
        <Controller
          name="main_photo"
          control={form.control}
          render={({ field }) => {
            return <ImageField onChange={field.onChange} currentValue={field.value} />;
          }}
        />

        <Stack>
          <Controller
            name={`full_name.${language}`}
            key={`full_name.${language}`}
            control={form.control}
            render={({ field }) => (
              <TextInput
                styles={{ required: { display: 'none' } }}
                required
                label="Full name"
                placeholder="Enter name"
                rightSection={<Text>{LanguageFlags[language]}</Text>}
                {...field}
              />
            )}
          />

          <Controller
            key={`team_position.${language}`}
            name={`team_position.${language}`}
            control={form.control}
            render={({ field }) => (
              <TextInput
                styles={{ required: { display: 'none' } }}
                required
                label="Role"
                placeholder="Enter the role of a team member"
                rightSection={<Text>{LanguageFlags[language]}</Text>}
                {...field}
              />
            )}
          />

          <Controller
            name="linkedin"
            control={form.control}
            render={({ field }) => (
              <TextInput
                styles={{ required: { display: 'none' } }}
                required
                label="LinkedIn"
                placeholder="Insert LinkedIn link"
                {...field}
              />
            )}
          />

          <Controller
            name="slug"
            control={form.control}
            render={({ field }) => (
              <TextInput
                styles={{ required: { display: 'none' } }}
                required
                label="slug"
                placeholder="Enter Slug"
                {...field}
              />
            )}
          />
          <Group>
            <Controller
              name={'is_main_photo_reverse'}
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  mt="xs"
                  ref={field.ref}
                  checked={field.value}
                  label="Main photo reverse"
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />

            <Controller
              name={'display_about_us'}
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  mt="xs"
                  ref={field.ref}
                  checked={field.value}
                  label="Displayed on «About Us»"
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </Group>
          <Controller
            name="languages"
            control={form.control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                data={Object.keys(LanguageNames).map((lang) => ({
                  label: LanguageNames[lang as Language],
                  value: lang,
                }))}
                label="Languages"
                placeholder="Pick language"
              />
            )}
          />
        </Stack>
      </Group>

      <Controller
        name="tags"
        control={form.control}
        render={({ field }) => (
          <MultiSelect
            {...field}
            data={field.value.map((item) => ({ value: item, label: item }))}
            label="Tags"
            creatable
            searchable
            placeholder="Select tags"
            getCreateLabel={(query) => `+ Create new tag: ${query}`}
            onCreate={(query) => {
              field.onChange([...field.value, query]);

              return { value: query, label: query };
            }}
          />
        )}
      />
    </Stack>
  );
};
