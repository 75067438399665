import {
  Group,
  Stack,
  Text,
  Card,
  Avatar,
  Flex,
  List,
  Button,
  Box,
  SelectItemProps,
  Select,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useLanguage } from 'components/Language/LanguageContext';
import { forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { coursesSelectors } from 'redux/courses/coursesSelectors';
import { EditableCourse } from 'redux/courses/coursesTypes';
import { useFieldArray, useFormContext } from 'react-hook-form';

const Item = forwardRef<HTMLDivElement, SelectItemProps>(({ label, value, ...others }, ref) => {
  const feedbacks = useSelector(coursesSelectors.selectFeedbacks);
  const feedback = feedbacks?.find((teacher) => teacher.id === Number(value)) || null;
  const { language } = useLanguage();
  return (
    <div ref={ref} {...others}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <Box mr={10}>
          <Avatar src={feedback?.image} />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start', flexDirection: 'column' }}>
          <div>
            {feedback?.name[language]} - {feedback?.from[language]}
          </div>

          <div>{feedback?.review[language]}</div>
        </Box>
      </Box>
    </div>
  );
});

interface FeedbackCardProps {
  idx: number;
  onDelete: (teacherId: number) => void;
}

const FeedbackCard = ({ idx, onDelete }: FeedbackCardProps) => {
  const { language } = useLanguage();
  const form = useFormContext<EditableCourse>();
  const feedbackId = form.getValues(`feedbacks.${idx}`);
  const feedbacks = useSelector(coursesSelectors.selectFeedbacks);
  const feedback = feedbacks?.find((teacher) => teacher.id === Number(feedbackId)) || null;

  const handleDelete = useCallback(
    (_: unknown) => {
      onDelete(idx);
    },
    [onDelete],
  );

  if (!feedback) {
    return (
      <Card shadow="sm" p="lg" radius="md" withBorder>
        Feedback not found
      </Card>
    );
  }

  return (
    <Card shadow="sm" p="md" radius="md" withBorder>
      <Stack>
        <Flex justify="space-between" direction="row">
          <Group>
            <Avatar src={feedback.image}></Avatar>
            <Text>{feedback.name[language]}</Text>
            <Text>{feedback.from[language]}</Text>
          </Group>

          <Group position="right" align="right">
            <Button
              size="xs"
              leftIcon={<IconTrash stroke={1} />}
              variant="outline"
              color="red"
              onClick={handleDelete}
            >
              Remove from course
            </Button>
          </Group>
        </Flex>
        <List>
          {feedback.points.map((point) => (
            <List.Item key={point.key}>
              {point.title[language]} {point.description[language]}
            </List.Item>
          ))}
        </List>

        <Text>{feedback.review[language]}</Text>
      </Stack>
    </Card>
  );
};

export const FeedbackForm = () => {
  const form = useFormContext();
  const allFeedbacks = useSelector(coursesSelectors.selectFeedbacks);
  const feedbackValues = useFieldArray({
    control: form.control,
    name: 'feedbacks',
  });

  const { language } = useLanguage();

  const watchFeedbacks = form.watch('feedbacks');

  const avalaibleFeedback = useMemo(
    () =>
      allFeedbacks?.filter((feedback) => {
        const feedbacks = form.getValues('feedbacks');

        return feedbackValues.fields?.findIndex((id, idx) => feedback.id === feedbacks[idx]) === -1;
      }) ?? [],
    [allFeedbacks, watchFeedbacks],
  );

  const feedbackFormatted = useMemo(
    () =>
      avalaibleFeedback.map((feedback) => ({
        label: feedback.review[language],
        value: String(feedback.id),
        ...feedback,
      })) || [],
    [avalaibleFeedback, language],
  );

  const addFeedback = (teacherId: string) => {
    feedbackValues.append(Number(teacherId));
  };

  const deleteFeedback = (feedbackIdx: number) => {
    feedbackValues.remove(feedbackIdx);
  };

  return (
    <Stack>
      {avalaibleFeedback.length > 0 && (
        <Select
          clearable={false}
          label={<Text>Add case</Text>}
          data={feedbackFormatted}
          limit={20}
          value={null}
          searchable
          onChange={addFeedback}
          itemComponent={Item}
        />
      )}

      {feedbackValues.fields?.map((item, idx) => (
        <FeedbackCard key={item.id} idx={idx} onDelete={deleteFeedback} />
      ))}
    </Stack>
  );
};
