import { ActionIcon, Button, Divider, Group, Stack, Text, TextInput } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { AddIcon } from 'assets/icons/AddIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { useLanguage } from 'components/Language/LanguageContext';
import { LanguageFlags } from 'components/Language/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EditableCourse } from 'redux/courses/coursesTypes';

export const ResultPointsForm = () => {
  const { language } = useLanguage();
  const form = useFormContext<EditableCourse>();

  const pointsFormArray = useFieldArray({
    control: form.control,
    name: 'you_get_description_block.text_block',
  });

  const handleAddPoint = () => {
    pointsFormArray.append({
      key: randomId(),
      title: {
        en: '',
        rus: '',
        est: '',
      },
      description: {
        en: '',
        rus: '',
        est: '',
      },
    });
  };

  return (
    <Stack>
      <TextInput
        key={`you_get_description_block.title.${language}`}
        withAsterisk
        label="Name"
        placeholder="Enter name"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        {...form.register(`you_get_description_block.title.${language}`)}
      />

      <TextInput
        key={`speaker_photo_or_video.${language}`}
        label="Video"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        placeholder="Enter youtube short code"
        {...form.register(`speaker_photo_or_video.${language}`)}
      />

      {pointsFormArray.fields.map((field, index) => (
        <Stack key={field.id}>
          <Group>
            <TextInput
              key={`${language}-${field.id}-title`}
              withAsterisk
              style={{ width: '100%' }}
              label="Title"
              placeholder="Enter title"
              rightSection={<Text>{LanguageFlags[language]}</Text>}
              {...form.register(`you_get_description_block.text_block.${index}.title.${language}`)}
            />
            <TextInput
              key={`${language}-${field.id}-desc`}
              style={{ width: '100%' }}
              withAsterisk
              label="Description"
              placeholder="Enter description"
              rightSection={<Text>{LanguageFlags[language]}</Text>}
              {...form.register(
                `you_get_description_block.text_block.${index}.description.${language}`,
              )}
            />
            <ActionIcon mt="xl" color="red" onClick={() => pointsFormArray.remove(index)}>
              <DeleteIcon />
            </ActionIcon>
            <Divider my="sm" variant="dashed" />
          </Group>
        </Stack>
      ))}

      <Button mt="md" leftIcon={<AddIcon />} onClick={handleAddPoint}>
        Add point
      </Button>
    </Stack>
  );
};
