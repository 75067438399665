import { useForm } from '@mantine/form';
import { Button, Container, Group, TextInput, Title } from '@mantine/core';
import { ITag } from '../../redux/blog/blogTypes';
import { useDispatch, useSelector } from 'react-redux';
import { blogActions } from '../../redux/blog/blogSlice';
import { useCallback, useEffect } from 'react';
import { blogSelectors } from '../../redux/blog/blogSelectors';
import { CreatedTags } from './CreatedTags/CreatedTags';

export const BlogTags = () => {
  const dispatch = useDispatch();

  const form = useForm<ITag>({
    initialValues: {
      label: {
        en: '',
        rus: '',
        est: '',
      },
    },
  });

  const tags = useSelector(blogSelectors.selectTags);
  const isFetching = useSelector(blogSelectors.selectIsFetching);

  const onSubmit = (tag: ITag) => {
    dispatch(blogActions.createTag(tag));
    form.reset();
  };

  const handleDelete = useCallback((id: number) => {
    dispatch(blogActions.deleteTag(id));
  }, []);

  useEffect(() => {
    dispatch(blogActions.getTags());
  }, []);

  return (
    <Container>
      <Title mb="xl" order={1}>
        Tags
      </Title>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput required label="Create tag en" placeholder="enter tag" {...form.getInputProps('label.en')} />
        <TextInput
          mt="md"
          required
          label="Create tag rus"
          placeholder="enter tag"
          {...form.getInputProps('label.rus')}
        />
        <TextInput
          mt="md"
          required
          label="Create tag est"
          placeholder="enter tag"
          {...form.getInputProps('label.est')}
        />

        <Group position="right" mt="md">
          <Button disabled={isFetching} type="submit">
            create tag
          </Button>
        </Group>
        <CreatedTags tags={tags} isFetching={isFetching} handleDelete={handleDelete} />
      </form>
    </Container>
  );
};
