import { all, call, put, takeLatest } from 'redux-saga/effects';

import { handleServerNetworkError } from '../../helpers/helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { settingsActions } from './settingsSlice';
import { ISetting } from './settingsTypes';
import { settingsApi } from '../../api/settingsApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { showNotification } from '@mantine/notifications';

function* getSettings({ payload }: PayloadAction<string>) {
  yield put(settingsActions.setIsFetching(true));
  try {
    const { data }: AxiosResponse<ISetting> = yield settingsApi.getSettings(payload);

    yield put(settingsActions.setSettings(data));
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(settingsActions.setIsFetching(false));
  }
}

function* updateSettings({ payload }: PayloadAction<{ setting: Omit<ISetting, 'key'>; key: string }>) {
  yield put(settingsActions.setIsFetching(true));
  try {
    yield settingsApi.updateSettings(payload.setting, payload.key);

    showNotification({
      message: 'successfully updated',
      color: 'green',
      autoClose: 3000,
    });

    yield call<any>(getSettings, { payload: payload.key });
  } catch (error) {
    handleServerNetworkError(error as AxiosError | Error);
  } finally {
    yield put(settingsActions.setIsFetching(false));
  }
}

export function* settingsSagas() {
  yield all([
    takeLatest(settingsActions.getSettings.toString(), getSettings),
    takeLatest(settingsActions.updateSettings.toString(), updateSettings),
  ]);
}
