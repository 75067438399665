import { Stack, TextInput, Textarea, Text } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { LanguageFlags } from 'components/Language/types';
import { useFormContext } from 'react-hook-form';

export const MetaCourseForm = () => {
  const form = useFormContext();
  const { language } = useLanguage();

  return (
    <Stack>
      <TextInput
        key={`meta_title.${language}`}
        withAsterisk
        label="Meta title"
        placeholder="Enter title for meta"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        {...form.register(`meta_title.${language}`)}
      />
      <Textarea
        key={`meta_description.${language}`}
        withAsterisk
        autosize
        minRows={4}
        maxRows={4}
        label="Meta description"
        placeholder="Enter meta description"
        rightSection={<Text>{LanguageFlags[language]}</Text>}
        {...form.register(`meta_description.${language}`)}
      />
    </Stack>
  );
};
