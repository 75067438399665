import { memo } from 'react';
import { Button, Container, Group, Menu, Stack, Text } from '@mantine/core';

import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';

type Props = {
  name: string;
  description: string;
  questions: any[];
  handleEdit: () => void;
  handleDelete: () => void;
};

export const TestCard = memo(({ name, description, questions, handleEdit, handleDelete }: Props) => {
  return (
    <Container mt="md" style={{ border: '1px solid #EBEFFF', borderRadius: '8px', padding: '24px 32px' }}>
      <Group position="apart">
        <Stack>
          <Text>{name}</Text>
          <Text>{description}</Text>
        </Stack>
        <Text>Questions: {questions.length}</Text>
        <Menu zIndex={1337} shadow="md" transitionProps={{ transition: 'rotate-right', duration: 150 }} width="auto">
          <Menu.Target>
            <Button>Settings</Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item onClick={handleEdit} icon={<EditIcon />}>
              Edit
            </Menu.Item>
            <Menu.Item onClick={handleDelete} icon={<DeleteIcon />}>
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Container>
  );
});
