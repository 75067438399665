import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, LoadingOverlay, Group, Title, Button, Text, Stack } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

import { webinarsSelectors } from '../../redux/webinars/webinarsSelectors';
import { webinarsActions } from '../../redux/webinars/webinarsSlice';
import { IWebinar } from '../../redux/webinars/webinarsTypes';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { APP_ROUTES } from '../../constants';
import { EmptyListContainer } from '../../components/containers';
import { WebinarCard } from '../../components/WebinarCard/WebinarCard';

export const Webinars = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const webinars = useSelector(webinarsSelectors.selectWebinars);
  const isFetching = useSelector(webinarsSelectors.selectIsFetching);

  const isEmpty = !webinars || webinars.length === 0;

  const handleCreate = () => {
    navigate(APP_ROUTES.CREATE_WEBINAR);
  };

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`${APP_ROUTES.CREATE_WEBINAR}/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback((id: number) => {
    openConfirmModal({
      title: 'Delete webinar',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete webinar?</Text>,
      labels: { confirm: 'Delete webinar', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onConfirm: () => dispatch(webinarsActions.deleteWebinar(id)),
    });
  }, []);

  useEffect(() => {
    dispatch(webinarsActions.getWebinars());
  }, []);

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  if (!webinars) {
    return null;
  }

  return (
    <Container>
      <EmptyListContainer
        isEmpty={isEmpty}
        buttonText="Add webinar"
        title="Your don’t have any webinar."
        description="Create your first webinar and enter the details."
        onClick={handleCreate}
      >
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title order={1}>Webinars</Title>
          <Button onClick={handleCreate}>Create webinar</Button>
        </Group>

        <Stack spacing="sm" mt="md">
          {webinars.map(
            ({ name, start_at_date, start_at_time, id, kajabi_url, timer_enabled }: IWebinar) => (
              <WebinarCard
                key={id}
                handleEdit={() => handleEdit(id)}
                handleDelete={() => handleDelete(id)}
                name={name.en}
                url={kajabi_url}
                withTimer={timer_enabled}
                date={start_at_date as string}
                time={start_at_time as string}
              />
            ),
          )}
        </Stack>
      </EmptyListContainer>
    </Container>
  );
};
