import { Alert, Button, Paper, SegmentedControl, Stack, Tabs } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageNames } from 'components/Language/types';
import { useWebinarFormContext } from './webinar-form-context';
import { WebinarGeneralFormSection } from './form-sections/WebinarGeneralFormSection';

function SaveAlert() {
  return (
    <Alert color="yellow" radius="md" variant="filled">
      Unsaved data
    </Alert>
  );
}

function SaveForm() {
  return (
    <Button color="green" radius="md" variant="filled" type="submit">
      Save
    </Button>
  );
}

export const EditWebinarForm = () => {
  const form = useWebinarFormContext();
  const { language, updateLanguage } = useLanguage();

  return (
    <Tabs
      defaultValue="general"
      keepMounted={false}
      variant="pills"
      radius="md"
      orientation="vertical"
      mt={5}
    >
      <Tabs.List style={{ position: 'sticky', top: '80px', alignSelf: 'flex-start' }}>
        <Tabs.Tab value="general">General</Tabs.Tab>

        <Stack mt={10}>
          <SegmentedControl
            size="sm"
            value={language}
            onChange={(value) => updateLanguage(value as Language)}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
          />
          {form.isDirty() && <SaveAlert />}
          {form.isDirty() && <SaveForm />}
        </Stack>
      </Tabs.List>

      <Tabs.Panel value="general" pl="xs">
        <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
          <WebinarGeneralFormSection />
        </Paper>
      </Tabs.Panel>
    </Tabs>
  );
};
