import { Container, LoadingOverlay, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { PageTitle } from 'components/common/PageTitle';
import dayjs from 'dayjs';
import { getTranslations } from 'helpers/get-translations.helper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { webinarsSelectors } from 'redux/webinars/webinarsSelectors';
import { webinarsActions } from 'redux/webinars/webinarsSlice';
import { EditableWebinar } from 'redux/webinars/webinarsTypes';
import { WebinarFormProvider, useWebinarForm } from './webinar-form-context';
import { LanguageProvider } from 'components/Language/LanguageContext';
import { EditWebinarForm } from './EditWebinarForm';
import { stripHTML } from 'helpers/strip-html';

const getEmptyWebinar = (): EditableWebinar => {
  const emptyTranslations = getTranslations([]);

  const emptyWebinar: EditableWebinar = {
    description: { ...emptyTranslations },
    name: { ...emptyTranslations },
    status: 'active',
    kajabi_url: '',
    timer_enabled: false,
    start_at_time: dayjs().format('HH:mm'),
    start_at_date: new Date(),
    courses: [],
  };

  return emptyWebinar;
};

const urlValidate = /^(http|https):\/\/[^ "]+$/;

export const CreateWebinarFormModern: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const webinars = useSelector(webinarsSelectors.selectWebinars);
  const webinarData = useSelector(webinarsSelectors.selectWebinar);
  const isFetching = useSelector(webinarsSelectors.selectIsFetching);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isEditMode = !!id;

  const pageTitle = isEditMode ? `Edit webinar ${stripHTML(webinarData?.name.en || "")}` : `New webinar`;

  const form = useWebinarForm({
    initialValues: getEmptyWebinar(),
    transformValues: (values) => {
      console.log(values);

      let returnValue: EditableWebinar = {
        ...values,
        start_at_date: dayjs(values.start_at_date).format('YYYY-MM-DD HH:mm:ss'),
        start_at_time: dayjs(`1970-01-01 ${values.start_at_time}`, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      };

      if (!values.timer_enabled) {
        returnValue.start_at_date = null;
        returnValue.start_at_time = null;
      }

      return returnValue;
    },
    validate: {
      kajabi_url: (value) =>
        urlValidate.test(value) ? null : 'The webinar link must be a valid URL.',
    },
  });

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(webinarsActions.getWebinar(id));
    }
  }, [id, isEditMode, dispatch]);

  useEffect(() => {
    if (webinarData && !isFetching) {
      const formData: EditableWebinar = {
        ...webinarData,
        description: getTranslations(webinarData.description),
        name: getTranslations(webinarData.name),
        start_at_date: webinarData.start_at_date
          ? dayjs(webinarData.start_at_date).toDate()
          : dayjs().toDate(),
        start_at_time: webinarData.start_at_time
          ? dayjs(webinarData.start_at_time).format('HH:mm')
          : dayjs().format('HH:mm'),
        status: webinarData.status,
        kajabi_url: webinarData.kajabi_url,
        course_id: webinarData.course_id,
        timer_enabled: webinarData.timer_enabled,
        courses: webinarData.courses.map((course) => course.id),
      };

      form.setValues(formData);
      form.resetDirty(formData);
    }
  }, [id, isEditMode, isFetching, webinarData]);

  const onSubmit = (values: typeof form.values) => {
    const webinar: EditableWebinar = values;

    if (isEditMode && id) {
      dispatch(webinarsActions.updateWebinar({ id, webinar, navigate }));
    } else {
      dispatch(webinarsActions.createWebinar({ webinar, navigate }));
    }
  };

  const handleError = (errors: typeof form.errors) => {
    if (errors.kajabi_ur) {
      showNotification({ message: 'The linkedin must be a valid URL.', color: 'red' });
    }
  };

  return (
    <LanguageProvider>
      <Container size="lg">
        <LoadingOverlay visible={isFetching} overlayBlur={2} />
        <PageTitle text={pageTitle} />

        <WebinarFormProvider form={form}>
          <form onSubmit={form.onSubmit(onSubmit, handleError)}>
            <EditWebinarForm />
          </form>
        </WebinarFormProvider>
      </Container>
    </LanguageProvider>
  );
};
