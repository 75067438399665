import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelectors } from './redux/auth/authSelectors';
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';

import { APP_ROUTES } from './constants';
import {
  Posts,
  Courses,
  CreateFeedback,
  CreateRate,
  Feedbacks,
  Login,
  EditablePage,
  Rates,
  Teachers,
  Webinars,
  BlogTags,
  TestsList,
  CoursePageReviews,
  CreateCourseReview,
} from './pages';
import { MainContainer } from './components/containers';
import { NewPost } from './components';
import { TestInfo } from './pages/Tests/CreateTestSteps/TestInfo/TestInfo';
import { TestQuestions } from './pages/Tests/CreateTestSteps/TestQuestions/TestQuestions';
import { TestUnits } from './pages/Tests/CreateTestSteps/TestUnits/TestUnits';
import { EditCourse } from 'pages/Courses/EditCourse';
import { ReactElement } from 'react';
import { EditablePost } from 'pages/Posts/EditablePost';
import { EditTeacher } from 'pages/Teachers/EditTeacher';
import { CreateWebinarFormModern } from 'pages/Webinars/CreateWebinar/CreateWebinarFormModern/CreateWebinarFormModern';
import { Pages } from 'pages/Pages/Pages';
import { EditPage } from 'pages/Pages/EditPage';

interface RequireAuthProps {
  children: ReactElement;
}

function RequireAuth({ children }: RequireAuthProps) {
  const isAuthorized = useSelector(authSelectors.selectIsAuthorized);

  return isAuthorized ? children : <Navigate to={APP_ROUTES.LOGIN} replace />;
}

export const App = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const isAuthorized = useSelector(authSelectors.selectIsAuthorized);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider withNormalizeCSS withGlobalStyles theme={{ colorScheme, loader: 'bars' }}>
        <ModalsProvider>
          <MainContainer isAuthorized={isAuthorized}>
            <Routes>
              <Route path="/" element={<Navigate to={APP_ROUTES.LOGIN} />} />
              <Route
                path={APP_ROUTES.PAGES}
                element={
                  <RequireAuth>
                    <Pages />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.PAGE}
                element={
                  <RequireAuth>
                    <EditPage />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.TEACHERS}
                element={
                  <RequireAuth>
                    <Teachers />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.POSTS}
                element={
                  <RequireAuth>
                    <Posts />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.POSTS}/new`}
                element={
                  <RequireAuth>
                    <EditablePost />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.POSTS}/:id`}
                element={
                  <RequireAuth>
                    <EditablePost />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.POSTS}
                element={
                  <RequireAuth>
                    <Posts />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.NEW_POST}
                element={
                  <RequireAuth>
                    <NewPost />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.TAGS}
                element={
                  <RequireAuth>
                    <BlogTags />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.NEW_POST}/:id`}
                element={
                  <RequireAuth>
                    <NewPost isEditMode />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.COURSES}
                element={
                  <RequireAuth>
                    <Courses />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.EDIT_COURSE_NEW}`}
                element={
                  <RequireAuth>
                    <EditCourse />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.EDIT_COURSE_NEW}/:id`}
                element={
                  <RequireAuth>
                    <EditCourse />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.RATES}
                element={
                  <RequireAuth>
                    <Rates />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.CREATE_RATE}
                element={
                  <RequireAuth>
                    <CreateRate />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_RATE}/:id`}
                element={
                  <RequireAuth>
                    <CreateRate isEditMode />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.FEEDBACKS}
                element={
                  <RequireAuth>
                    <Feedbacks />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.CREATE_FEEDBACK}
                element={
                  <RequireAuth>
                    <CreateFeedback />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_FEEDBACK}/:id`}
                element={
                  <RequireAuth>
                    <CreateFeedback isEditMode />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.COURSES_REVIEW}
                element={
                  <RequireAuth>
                    <CoursePageReviews />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.CREATE_COURSES_REVIEW}
                element={
                  <RequireAuth>
                    <CreateCourseReview />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_COURSES_REVIEW}/:id`}
                element={
                  <RequireAuth>
                    <CreateCourseReview isEditMode />
                  </RequireAuth>
                }
              />
              <Route path={APP_ROUTES.LOGIN} element={<Login />} />
              <Route
                path={`${APP_ROUTES.TEACHERS}/new`}
                element={
                  <RequireAuth>
                    <EditTeacher />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.TEACHERS}/:id`}
                element={
                  <RequireAuth>
                    <EditTeacher />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.WEBINARS}
                element={
                  <RequireAuth>
                    <Webinars />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.CREATE_WEBINAR}
                element={
                  <RequireAuth>
                    <CreateWebinarFormModern />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_WEBINAR}/:id`}
                element={
                  <RequireAuth>
                    <CreateWebinarFormModern />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.PRIVACY_POLICY}
                element={
                  <RequireAuth>
                    <EditablePage path="privacy-policy" />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.TERMS_CONDITIONS}
                element={
                  <RequireAuth>
                    <EditablePage path="terms-conditions" />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.TRAINING_QA}
                element={
                  <RequireAuth>
                    <EditablePage path="training-qa" />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.BASIC_OF_STUDY_ORGANISATION}
                element={
                  <RequireAuth>
                    <EditablePage path="basic-of-study-organisation" />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.TESTS}
                element={
                  <RequireAuth>
                    <TestsList />
                  </RequireAuth>
                }
              />
              <Route
                path={APP_ROUTES.CREATE_TEST}
                element={
                  <RequireAuth>
                    <TestInfo />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.EDIT_TEST}/:testId`}
                element={
                  <RequireAuth>
                    <TestInfo isEditMode />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_TEST}/:testId`}
                element={
                  <RequireAuth>
                    <TestQuestions />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.EDIT_TEST_QUESTIONS}/:testId`}
                element={
                  <RequireAuth>
                    <TestQuestions isEditMode />
                  </RequireAuth>
                }
              />
              <Route
                path={`${APP_ROUTES.CREATE_TEST}/:testId/units`}
                element={
                  <RequireAuth>
                    <TestUnits />
                  </RequireAuth>
                }
              />
            </Routes>
          </MainContainer>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
