import { Stack, TextInput } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language } from 'components/Language/types';
import { Test } from 'components/Pages/api';
import { RTE } from 'components/RTE';
import { Controller, useFormContext } from 'react-hook-form';

type FormWithTestBlock = {
  test_block: Record<Language, Test>;
};

export const TestBlockForm = () => {
  const form = useFormContext<FormWithTestBlock>();
  const { language } = useLanguage();

  return (
    <Stack>
      <Controller
        key={`name-${language}`}
        control={form.control}
        name={`test_block.${language}.title`}
        render={({ field }) => <RTE label="Title" {...field} />}
      />

      <Controller
        key={`description-${language}`}
        control={form.control}
        name={`test_block.${language}.description`}
        render={({ field }) => <RTE label="Description" {...field} />}
      />
      <Controller
        key={`button-text-${language}`}
        control={form.control}
        name={`test_block.${language}.buttonText`}
        render={({ field }) => <TextInput label="Text inside button" {...field} />}
      />

      <Controller
        key={`post-button-text-${language}`}
        control={form.control}
        name={`test_block.${language}.postButtonText`}
        render={({ field }) => <TextInput label="Post button text" {...field} />}
      />

      <Controller
        key={`url-${language}`}
        control={form.control}
        name={`test_block.${language}.url`}
        render={({ field }) => <TextInput label="URL" type="url" {...field} />}
      />
    </Stack>
  );
};
