import { Container, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { LanguageProvider } from 'components/Language/LanguageContext';
import { FullPage, Page, Test, usePage } from 'components/Pages/api';
import { PageTitle } from 'components/common/PageTitle';
import { BaseFormLayout } from 'components/form/BaseFormLayout';
import { ReviewsForm } from 'components/form/ReviewsForm';
import { TestBlockForm } from 'components/form/TestBlockForm';
import { WebinarForm } from 'components/form/WebinarForm';
import { APP_ROUTES } from 'constants/index';
import { getTranslations, getTranslationsWithEmptyValue } from 'helpers/get-translations.helper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useParams, Navigate } from 'react-router-dom';
import { coursesActions } from 'redux/courses/coursesSlice';
import { webinarsActions } from 'redux/webinars/webinarsSlice';

const FormSections = [
  { name: 'Webinar', key: 'webinar', component: WebinarForm },
  { name: 'Reviews', key: 'reviews', component: ReviewsForm },
  { name: 'Test', key: 'test', component: TestBlockForm },
];

const getEmptyTest: () => Test = () => ({
  title: '',
  description: '',
  postButtonText: '',
  url: '',
  buttonText: ''
})


export const EditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, page, update, isError } = usePage(parseInt(id!));
  const form = useForm<FullPage>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(webinarsActions.getWebinars());
    dispatch(coursesActions.getReviews());
  }, [dispatch]);

  useEffect(() => {
    if (page) {
      const editablePage: FullPage = {
        ...page,
        test_block: getTranslationsWithEmptyValue(page.test_block, getEmptyTest)
      }

      console.log(editablePage);

      form.reset(editablePage);
    }
  }, [page]);

  if (!id) {
    return <Navigate to={APP_ROUTES.PAGE} />;
  }

  const onSubmit = async (values: FullPage) => {
    console.log(values);

    try {
      await update(values);
    } catch (e: any) {
      showNotification({ message: e.message, color: 'red' });
    }
  };

  const handleError = (errors: FieldErrors) => {
    if (errors.languages) {
      showNotification({ message: 'Please choose minimum 1 language', color: 'red' });
    }
    if (errors.linkedin) {
      showNotification({ message: 'The linkedin must be a valid URL.', color: 'red' });
    }
  };

  const pageTitle = page?.type + ': ' + page?.name;

  return (
    <LanguageProvider>
      <Container size="lg" pl="xs">
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <PageTitle text={pageTitle} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, handleError)}>
            <BaseFormLayout sections={FormSections} />
          </form>
        </FormProvider>
      </Container>
    </LanguageProvider>
  );
};
