import { RootState } from '../store';
import { ITeacher } from './teachersTypes';
import { IPagination } from '../pagination/paginationTypes';

const selectIsFetching = (state: RootState): boolean => state.teachers.isFetching;
const selectIsFetchingError = (state: RootState): boolean => state.teachers.isFetchingError;
const selectTeachers = (state: RootState): ITeacher[] | null => state.teachers.teachers;
const selectTeacher = (state: RootState): ITeacher | null => state.teachers.teacher;
const selectPaginationData = (state: RootState): IPagination => state.teachers.paginationData;

export const teachersSelectors = {
  selectIsFetchingError,
  selectIsFetching,
  selectTeachers,
  selectTeacher,
  selectPaginationData,
};
