import {
  Avatar,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  MultiSelect,
  SelectItemProps,
  Stack,
  Text,
} from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { APP_ROUTES } from 'constants/index';
import { forwardRef, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EditableCourse } from 'redux/courses/coursesTypes';
import { teachersSelectors } from 'redux/teachers/teachersSelectors';

interface TeacherCardProps {
  teacherId: number;
  onDelete: (teacherId: number) => void;
}

const TeacherCard = ({ teacherId, onDelete }: TeacherCardProps) => {
  const teachers = useSelector(teachersSelectors.selectTeachers);
  const teacher = teachers?.find((teacher) => teacher.id === teacherId);

  const handleDelete = useCallback(
    (_: unknown) => {
      onDelete(teacherId);
    },
    [onDelete],
  );

  if (!teacher) {
    return (
      <Card shadow="sm" p="lg" radius="md" withBorder>
        teacher not found
      </Card>
    );
  }

  return (
    <Card shadow="sm" p="md" radius="md" withBorder>
      <Grid gutter={20} columns={10}>
        <Grid.Col span={2}>
          <Flex justify="center" align="center" style={{ height: '100%' }}>
            <Avatar src={teacher.main_photo} style={{ width: '100%', height: '100%' }} />
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack spacing="xs" justify="center">
            <Group spacing="xs">
              <Text weight={600}>Name:</Text>
              <Text>{teacher.full_name.en}</Text>
            </Group>
            <Group spacing="xs">
              <Text weight={600}>Team position:</Text>
              <Text lineClamp={2}>{teacher.team_position.en}</Text>
            </Group>
            <Group spacing="xs">
              <Text weight={600}>Tags:</Text>
              <Text>{teacher.tags.join(', ')}</Text>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={2}>
          <Stack justify="center" align="center" style={{ height: '100%' }}>
            <Link to={`${APP_ROUTES.CHANGE_TEACHER}/${teacherId}`} target="_blank">
              <Button size="xs" leftIcon={<IconEdit stroke={1} />} variant="outline">
                Edit
              </Button>
            </Link>

            <Button
              size="xs"
              leftIcon={<IconTrash stroke={1} />}
              variant="outline"
              color="red"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

const Item = forwardRef<HTMLDivElement, SelectItemProps>(({ label, value, ...others }, ref) => {
  const teachers = useSelector(teachersSelectors.selectTeachers);
  const src = teachers?.find((teacher) => teacher.id === Number(value))?.main_photo || '';
  return (
    <div ref={ref} {...others}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box mr={10}>
          <Avatar src={src} />
        </Box>
        <div>{label}</div>
      </Box>
    </div>
  );
});

export const TeachersForm = () => {
  const teachers = useSelector(teachersSelectors.selectTeachers);
  const form = useFormContext<EditableCourse>();
  const watchTeachers = form.watch('teachers');
  const teachersInForm = useMemo(() => form.getValues('teachers'), [watchTeachers]);

  const avalaibleTeachers = useMemo(
    () =>
      teachers?.filter(
        (teacher) => teachersInForm.findIndex((form_teacher) => teacher.id === form_teacher) === -1,
      ) || [],
    [teachers, teachersInForm],
  );

  const teachersFormatted = useMemo(
    () =>
      avalaibleTeachers.map((teacher) => ({
        label: teacher.full_name.en,
        value: String(teacher.id),
      })) || [],
    [avalaibleTeachers],
  );

  const addTeacher = ([teacherId]: string[]) => {
    form.setValue('teachers', [...teachersInForm, Number(teacherId)], { shouldDirty: true });
  };

  const deleteTeacher = (teacherId: number) => {
    form.setValue(
      'teachers',
      teachersInForm.filter((teacher) => teacher != teacherId),
      { shouldDirty: true },
    );
  };

  return (
    <Stack>
      {teachersInForm.map((teacher) => (
        <TeacherCard onDelete={deleteTeacher} teacherId={teacher} key={teacher} />
      ))}

      {avalaibleTeachers.length > 0 && (
        <MultiSelect
          clearable={false}
          label={<Text>Add teachers</Text>}
          data={teachersFormatted}
          limit={20}
          value={[]}
          searchable
          onChange={addTeacher}
          itemComponent={Item}
          valueComponent={() => <></>}
        />
      )}
    </Stack>
  );
};
