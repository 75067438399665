import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  ActionIcon,
  Button,
  Container,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  Tabs,
  TextInput,
  Title,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';

import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { CreateRateForm } from './CreateRateForm/CreateRateForm';
import { Nullable } from '../../../helpers/nullable';
import { useSelector } from 'react-redux';
import { coursesSelectors } from '../../../redux/courses/coursesSelectors';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { coursesActions } from '../../../redux/courses/coursesSlice';
import type { EditableRate } from '../../../redux/courses/coursesTypes';

type Props = {
  isEditMode?: boolean;
};

export const CreateRate = ({ isEditMode = false }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [languages, changeLanguages] = useState<string[]>([]);
  const [currentLanguageTab, setCurrentLanguageTab] = useState<Nullable<string>>('en');

  const courses = useSelector(coursesSelectors.selectShortCourses);
  const rateData = useSelector(coursesSelectors.selectRate);
  const isFetching = useSelector(coursesSelectors.selectIsFetching);

  const form = useForm<EditableRate>({
    initialValues: {
      courses: [],
      number: 0,
      price: 999,
      old_price: null,
      kajabi_url: '',
      kajabi_buy_url: '',
      languages: [],
      program: '',
      name: {
        en: '',
        rus: '',
        est: '',
      },
      short_description: {
        en: '',
        rus: '',
        est: '',
      },
      description: [
        {
          key: randomId(),
          point: {
            en: '',
            rus: '',
            est: '',
          },
        },
      ],
      description_under_price: {
        en: '',
        rus: '',
        est: '',
      },
    },
  });

  const onSubmit = (values: typeof form.values) => {
    if (isEditMode && id) {
      dispatch(coursesActions.updateRate({ id, rate: values, navigate }));
    } else {
      dispatch(coursesActions.createRate({ rate: values, navigate }));
    }
  };

  const handleError = () => {};

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(coursesActions.getRate(id));
    }

    dispatch(coursesActions.getCourses());
  }, []);

  useEffect(() => {
    if (isEditMode && rateData) {
      const currentData = {
        courses: rateData.courses.map(course => String(course.id)),
        number: rateData.number,
        price: Number(rateData.price),
        old_price: Number(rateData.old_price),
        kajabi_url: rateData.kajabi_url,
        kajabi_buy_url: rateData.kajabi_buy_url,
        languages: rateData.languages,
        program: '',
        name: rateData.name,
        short_description: rateData.short_description,
        description: rateData.description,
        description_under_price: rateData.description_under_price,
      };

      form.setValues(currentData);
    }
  }, [isEditMode, isFetching, rateData]);

  const points = form.values.description.map((item, index) => (
    <Group key={item.key} mt="xs">
      <TextInput
        label="Point"
        placeholder="Enter text"
        description="58 symbols maximum"
        inputWrapperOrder={['label', 'error', 'input', 'description']}
        sx={{ flex: 1 }}
        {...form.getInputProps(`description.${index}.point.${currentLanguageTab}`)}
      />
      <ActionIcon color="red" onClick={() => form.removeListItem('description', index)}>
        <DeleteIcon />
      </ActionIcon>
    </Group>
  ));

  if (!courses) {
    return null;
  }

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} overlayBlur={2} />;
  }

  return (
    <Container>
      <Title mb="xl" order={1}>
        Create rate
      </Title>
      <form onSubmit={form.onSubmit(onSubmit, handleError)}>
        <Tabs onTabChange={(value) => setCurrentLanguageTab(value)} defaultValue="en" keepMounted={false}>
          <Tabs.List grow mb="xl">
            <Tabs.Tab value="en">Eng</Tabs.Tab>
            <Tabs.Tab value="rus">Rus</Tabs.Tab>
            <Tabs.Tab value="est">Est</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="en" pt="xs">
            {/* <MultiSelect
              styles={{ required: { display: 'none' } }}
              required
              mb="xl"
              label="Select course"
              placeholder="Select course"
              data={courses.map(({ id, name }) => ({ value: `${id}`, label: name }))}
              {...form.getInputProps('courses')}
            /> */}
            <TextInput
              mt="md"
              mb="xl"
              styles={{ required: { display: 'none' } }}
              required
              label="Leave a request button"
              placeholder="enter kajabi link"
              {...form.getInputProps('kajabi_url')}
            />
            <TextInput
              mt="md"
              mb="xl"
              styles={{ required: { display: 'none' } }}
              required
              label="Buy the course button"
              placeholder="enter kajabi link"
              {...form.getInputProps('kajabi_buy_url')}
            />
            <CreateRateForm
              form={form}
              points={points}
              language="en"
              languages={languages}
              changeLanguages={changeLanguages}
            />
          </Tabs.Panel>

          <Tabs.Panel value="rus" pt="xs">
            <CreateRateForm
              form={form}
              points={points}
              language="rus"
              languages={languages}
              changeLanguages={changeLanguages}
            />
          </Tabs.Panel>

          <Tabs.Panel value="est" pt="xs">
            <CreateRateForm
              form={form}
              points={points}
              language="est"
              languages={languages}
              changeLanguages={changeLanguages}
            />
          </Tabs.Panel>
        </Tabs>

        <Group position="right">
          <Button mt="md" loading={false} disabled={!form.isValid()} type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};
