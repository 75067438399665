import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { Nullable } from '../../helpers/nullable';
import { IPagination } from '../pagination/paginationTypes';
import { EditableTeacher, ITeacher } from './teachersTypes';

interface IState {
  isFetching: boolean;
  isFetchingError: boolean;
  teachers: Nullable<ITeacher[]>;
  teacher: Nullable<ITeacher>;
  paginationData: IPagination;
}

const initialState: IState = {
  isFetching: false,
  isFetchingError: false,
  teachers: null,
  teacher: null,
  paginationData: {
    current_page: 1,
    per_page: 0,
    total: 0,
  },
};

export const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setIsFetching(state: IState, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    setIsFetchingError(state: IState, { payload }: PayloadAction<boolean>) {
      state.isFetchingError = payload;
    },
    getTeachers(state, { payload }: PayloadAction<{ page: number }>) {},
    createTeacher(state, { payload }: PayloadAction<any>) {},
    deleteTeacher(state, { payload }: PayloadAction<number>) {},
    updateTeacher(
      state,
      { payload }: PayloadAction<{ id: string; teacher: EditableTeacher; navigate: NavigateFunction }>,
    ) {},
    getTeacher(state, { payload }: PayloadAction<string>) {},
    setTeachers(state, { payload }: PayloadAction<ITeacher[]>) {
      state.teachers = payload;
    },
    setTeacher(state, { payload }: PayloadAction<ITeacher>) {
      state.teacher = payload;
    },
    setPaginationData(state, { payload }: PayloadAction<any>) {
      state.paginationData = payload;
    },
  },
});

export const teachersActions = teachersSlice.actions;
export const teachersReducer = teachersSlice.reducer;
