import { Alert, Button, Paper, SegmentedControl, Stack, Tabs } from '@mantine/core';
import { useLanguage } from 'components/Language/LanguageContext';
import { Language, LanguageNames } from 'components/Language/types';
import { GeneralTeacherForm } from './GeneralTeacherForm';
import { MetaTeacherForm } from './MetaTeacherForm';
import { DescriptionsTeachersForm } from './DescriptionsTeacherForm';
import { useFormContext } from 'react-hook-form';
import { WebinarForm } from 'components/EditTeacherForm/WebinarForm';
import { ReviewsForm } from 'components/EditTeacherForm/ReviewsForm';

function SaveAlert() {
  return (
    <Alert color="yellow" radius="md" variant="filled">
      Unsaved data
    </Alert>
  );
}

function SaveForm() {
  return (
    <Button color="green" radius="md" variant="filled" type="submit">
      Save
    </Button>
  );
}

export const EditTeacherForm = () => {
  const form = useFormContext();
  const { language, updateLanguage } = useLanguage();

  return (
    <Tabs
      defaultValue="general"
      keepMounted={false}
      variant="pills"
      radius="md"
      orientation="vertical"
      mt={5}
    >
      <Tabs.List style={{ position: 'sticky', top: '80px', alignSelf: 'flex-start' }}>
        <Tabs.Tab value="general">General</Tabs.Tab>
        <Tabs.Tab value="descriptions">Descriptions</Tabs.Tab>
        <Tabs.Tab value="meta">Meta</Tabs.Tab>
        <Tabs.Tab value="webinar">Webinar</Tabs.Tab>
        <Tabs.Tab value="reviews">Reviews</Tabs.Tab>

        <Stack mt={10}>
          <SegmentedControl
            size="sm"
            value={language}
            onChange={(value) => updateLanguage(value as Language)}
            data={Object.keys(LanguageNames).map((lang) => ({
              label: LanguageNames[lang as Language],
              value: lang,
            }))}
          />
          {form.formState.isDirty && <SaveAlert />}
          {form.formState.isDirty && <SaveForm />}
        </Stack>
      </Tabs.List>

      <Tabs.Panel value="general" pl="xs">
        <Paper style={{ height: '100%' }} shadow="sm" p="md" withBorder>
          <GeneralTeacherForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="meta" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <MetaTeacherForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="webinar" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <WebinarForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="descriptions" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <DescriptionsTeachersForm />
        </Paper>
      </Tabs.Panel>

      <Tabs.Panel value="reviews" pl="xs">
        <Paper shadow="sm" p="md" withBorder>
          <ReviewsForm />
        </Paper>
      </Tabs.Panel>
    </Tabs>
  );
};
